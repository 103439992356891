<div class="mainframe">
    <h2 i18n="users$$your config, header@@your config" class="main-heading">Your configuration</h2>

    <mat-card appearance="outlined" style="margin-top: 15px; margin-bottom: 20px;">
        <mat-card-content>
            @if (3 >= paidDaysLeft) {
            <div class="warning">
                @if (paidDaysLeft === 0) {
                <div i18n="toolbar$$last day of paid service@@0 paid days left">0 days left</div>
                } @else if (paidDaysLeft === 1) {
                <div i18n="toolbar$$one more day before the paid service expires@@1 paid days left">1 day left</div>
                } @else if (paidDaysLeft === 2) {
                <div i18n="toolbar$$two more days before the paid service expires@@2 paid days left">2 days left</div>
                } @else if (paidDaysLeft === 3) {
                <div i18n="toolbar$$three more days before the paid service expires@@3 paid days left">3 days left</div>
                }
                <div><a [href]="'https://buy.artisan.plus/' + (locale2 === 'de' ? locale2 : '')" data-test="lnk_shop" target="_blank" rel="noopener" i18n="toolbar$$tooltip with hint to our store@@visit our store">Please visit our shop to extend your subscription</a></div>
            </div>
            } @else {
            <div>
                <div i18n="toolbar$$link showing until when user has paid@@paid until">Paid until {{paidUntil.toLocaleString(DateTime.DATE_MED)}}</div>
                <div><a [href]="'https://buy.artisan.plus/' + (locale2 === 'de' ? locale2 : '')" data-test="lnk_shop2" target="_blank" rel="noopener" i18n="users$$link to our shop@@You can always visit our shop to extend your subscription">You can always visit our shop to extend your subscription</a></div>
            </div>
            }
        </mat-card-content>
    </mat-card>

    <div class="toggle-settings">
        <mat-button-toggle-group [(ngModel)]="darkmode" name="darkmodesetting" hideSingleSelectionIndicator="true">
            <mat-button-toggle (click)="darkmodeSettingChanged()" data-test="bttn_light" i18n="generic$$user setting, don't use dark mode@@Light mode" value="lightmode">Light mode</mat-button-toggle>
            <mat-button-toggle (click)="darkmodeSettingChanged()" data-test="bttn_auto" i18n="generic$$user setting, use light/dark mode according to browser setting@@Auto" value="auto">Auto</mat-button-toggle>
            <mat-button-toggle (click)="darkmodeSettingChanged()" data-test="bttn_dark" i18n="generic$$user setting, use dark mode@@Dark mode" value="darkmode">Dark mode</mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <div class="oneline-formfields">
        <mat-form-field subscriptSizing="dynamic">
            <mat-label i18n="users$$nickname, only for the greeting@@nickname">Nickname</mat-label>
            <input required matInput data-test="npt_nickname"
                placeholder="Nickname" i18n-placeholder="users$$nickname, only for the greeting@@nickname"
                [disabled]="updatingUser || readOnly"
                [ngModel]="currentUser?.nickname || ''" type="text" autocomplete="" name="nickname"
                (ngModelChange)="saver.next({ prop: 'nickname', value: $event })" (blur)="saveUser({ nickname: $event.target['value'] })">
        </mat-form-field>
        @if (updatingUser || updatingAccount) {
        <mat-spinner diameter="15" style="display: inline-block; margin-left: 15px;"></mat-spinner>
        }
    </div>

    <div class="oneline-formfields">
        <mat-form-field data-test="slct_country" class="half-formfield" subscriptSizing="dynamic">
            <mat-label i18n="contacts$$country@@Country">Country</mat-label>
            <input matInput type="text" id="country"
                placeholder="Country" i18n-placeholder="contacts$$country@@Country"
                [disabled]="updatingUser || readOnly"
                [matAutocomplete]="autoCompleteCountry"
                (input)="changeCountryFilter()" (focus)="changeCountryFilter()" name="country"
                [(ngModel)]="userCountry"
                (blur)="countryChanged($event)">
            <mat-autocomplete #autoCompleteCountry="matAutocomplete" panelWidth="300px"
                [displayWith]="displayCountryFn" (optionSelected)="countryChanged()">
                @for (cntr of countries; track cntr) {
                <mat-option [value]="cntr">{{cntr.tr}}</mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field data-test="slct_currency" style="width: 47.5%" subscriptSizing="dynamic">
            <mat-label i18n="unit_system$$user can choose between any currency@@currency">Currency</mat-label>
            <mat-select [disabled]="updatingAccount || readOnly || !isAdmin"
                name="currency" hideSingleSelectionIndicator="true"
                placeholder="Currency" i18n-placeholder="unit_system$$user can choose between any currency@@currency"
                [ngModel]="currentUser?.account?.currency || 'EUR'"
                (selectionChange)="saveAccount('currency', $event.value)">
                <mat-option value="EUR">EUR</mat-option>
                <mat-option value="USD">USD</mat-option>
                @for (cur of currencies; track cur) {
                <mat-option [value]="cur">{{cur}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>

    <div class="oneline-formfields">
        <mat-form-field data-test="slct_unitSystem" class="half-formfield" subscriptSizing="dynamic">
            <mat-label i18n="unit_system$$user can choose between, e.g. metric or imperial@@unit system">Unit system</mat-label>
            <mat-select [disabled]="updatingUser || readOnly"
                name="unit_system" hideSingleSelectionIndicator="true"
                placeholder="Unit system" i18n-placeholder="unit_system$$user can choose between, e.g. metric or imperial@@unit system"
                [ngModel]="currentUser?.unit_system || Enumerations.UNIT_SYSTEM.METRIC"
                (selectionChange)="saveUser({ unit_system: $event.value })">
                <mat-option [value]="Enumerations.UNIT_SYSTEM.METRIC">kg</mat-option>
                <mat-option [value]="Enumerations.UNIT_SYSTEM.IMPERIAL">lbs</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field data-test="slct_temperatureSystem" class="half-formfield" subscriptSizing="dynamic">
            <mat-label i18n="unit_system$$user can choose between, e.g. Celsius or Fahrenheit@@temperature system">Temperature system</mat-label>
            <mat-select [disabled]="updatingUser || readOnly"
                name="temperature_system" hideSingleSelectionIndicator="true"
                placeholder="Temperature system" i18n-placeholder="unit_system$$user can choose between, e.g. Celsius or Fahrenheit@@temperature system"
                [ngModel]="currentUser?.temperature_system || Enumerations.TEMPERATURE_SYSTEM.CELSIUS"
                (selectionChange)="saveUser({ temperature_system: $event.value })">
                <mat-option [value]="Enumerations.TEMPERATURE_SYSTEM.CELSIUS">°C</mat-option>
                <mat-option [value]="Enumerations.TEMPERATURE_SYSTEM.FAHRENHEIT">°F</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="oneline-formfields">
        <mat-form-field data-test="slct_energyUnit" class="half-formfield" subscriptSizing="dynamic">
            <mat-label i18n="roasts$$energy (e.g. used by one or more roasts)@@Energy">Energy</mat-label>
            <mat-select [disabled]="updatingUser || readOnly"
                name="energyUnit" hideSingleSelectionIndicator="true"
                placeholder="Energy" i18n-placeholder="roasts$$energy (e.g. used by one or more roasts)@@Energy"
                [ngModel]="currentUser?.energy_unit || 'BTU'"
                (selectionChange)="saveUser({ energy_unit: $event.value })">
                <mat-option value="BTU">BTU</mat-option>
                <mat-option value="kJ">kJ</mat-option>
                <mat-option value="kCal">kCal</mat-option>
                <mat-option value="kWh">kWh</mat-option>
                <mat-option value="hph">hph</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field data-test="slct_gasUnit" class="half-formfield" subscriptSizing="dynamic">
            <mat-label i18n="generic$$unit in which users buy their gas cylinders@@Propane bottle">Propane bottle</mat-label>
            <mat-select [disabled]="updatingUser || readOnly"
                name="gasUnit" hideSingleSelectionIndicator="true"
                placeholder="Propane bottle" i18n-placeholder="generic$$unit in which users buy their gas cylinders@@Propane bottle"
                [ngModel]="currentUser?.gas_unit || 'kg'"
                (selectionChange)="saveUser({ gas_unit: $event.value })">
                <mat-option value="kg">kg</mat-option>
                <mat-option value="lbs">lbs</mat-option>
                <mat-option value="gal">US gal</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="settings-header">
        <span i18n="settings$$Data Export Settings (CSV, Excel), header@@Data Export Settings (CSV, Excel)">Data Export Settings (CSV, Excel)</span>
        <a [href]="'https://' + (isDarkmode ? 'd' : '') + 'doc.artisan.plus/updates/exporting-data/'"
            data-test="lnk_exportHelp" rel="noopener" style="padding-right: 0;margin: 0 0 0 5px;" target="_blank">
            <mat-icon class="helpicon">{{isDarkmode ? 'help' : 'help_outline'}}</mat-icon>
        </a>
    </div>

    <div class="oneline-formfields">
        <mat-form-field data-test="slct_exportSeparator" class="half-formfield"
            [ngClass]="{'incompatible-setting': currentUser?.export?.decsep && currentUser?.export?.decsep === currentUser?.export?.sep}" subscriptSizing="dynamic">
            <mat-label i18n="settings$$character that separates data entries@@Separated by">Separated by</mat-label>
            <mat-select [disabled]="updatingUser || readOnly" panelWidth="null"
                name="separator" hideSingleSelectionIndicator="true"
                placeholder="Separated by" i18n-placeholder="settings$$character that separates data entries@@Separated by"
                [ngModel]="currentUser?.export?.sep || '\t'"
                (selectionChange)="saveExport('sep', $event.value)">
                <mat-option [value]="'\t'" i18n="settings$$Tab@@Tab">Tab</mat-option>
                <mat-option value="," i18n="settings$$Comma@@Comma">Comma</mat-option>
                <mat-option value=";" i18n="settings$$Semicolon@@Semicolon">Semicolon</mat-option>
                <mat-option value=" " i18n="settings$$Space@@Space">Space</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field data-test="slct_exportLineSeparator" class="half-formfield" subscriptSizing="dynamic">
            <mat-label i18n="settings$$character(s) that separate each line of data@@Line separator">Line separator</mat-label>
            <mat-select [disabled]="updatingUser || readOnly"
                name="lineseparator" hideSingleSelectionIndicator="true"
                placeholder="Line separator" i18n-placeholder="settings$$character(s) that separate each line of data@@Line separator"
                [ngModel]="currentUser?.export?.linesep || '\r\n'"
                (selectionChange)="saveExport('linesep', $event.value)">
                <mat-option [value]="'\r\n'" i18n="settings$$Windows line sep@@Windows">Windows</mat-option>
                <mat-option [value]="'\n'" i18n="settings$$Unix / macOS line sep@@Unix / macOS">Unix / macOS</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-form-field data-test="slct_exportDecimalSeparator" [ngClass]="{'incompatible-setting': currentUser?.export?.decsep === currentUser?.export?.sep}" subscriptSizing="dynamic">
        <mat-label i18n="settings$$Decimal Separator@@Decimal Separator">Decimal Separator</mat-label>
        <mat-select [disabled]="updatingUser || readOnly"
            name="decseparator" hideSingleSelectionIndicator="true"
            placeholder="Decimal Separator" i18n-placeholder="settings$$Decimal Separator@@Decimal Separator"
            [ngModel]="currentUser?.export?.decsep || '.'"
            (selectionChange)="saveExport('decsep', $event.value)">
            <mat-option value="." i18n="generic$$CSV export, use dot as decimal seperator@@dot seperator">Format: 12.34kg (dot)</mat-option>
            <mat-option value="," i18n="generic$$CSV export, use comma as decimal seperator@@comma seperator">Format: 12,34kg (comma)</mat-option>
        </mat-select>
    </mat-form-field>

    <div class="settings-header" i18n="settings$$Settings for reports, header@@Reports Settings">Reports Settings</div>
    <div class="oneline-formfields">
        <mat-form-field class="half-formfield" subscriptSizing="dynamic">
            <mat-label i18n="company$$Company name@@Company name">Company name</mat-label>
            <input [disabled]="updatingAccount || readOnly || !isAdmin" matInput
                data-test="npt_companyName" type="text"
                [ngModel]="currentUser?.account?.company?.name" name="companyName"
                placeholder="Company name" i18n-placeholder="company$$Company name@@Company name"
                (ngModelChange)="saverComp.next({ prop: 'name', value: $event })" (blur)="saveCompany('name', $event.target['value'])">
        </mat-form-field>
        <mat-form-field class="company-number half-formfield" subscriptSizing="dynamic">
            <mat-label i18n="company$$Company number@@Company number">Company number</mat-label>
            <input [disabled]="updatingAccount || readOnly || !isAdmin" matInput
                data-test="npt_companyNumber" type="text"
                [ngModel]="currentUser?.account?.company?.number" name="companyNumber"
                placeholder="Company number" i18n-placeholder="company$$Company number@@Company number"
                (ngModelChange)="saverComp.next({ prop: 'number', value: $event })" (blur)="saveCompany('number', $event.target['value'])">
        </mat-form-field>
    </div>

    <div #customsHeader class="settings-header">
        <span i18n="generic$$Customs@@Customs">Customs</span>
        <a [href]="'https://' + (isDarkmode ? 'd' : '') + 'doc.artisan.plus/updates/exporting-data/#customs--tax-report-settings'"
            data-test="lnk_customsHelp" rel="noopener" style="padding-right: 0;margin: 0 0 0 5px;" target="_blank">
            <mat-icon class="helpicon" style="vertical-align: middle;" [ngClass]="{'bigicon': !currentUser?.account?.customs?.report_type?.country || !currentUser?.account?.customs?.report_type?.variant}">{{isDarkmode ? 'help' : 'help_outline'}}</mat-icon>
        </a>
    </div>
    <div class="oneline-formfields">
        <mat-form-field data-test="slct_customsCountry" class="half-formfield" subscriptSizing="dynamic">
            <mat-label i18n="contacts$$country@@Country">Country</mat-label>
            <mat-select [disabled]="updatingAccount || readOnly || !isAdmin"
                name="customs_country" hideSingleSelectionIndicator="true"
                placeholder="Country" i18n-placeholder="contacts$$country@@Country"
                [ngModel]="currentUser?.account?.customs?.report_type?.country || '-'"
                (selectionChange)="saveCustomsCountry($event.value)">
                <mat-option value="-">-</mat-option>
                @for (country of customsCountries; track country) {
                <mat-option [value]="country">{{tr.anslate(country)}}</mat-option>
                }
            </mat-select>
        </mat-form-field>

        <mat-form-field data-test="slct_customsVariant" class="half-formfield" subscriptSizing="dynamic">
            <mat-label i18n="settings$$specific, probably local, type of@@Variant">Variant</mat-label>
            <mat-select [disabled]="updatingAccount || readOnly || !isAdmin"
                name="customs_variant" hideSingleSelectionIndicator="true"
                placeholder="Variant" i18n-placeholder="settings$$specific, probably local, type of@@Variant"
                [ngModel]="currentUser?.account?.customs?.report_type?.variant || '-'"
                (selectionChange)="saveCustomsVariant($event.value)">
                <mat-option value="-">-</mat-option>
                @for (variant of customsVariants; track variant) {
                <mat-option [value]="variant">{{variant}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>


    @if (isAdmin || otherUsers?.length || pendingUsers?.length || otherViewers?.length || pendingViewers?.length) {
    <div class="settings-header" i18n="multi users$$heading@@Sharing options">Sharing options</div>
    }

    @if (otherViewers?.length || pendingViewers?.length) {
    <div style="margin-bottom: 20px">
        <div i18n="multi users$$heading of list of viewers of this account@@Viewers with read-only access to this account:" class="minor-header">Viewers with read-only access to this account:</div>
        @for (user of otherViewers; track user) {
        <div class="flex-left">
            @if (isAdmin && !removing) {
            <button mat-icon-button class="tertiary-element"
                [disabled]="updatingAccount || readOnly || !isAdmin"
                (click)="removeViewer(user, false)">
                <mat-icon>delete</mat-icon>
            </button>
            }
            <span>
                @if (!isAdmin || removing) {
                <span>- </span>
                }
                @if (user.nickname !== user.email) {
                <span>{{user.nickname}}: </span>
                } <a [href]="'mailto:' + user.email">{{user.email}}</a>
                @if (removing) {
                <mat-spinner class="in-text-spinner" diameter="15"></mat-spinner>
                }
            </span>
        </div>
        }
        @for (user of pendingViewers; track user?._id) {
        <div class="flex-left">
            @if (isAdmin && !removing) {
            <button mat-icon-button class="tertiary-element"
                [disabled]="updatingAccount || readOnly || !isAdmin"
                (click)="removeViewer(user, true)">
                <mat-icon>delete</mat-icon>
            </button>
            }
            <span>
                @if (!isAdmin || removing) {
                <span>- </span>
                }
                @if (user.nickname !== user.email) {
                <span>{{user.nickname}}: </span>
                } <a [href]="'mailto:' + user.email">{{user.email}}</a><span>&nbsp;</span>
                <span class="littlespaceright" i18n="multi users$$in the list of users who can access this account, mark which one has been invited but not yet accepted@@pending user">(pending)</span>
                @if (!removing && !resending) {
                <span>- <a (click)="resendInvitationEmail(user)" (keydown.enter)="resendInvitationEmail(user); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer" i18n="multi users$$in the list of users who were invited to access this account, allow to resend the invitation email@@resend invitation">resend invitation</a></span>
                } @else {
                <mat-spinner class="in-text-spinner" diameter="15"></mat-spinner>
                }
            </span>
        </div>
        }
    </div>
    }
    @if (isAdmin) {
    <div class="single-button">
        <button mat-raised-button [disabled]="updatingAccount || readOnly || !isAdmin"
            class="action-button"
            i18n="multi users$$button for read-only invitation dialog@@invite for read-only access"
            (click)="addUser(true)">Invite for read-only access</button>
    </div>
    }

    <hr class="hr-spacer">

    @if ((otherUsers && otherUsers.length > 0) || (pendingUsers && pendingUsers.length > 0)) {
    <div style="margin-bottom: 20px">
        <div i18n="multi users$$heading of list of other users of this account@@Users with full access to this account:" class="minor-header">Users with full access to this account:</div>
        @for (user of otherUsers; track user?._id) {
        <div class="flex-left">
            @if (isAdmin && !removing) {
            <button mat-icon-button class="tertiary-element"
                [disabled]="updatingAccount || readOnly || !isAdmin"
                (click)="removeUser(user, false)">
                <mat-icon>delete</mat-icon>
            </button>
            }
            <span>
                @if (!isAdmin || removing) {
                <span>- </span>
                }
                @if (user.nickname !== user.email) {
                <span>{{user.nickname}}: </span>
                } <a [href]="'mailto:' + user.email">{{user.email}}</a>
                @if (user.admin) {
                <span><span>&nbsp;</span>
                    <ng-container i18n="multi users$$in the list of users who can access this account, mark which one is the admin user who can add/remove users@@admin user">(Admin)</ng-container>
                </span>
                }
                @if (removing) {
                <mat-spinner class="in-text-spinner" diameter="15"></mat-spinner>
                }
            </span>
        </div>
        }
        @for (user of pendingUsers; track user?._id) {
        <div class="flex-left">
            @if (isAdmin && !removing) {
            <button mat-icon-button class="tertiary-element"
                [disabled]="updatingAccount || readOnly || !isAdmin"
                (click)="removeUser(user, true)">
                <mat-icon>delete</mat-icon>
            </button>
            }
            <span>
                @if (!isAdmin || removing) {
                <span>- </span>
                }
                @if (user.nickname !== user.email) {
                <span>{{user.nickname}}: </span>
                } <a [href]="'mailto:' + user.email">{{user.email}}</a><span>&nbsp;</span>
                <span class="littlespaceright" i18n="multi users$$in the list of users who can access this account, mark which one has been invited but not yet accepted@@pending user">(pending)</span>
                @if (!removing && !resending) {
                <span>- <a (click)="resendInvitationEmail(user)" (keydown.enter)="resendInvitationEmail(user); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer" i18n="multi users$$in the list of users who were invited to access this account, allow to resend the invitation email@@resend invitation">resend invitation</a></span>
                }
                @if (removing || resending) {
                <mat-spinner class="in-text-spinner" diameter="15"></mat-spinner>
                }
            </span>
        </div>
        }
    </div>
    }
    @if (isAdmin) {
    <div class="single-button" style="margin-bottom: 25px;">
        <button mat-raised-button [disabled]="updatingAccount || readOnly || !isAdmin"
            class="action-button"
            i18n="multi users$$button for invitation dialog@@Add user to account"
            (click)="addUser(false)" data-test="bttn_addUser">Add full user to your account</button>
    </div>
    }

    <hr style="margin-left: 20px; margin-right: 20px;">

    @if (currentUser?.other_accounts?.length) {
    <div style="margin-bottom: 20px">
        <div i18n="multi users$$heading of list of readonly accounts that you can see@@You can see the accounts of:" class="minor-header">You can see the accounts of:</div>
        @for (user of currentUser.other_accounts; track user) {
        <div class="flex-left">
            @if (isAdmin && !removing) {
            <button mat-icon-button class="tertiary-element"
                [disabled]="updatingAccount || readOnly || !isAdmin"
                (click)="removeViewerFromMyAccount(user)">
                <mat-icon>delete</mat-icon>
            </button>
            }
            <span>
                @if (!isAdmin || removing) {
                <span>- </span>
                }
                @if (user.nickname !== user.email) {
                <span>{{user.nickname}}: </span>
                } <a [href]="'mailto:' + user.email">{{user.email}}</a>
                @if (removing) {
                <mat-spinner class="in-text-spinner" diameter="15"></mat-spinner>
                }
            </span>
        </div>
        }
    </div>
    }


    <div class="settings-header" i18n="settings$$Change your password@@Change your password">Change your password</div>

    <form id="form" name="form" (ngSubmit)="f.form.valid && updatePassword()" #f="ngForm" style="margin-top: 20px;">
        <!-- hidden username input field for password managers -->
        <input type="hidden" required placeholder="Email" i18n-placeholder="users$$email address@@email"
            data-test="npt_email" name="email" autocomplete="username" value="some@email.address">
        <mat-form-field subscriptSizing="dynamic">
            <mat-label i18n="users$$password@@password">Password</mat-label>
            <input required placeholder="Password" type="password" pattern=".{4,}"
                data-test="npt_password" matInput i18n-placeholder="users$$password@@password"
                [(ngModel)]="password" name="password" autocomplete="current-password">
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic">
            <mat-label i18n="users$$new password@@register_new_password">New password</mat-label>
            <input required placeholder="New password" type="password" pattern=".{4,}"
                data-test="npt_newpassword" matInput i18n-placeholder="users$$new password@@register_new_password"
                [(ngModel)]="newPassword" name="newPassword" autocomplete="new-password">
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic">
            <mat-label i18n="users$$repeat password@@register_password_repeat">Repeat password</mat-label>
            <input required placeholder="Repeat password" type="password" pattern=".{4,}"
                data-test="npt_repeatpassword" matInput i18n-placeholder="users$$repeat password@@register_password_repeat"
                [(ngModel)]="repeatPassword" name="repeatPassword" autocomplete="new-password">
        </mat-form-field>

        <div class="inner-action-row">
            <button [disabled]="!f.form.valid" data-test="bttn_savePassword" mat-raised-button type="submit" class="action-button">
                <mat-icon>done</mat-icon>
            </button>
        </div>
    </form>

    <div class="settings-header" i18n="settings$$download data heading@@download data heading">Download your data</div>

    <div class="flex-center">
        <span i18n="settings$$download all data@@download all data">Export all your data to a file.</span>
        @if (!downloading) {
        <button mat-raised-button (click)="download()" class="action-button only-left-margin" style="min-width: 74px;">
            <mat-icon>cloud_download</mat-icon>
        </button>
        } @else {
        <mat-spinner diameter="20"></mat-spinner>
        }
    </div>

    @if (!readOnly && isAdmin) {
    <div class="settings-header" i18n="settings$$delete data heading@@delete data heading">Delete your data</div>
    <div class="flex-center">
        <span i18n="settings$$reset your account@@reset">Reset your account: keeps your account but deletes all your data forever. Cannot be undone.</span>
        <button mat-raised-button (click)="deleteData()" class="edit-button only-left-margin" style="min-width: 74px;">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
    <br />
    <div class="flex-center">
        <span i18n="settings$$delete your account@@delete account">Delete your account: delete all your data including your account. Cannot be undone.</span>
        <button mat-raised-button (click)="deleteAccount()" class="edit-button only-left-margin" style="min-width: 74px;">
            <mat-icon>delete_forever</mat-icon>
        </button>
    </div>
    }

    <div class="enlargepage"></div>
    <div style="font-size: 50%">{{version}}</div>
</div>