import { Component, OnDestroy, Inject, LOCALE_ID, Input, Output, EventEmitter } from '@angular/core';
import { Subject, finalize, takeUntil, throttleTime } from 'rxjs';
import { Enumerations } from 'src/app/models/Enumerations';
import { InvitedUserInfo, UserType } from 'src/app/modules/frame/services/user.service';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { UnitSystemType, Utils } from 'src/app/util/utils';
import { RoastSchedule } from 'src/app/models/RoastSchedule';
import { RoastScheduledItem } from 'src/app/models/RoastScheduledItem';
import { moveItemInArray, CdkDragDrop, transferArrayItem, CdkDragStart, CdkDragMove } from '@angular/cdk/drag-drop';
import { SchedulerService } from './scheduler.service';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/util/alert/alert.service';
import { Coffee } from 'src/app/models/Coffee';
import { Blend } from 'src/app/models/Blend';
import { Location } from 'src/app/models/Location';
import { Utils2 } from 'src/app/util/utils2';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { DateTime } from 'luxon';
import omit from 'lodash-es/omit';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { RecurringDialogComponent, RecurringDialogData } from './recurring-dialog.component';
import { RoastsDialogComponent } from './roasts-dialog.component';
import { Roast } from 'src/app/models/Roast';
import { YesNoDialogComponent } from '../ui/dialog/yesno-dialog.component';

enum DRAGMODES {
    NONE = 0,
    MOVE = 1,
    CLONE = 2,
}
type DragMode = DRAGMODES;

@Component({
    selector: 'app-scheduler-planner',
    templateUrl: './scheduler-planner.component.html',
    styleUrls: ['./scheduler-planner.component.scss'],
    animations: [
        trigger('showDelete', [
            state('start', style({
                'min-width': 0,
                width: 0,
            })),
            state('end', style({
                'min-width': '{{deleteWidth}}',
                width: '{{deleteWidth}}',
            }), { params: { deleteWidth: '25px' } }),
            transition('start => end', [
                animate(SchedulerPlannerComponent.ANIMATIONDURATION)
            ]),
            transition('end => start', [
                animate(SchedulerPlannerComponent.ANIMATIONDURATION)
            ]),
        ]),
        // trigger('shrinkItem', [
        //     state('start', style({
        //         width: '350px',
        //     })),
        //     state('end', style({
        //         width: 'unset',
        //     })),
        //     transition('start => end', [
        //         animate(SchedulerPlannerComponent.ANIMATIONDURATION)
        //     ]),
        //     transition('end => start', [
        //         animate(SchedulerPlannerComponent.ANIMATIONDURATION)
        //     ]),
        // ]),
    ],
})
export class SchedulerPlannerComponent implements OnDestroy {

    constructor(
        protected schedulerService: SchedulerService,
        protected alertService: AlertService,
        protected utils: Utils,
        protected utils2: Utils2,
        protected tr: TranslatorService,
        private dialog: MatDialog,
        @Inject(LOCALE_ID) public locale: string,
    ) { }

    static readonly ANIMATIONDURATION = '0.15s';
    deleteWidth = '25px';

    isPlaceholder = false;

    _currentUser: UserType;
    @Input() set currentUser(cu: UserType) {
        this._currentUser = cu;
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lb';
            this.deleteWidth = '22px';
        }
    }
    get currentUser(): UserType {
        return this._currentUser;
    }

    _schedule: RoastSchedule;
    @Input() set schedule(rs: RoastSchedule) {
        this._schedule = rs;
        if (this.lastEditItem) {
            this.lastEditItem.selected = false;
            this.lastEditItem = undefined;
        }
        // update today as it might have changed after focus (checkTodayFun)
        this.today = DateTime.now().toISODate()
        this.deselect();
        this.animateItem = [];
        // this.setFilter(this.machineFilter, this.userFilter);
        this.schedulerService.addInfoAndSummary(this.schedule, this.filteredItems, this.mainUnit, this.users?.length);
    };
    get schedule(): RoastSchedule {
        return this._schedule;
    }

    @Input() zoomState: 's' | 'm' | 'l' = 'm';
    @Input() index = 0;
    @Input() startMonthIndex = 0;

    @Input() previousDay: boolean;
    @Input() isMainDay: boolean;
    @Input() readOnly: boolean;
    @Input() users: InvitedUserInfo[];

    @Output() editItem = new EventEmitter<{ item: RoastScheduledItem, readonly?: boolean }>();
    @Output() addCurrentItem = new EventEmitter<string>(); // date string
    @Output() updateFinished = new EventEmitter<void>();
    @Output() movedAcrossDays = new EventEmitter<{ fromDate: string, toDate: string }>();
    @Output() initiateReload = new EventEmitter<void>();
    @Output() jumpToDate = new EventEmitter<DateTime>();
    @Output() itemReceived = new EventEmitter<{ item: RoastScheduledItem, fromDate: string, toDate: string }>();
    @Output() newScheduleItems = new EventEmitter<RoastScheduledItem[]>();
    @Output() newFilteredItems = new EventEmitter<RoastScheduledItem[]>();
    @Output() itemAdded = new EventEmitter<RoastScheduledItem>();
    @Output() itemDeleted = new EventEmitter<RoastScheduledItem>();

    machineFilter: string[];
    userFilter: InvitedUserInfo[];
    filteredItems: RoastScheduledItem[] = [];
    lastEditItem: RoastScheduledItem;

    isAdding = false;
    isAddingNow = false;
    addingTimer: ReturnType<typeof setTimeout>;
    isUpdating = false;
    isUpdatingNow = false;
    updatingTimer: ReturnType<typeof setTimeout>;
    isMoving = false;
    isMovingNow = false;
    movingTimer: ReturnType<typeof setTimeout>;
    isDeleting = false;
    isDeletingNow = false;
    deletingTimer: ReturnType<typeof setTimeout>;

    dragIndex = -1;
    showClone = false;
    dragHasExited = false;

    openItemDate: string;

    protected _dragMode: DragMode = DRAGMODES.NONE;
    set dragMode(dm: DragMode) {
        if (dm === this._dragMode) {
            return;
        }
        if (dm === DRAGMODES.MOVE) {
            this.bodyElement.classList.add('inheritCursors');
            this.bodyElement.style.cursor = 'move';
        } else if (dm === DRAGMODES.CLONE) {
            this.bodyElement.classList.add('inheritCursors');
            this.bodyElement.style.cursor = 'copy';
        } else if (!dm) {
            this.bodyElement.classList.remove('inheritCursors');
            this.bodyElement.style.cursor = 'unset';
        }
        this._dragMode = dm;
    };
    get dragMode(): DragMode {
        return this._dragMode;
    }

    animateItem = [];
    isRecurringEnabledCached = false;

    today = DateTime.now().toISODate();
    mainUnit: UnitSystemType = 'kg';

    protected ngUnsubscribe = new Subject();

    // for the copy drag and drop cursor
    bodyElement: HTMLElement = document.body;

    Math = Math;
    isFinite = isFinite;
    DateTime = DateTime;
    DRAGMODES = DRAGMODES;
    parseFloat = parseFloat;


    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    public getDate(): string {
        return this.schedule?.date;
    }

    public setFilter(machineFilter: string[], userFilter: InvitedUserInfo[]) {
        if (!machineFilter) {
            this.filteredItems = this.schedule.items?.slice() ?? [];
        } else if (!machineFilter.length) {
            this.filteredItems = this.schedule.items?.filter(itm => !itm?.machine) ?? [];
        } else {
            this.filteredItems = this.schedule.items?.filter(itm => !itm?.machine || machineFilter.includes(itm.machine)) ?? [];
        }
        if (userFilter) {
            if (!userFilter.length) {
                this.filteredItems = this.filteredItems?.filter(itm => !itm?.user) ?? [];
            } else {
                this.filteredItems = this.filteredItems?.filter(itm => !itm?.user || userFilter.map(u => u._id).includes(itm.user)) ?? [];
            }
        }
        this.deselect(false);
        this.schedulerService.addSummary(this.schedule, this.filteredItems, this.mainUnit);
        this.machineFilter = machineFilter;
        this.userFilter = userFilter;
    }

    protected cloneSchedule(event: MatDatepickerInputEvent<DateTime>) {
        if (this.readOnly) { return; }

        if (event?.value?.isValid) {
            const itemsToSend: RoastScheduledItem[] = [];
            for (const item of this.filteredItems) {
            // for (let i = 0; i < this.filteredItems.length; i++) {
                // const item = this.filteredItems[i];
                const itemToSend = omit(item, ['info1', 'info2', 'tooltip', 'blendInfo', 'subtitle', 'selected', 'nickname', 'synced', 'roasts']);

                itemToSend.date = event.value.toISODate();

                if (itemToSend.template) {
                    itemToSend.template = { id: itemToSend.template['id'] ?? itemToSend.template['roast_id'], label: itemToSend.template.label };
                }
                if (itemToSend.location) {
                    itemToSend.location = itemToSend.location.hr_id as unknown as Location;
                }

                if (itemToSend.coffee) {
                    itemToSend.coffee = itemToSend.coffee.hr_id as unknown as Coffee;
                }
                if (itemToSend.blend) {
                    itemToSend.blend = itemToSend.blend.hr_id as unknown as Blend;
                }
                itemsToSend.push(this.utils2.cleanResult(itemToSend));
            }

            this.addingTimer = setTimeout(() => this.isAdding = true, 600);
            this.schedulerService.addItems(itemsToSend)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .pipe(finalize(() => { clearTimeout(this.addingTimer); this.isAdding = this.isAddingNow = false; }))
                .subscribe({
                    next: response => {
                        if (response.success === true) {
                            this.alertService.success(this.tr.anslate('Successfully added'));
                            this.reloadSchedule();
                        } else {
                            this.utils.handleError('error updating the schedule', response.error);
                        }
                    },
                    error: error => {
                        this.utils.handleError('error updating the schedule', error);
                    }
                });
        }
    }

    public addItem(item: RoastScheduledItem): void {
        if (this.readOnly) { return; }

        if (this.isAddingNow) return;
        this.isAddingNow = true;

        // // TODO remove test data
        // // START create some test data
        // item.roasts = [];
        // const len = Math.floor(item.count * (Math.round(Math.random() * 10) / 10));
        // for (let i = 0; i < len; i++) {
        //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
        //     item.roasts.push('a9b2723db0af4cc6a7725884dc8f9c79' as unknown as any);
        // }
        // // END test data

        item.date = this.schedule.date;
        // don't select since this is confusing for people who want to 
        // directly add another item
        // this.selectItem(this.schedule.items.length - 1);

        const itemToSend = omit(item, ['info1', 'info2', 'tooltip', 'blendInfo', 'subtitle', 'selected', 'nickname', 'roasts', 'roasted', 'itemAmount', 'synced', '_id', 'isPostBlend']);

        const itemsToSend = [itemToSend];
        const items = [item];

        if (itemToSend.template) {
            itemToSend.template = {
                roast_id: itemToSend.template['roast_id'] ?? itemToSend.template['id'],
                label: itemToSend.template.label,
            };
        }
        if (itemToSend.location) {
            itemToSend.location = itemToSend.location.hr_id as unknown as Location;
        }

        if (item.isPostBlend && item.blend?.ingredients?.length >= 2) {
            // remove blend, split into coffee ingredients
            itemsToSend.length = 0;
            items.length = 0;
            const amount = itemToSend.amount;
            for (let i = 0; i < item.blend.ingredients?.length; i++) {
                const ing = item.blend.ingredients[i];
                if (!ing?.coffee) {
                    continue;
                }
                const newItem = Object.assign({}, item);
                newItem.coffee = ing.coffee;
                newItem.blend = undefined;
                newItem.amount = Math.round(amount * ing.ratio * 1000) / 1000;
                newItem.title = `${i + 1}/${item.blend.ingredients.length} ${item.title}`;
                items.push(newItem);

                const newItemToSend = Object.assign({}, itemToSend);
                newItemToSend.coffee = ing.coffee.hr_id as unknown as Coffee;
                newItemToSend.blend = undefined;
                newItemToSend.amount = Math.round(amount * ing.ratio * 1000) / 1000;
                newItemToSend.title = `${i + 1}/${item.blend.ingredients.length} ${itemToSend.title}`;
                itemsToSend.push(newItemToSend);
            }
        } else {
            if (itemToSend.coffee) {
                itemToSend.coffee = itemToSend.coffee.hr_id as unknown as Coffee;
            }
            if (itemToSend.blend) {
                itemToSend.blend = itemToSend.blend.hr_id as unknown as Blend;
            }
        }

        itemsToSend.forEach(itm => this.utils2.cleanResult(itm));

        this.addingTimer = setTimeout(() => this.isAdding = true, 600);
        this.schedulerService.addItems(itemsToSend)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .pipe(finalize(() => { clearTimeout(this.addingTimer); this.isAdding = this.isAddingNow = false; }))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        if (!this.schedule.items) {
                            this.schedule.items = [];
                        }
                        const itemIds = response.result;
                        itemIds.forEach((itemId, idx) => {
                            item = items[idx];
                            item._id = itemId;
                            this.schedulerService.addInfo(item, this.mainUnit, this.users?.length);
                            this.schedule.items.push(item);
                        });
                        this.schedulerService.addSummary(this.schedule, this.filteredItems, this.mainUnit);
                        // scheduler.component->onNewItem ensures that the item is shown with the current filter
                        this.setFilter(this.machineFilter, this.userFilter);
                        // this is done in scheduler.component->addCurrentItem
                        // this.deselect();
                        this.alertService.success(this.tr.anslate('Successfully added'));
                    } else {
                        this.utils.handleError('error updating the schedule', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating the schedule', error);
                }
            });
    }

    public addItemAt(item: RoastScheduledItem, index: number): void {
        if (this.readOnly) { return; }

        if (this.isAddingNow) return;
        this.isAddingNow = true;

        this.schedulerService.addInfo(item, this.mainUnit, this.users?.length);
        if (!this.schedule.items) {
            this.schedule.items = [];
        }
        this.schedule.items.splice(index, 0, item);
        this.schedulerService.addSummary(this.schedule, this.filteredItems, this.mainUnit);
        // scheduler.component->onNewItem ensures that the item is shown with the current filter
        this.setFilter(this.machineFilter, this.userFilter);
        if (this.isPlaceholder) {
            this.newScheduleItems.emit(this.schedule.items);
            this.newFilteredItems.emit(this.filteredItems);
        }

        item.date = this.schedule.date;
        // don't select since this is confusing for people who want to 
        // directly add another item
        // this.selectItem(this.schedule.items.length - 1);

        const itemToSend = omit(item, ['info1', 'info2', 'tooltip', 'blendInfo', 'subtitle', 'selected', 'nickname', 'roasts', 'roasted', 'itemAmount', 'synced', '_id', 'isPostBlend']);

        if (itemToSend.template) {
            itemToSend.template = { roast_id: itemToSend.template['id'] ?? itemToSend.template['roast_id'], label: itemToSend.template.label };
        }
        if (itemToSend.location) {
            itemToSend.location = itemToSend.location.hr_id as unknown as Location;
        }

        if (itemToSend.coffee) {
            itemToSend.coffee = itemToSend.coffee.hr_id as unknown as Coffee;
        }
        if (itemToSend.blend) {
            itemToSend.blend = itemToSend.blend.hr_id as unknown as Blend;
        }

        this.utils2.cleanResult(itemToSend);

        this.addingTimer = setTimeout(() => this.isAdding = true, 600);
        this.schedulerService.addItem(itemToSend, index)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .pipe(finalize(() => { clearTimeout(this.addingTimer); this.isAdding = this.isAddingNow = false; }))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        const itemId = response.result;
                        item._id = itemId;
                        this.alertService.success(this.tr.anslate('Successfully added'));
                        this.itemAdded.emit(item);
                    } else {
                        this.utils.handleError('error updating the schedule', response.error);
                        this.reloadSchedule();
                    }
                },
                error: error => {
                    this.utils.handleError('error updating the schedule', error);
                    this.reloadSchedule();
                }
            });
    }

    protected hasDeletableItems(): boolean {
        if (this.schedule.date < this.today) {
            // past
            return false;
        }
        // today or future
        for (const item of this.filteredItems) {
            if (!item.synced && !item.roasts?.length) {
                return true;
            }
        }
        return false;
    }

    // protected findItemIdx(schedule: RoastSchedule, item: RoastScheduledItem): number {
    //     if (!schedule || !item || !schedule.items?.length) {
    //         return undefined;
    //     }
    //     for (let i = 0; i < schedule.items.length; i++) {
    //         const sitem = schedule.items[i];
    //         if (sitem?._id?.toString() === item._id.toString()) {
    //             return i;
    //         }
    //     }
    //     return undefined;
    // }

    /**
     * Sends this.lastEditItem to the server.
     * Assigns editModeItemOriginal on error as an undo operation.
     * @param editModeItemOriginal the item before the changes have been applied
     * @returns true if an update was successfully made
     */
    public updateEditedItem(editModeItemOriginal: RoastScheduledItem): boolean {
        if (this.readOnly) { return; }

        if (this.lastEditItem) {
            if (this.schedule.date < this.today) {
                return;
            }

            if (this.isUpdatingNow) return;
            this.isUpdatingNow = true;

            // TODO check: doesn't work since the lastEditItem is already the changed one
            // const updatedItem = this.findItem(schedule, this.lastEditItem);
            // if (JSON.stringify(this.lastEditItem) === JSON.stringify(updatedItem)) {
            //     this.alertService.success(this.tr.anslate('Nothing to change'));
            //     return;
            // }
            const itemCopy = omit(this.lastEditItem, ['info1', 'info2', 'tooltip', 'blendInfo', 'subtitle', 'selected', 'nickname', 'itemAmount', 'marginRightForDelete', 'selected', 'nickname']);
            itemCopy.coffee = itemCopy.coffee?.hr_id as unknown as Coffee;
            itemCopy.location = itemCopy.location?.hr_id as unknown as Location;
            itemCopy.blend = (itemCopy.blend?.hr_id ?? itemCopy.blend?.label) as unknown as Blend;
            if (itemCopy.template) {
                itemCopy.template = {
                    roast_id: itemCopy.template['roast_id'] ?? itemCopy.template['id'],
                    label: itemCopy.template.label,
                };
            }

            this.updatingTimer = setTimeout(() => this.isUpdating = true, 600);
            this.schedulerService.updateItem(this.schedule, itemCopy)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .pipe(finalize(() => {
                    clearTimeout(this.updatingTimer);
                    this.isUpdating = this.isUpdatingNow = false;
                    this.updateFinished.emit();
                }))
                .subscribe({
                    next: response => {
                        if (!response || response.success === true) {
                            // this.lastEditItem = response.result;
                            // this.lastEditItem.selected = true;
                            this.schedulerService.addInfo(this.lastEditItem, this.mainUnit, this.users?.length);
                            // const idx = this.findItemIdx(this.schedule, this.lastEditItem);
                            // this.schedule.items.splice(idx, 1, this.lastEditItem);
                            this.schedulerService.addSummary(this.schedule, this.filteredItems, this.mainUnit);
                            this.alertService.success(this.tr.anslate('Successfully updated'));
                            this.deselect();
                            // // update reference to item
                            // this.editItem.emit({ item: this.lastEditItem });
                        } else {
                            this.reloadSchedule();
                            this.utils.handleError('error updating the schedule', response.error);
                        }
                    },
                    error: error => {
                        // undo change without changing the pointer
                        Object.assign(this.lastEditItem, editModeItemOriginal);
                        this.reloadSchedule();
                        this.utils.handleError('error updating the schedule', error);
                    }
                });
        }
    }

    protected deleteSchedule(): void {
        if (this.readOnly) { return; }

        const dialogRef = this.dialog.open(YesNoDialogComponent, {
            closeOnNavigation: true,
            data: { text: this.tr.anslate('Do you really want to delete {{name}}?', { name: DateTime.fromISO(this.schedule.date).toLocaleString(DateTime.DATE_MED) }) }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
            this.updatingTimer = setTimeout(() => this.isUpdating = true, 600);
            this.deselect(true);
            this.schedulerService.deleteSchedule(this.schedule.date)
                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                .pipe(finalize(() => {
                    clearTimeout(this.updatingTimer);
                    this.isUpdating = this.isUpdatingNow = false;
                    this.updateFinished.emit();
                }))
                .subscribe({
                    next: response => {
                        if (!response || response.success === true) {
                            if (!response?.result) {
                                this.schedule.items = [];
                                this.filteredItems = [];
                                this.schedulerService.addSummary(this.schedule, this.filteredItems, this.mainUnit);
                                this.alertService.success(this.tr.anslate('Successfully updated'));
                            } else {
                                // have some items that could not be deleted
                                this.alertService.success(this.tr.anslate('Some items could not be deleted'));
                            }
                            // reload in any case; mostly bc of the stock; don't know about includePlannedInStock
                            this.reloadSchedule();
                            this.deselect();
                        } else {
                            this.reloadSchedule();
                            this.utils.handleError('error updating the schedule', response.error);
                        }
                    },
                    error: error => {
                        this.reloadSchedule();
                        this.utils.handleError('error updating the schedule', error);
                    }
                });
            }
        });
    }

    public deselect(emitChange = true): void {
        if (this.lastEditItem) {
            this.lastEditItem.selected = false;
            // don't bother if none show the delete (e.g. if readonly)
            if (this.animateItem.some(itm => itm)) {
                setTimeout(() => {
                    // need to find the item or just use all
                    for (let i = 0; i < this.animateItem.length; i++) {
                        this.animateItem[i] = false;
                    }
                }, 10);
            }
            this.lastEditItem = undefined;
            if (emitChange) {
                this.editItem.emit({ item: undefined });
            }
        }
    }

    private edit(item: RoastScheduledItem | undefined, doEmit = true, readonly = false): void {
        this.lastEditItem = item;
        if (this.lastEditItem) {
            this.lastEditItem.date = this.schedule.date;
        }
        if (doEmit) {
            this.editItem.emit({ item, readonly });
        }
    }

    /**
     * Selects the given item or deselects if already selected. Deselects all others.
     * @param idx index of the item within filteredItems
     */
    protected selectItem(idx: number): void {
        for (let i = 0; i < this.filteredItems?.length; i++) {
            const item = this.filteredItems[i];
            if (item.selected) {
                // deselect in any case (either clicked on same item or another)
                item.selected = false;
                setTimeout(() => {
                    this.animateItem[i] = false;
                }, 10);
                if (i === idx) {
                    // clicked on selected item, unselect and finish
                    this.edit(undefined);
                    return;
                }
                continue;
            }
            if (i === idx) {
                item.selected = true;
                const readonly = this.schedule.date < this.today || item.roasts?.length > 0 || item.synced;
                this.edit(item, true, readonly);
                if (!readonly) {
                    setTimeout(() => {
                        this.animateItem[i] = true;
                    }, 10);
                }
            }
        }
    }

    /**
     * Finds the item given by items[idx] in this.schedule.items (or the
     * given allItems list) and returns its index.
     * @param items the items list for which the index is given
     * @param idx the index into items
     * @param allItems the items list in which should be searched; uses this.schedule.items if not given
     * @returns the index of the items[idx] in this.schedule.items
     */
    private getRealFromFilteredIndex(items: RoastScheduledItem[], idx: number, allItems?: RoastScheduledItem[]): number {
        const searchList = allItems ?? this.schedule?.items;
        if (!searchList?.length) {
            return idx;
        }
        let realIdx = idx;
        if (items.length !== searchList.length) {
            // need index in real schedule list
            const searchId = items[idx]?._id;
            if (!searchId) {
                return idx;
            }
            for (let i = 0; i < searchList.length; i++) {
                const item = searchList[i];
                if (item._id === searchId) {
                    realIdx = i;
                    break;
                }
            }
        }
        return realIdx;
    }

    protected deleteItem(idx: number): void {
        if (this.readOnly) { return; }

        if (this.isDeletingNow || this.needsClone(this.schedule.date, this.filteredItems[idx])) {
            return;
        }
        this.isDeletingNow = true;

        this.deletingTimer = setTimeout(() => this.isDeleting = true, 600);
        const realIdx = this.getRealFromFilteredIndex(this.filteredItems, idx);
        this.schedulerService.deleteItem(this.schedule.date, realIdx)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .pipe(finalize(() => { clearTimeout(this.deletingTimer); this.isDeleting = this.isDeletingNow = false; }))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        const [removedItem] = this.schedule.items.splice(realIdx, 1);
                        this.filteredItems.splice(idx, 1);
                        this.schedulerService.addSummary(this.schedule, this.filteredItems, this.mainUnit);
                        this.alertService.success(this.tr.anslate('Successfully deleted'));
                        this.itemDeleted.emit(removedItem);
                    } else {
                        this.reloadSchedule();
                        this.utils.handleError('error updating the schedule', response.error);
                    }
                },
                error: error => {
                    this.reloadSchedule();
                    this.utils.handleError('error updating the schedule', error);
                }
            });
    }

    emitMovedAcrossDays(event: { fromDate: string, toDate: string }): void {
        if (this.readOnly) { return; }

        this.movedAcrossDays.emit(event);
    }

    // /**
    //  * Called when an item is moved into a placeholder.
    //  * @param $event dates from and to an item was moved; toDate should be this schedule
    //  */
    // placeHolderFilled(data: { item: RoastScheduledItem, fromDate: string, toDate: string }) {
    //     if (data.toDate === this.schedule.date) {
    //         // this.schedule.items.push(data.item);
    //         this.schedule.items = [data.item];
    //         this.filteredItems = [data.item];
    //         if (data.fromDate === data.toDate) {
    //             this.schedulerService.addSummary(this.schedule, this.filteredItems, this.mainUnit);
    //         } else {
    //             this.movedAcrossDays.emit({ fromDate: data.fromDate, toDate: data.toDate });
    //         }
    //     }
    // }

     
    protected drop(event: CdkDragDrop<{ items?: RoastScheduledItem[], date?: string, allItems?: RoastScheduledItem[], fromFavorites?: boolean }, { items?: RoastScheduledItem[], date?: string, allItems?: RoastScheduledItem[], fromFavorites?: boolean }, { date: string, idx: number, item: RoastScheduledItem }>) {
        this.bodyElement.classList.remove('inheritCursors');
        this.bodyElement.style.cursor = 'unset';

        if (this.readOnly || this.isMovingNow || this.schedule.date < this.today) {
            return;
        }

        const fromDate = event.previousContainer.data?.date;
        const toDate = this.schedule.date;
        if (fromDate === toDate && event.previousIndex === event.currentIndex) {
            return;
        }

        const realCurrentIndex = this.getRealFromFilteredIndex(this.filteredItems, event.currentIndex);

        if (event.previousContainer.data.fromFavorites) {
            // clone from favorites
            const newItem = { ...event.item?.data?.item };
            newItem.synced = undefined;
            newItem.roasts = undefined;
            newItem.roasted = undefined;
            newItem._id = undefined;
            // this.filteredItems.splice(event.currentIndex, 0, newItem);
            // if (!this.schedule.items) {
            //     this.schedule.items = [];
            // }
            // this.schedule.items.splice(realCurrentIndex, 0, newItem);

            if (event.item?.data?.item) {
                // this.addItem({ ...event.item.data.item });
                this.addItemAt(newItem, realCurrentIndex);
                this.deselect(false);
                this.editItem.emit({ item: undefined });
            }
            return;
        }

        // this.dragMode has been set to NONE in dragReleased already
        // and when dragging to another day, this.dragMode has only been set
        // on the source anyway
        const clone = event.event.altKey || this.needsClone(fromDate, event.previousContainer.data?.items[event.previousIndex]);

        if (!event.container.data?.items?.length) {
            if (event.container.data) {
                event.container.data.items = [];
            }
        }
        // calc those before moveItemInArray or transferArrayItem
        const realPreviousIndex = this.getRealFromFilteredIndex(event.previousContainer.data?.items, event.previousIndex, event.previousContainer.data?.allItems);

        if (clone) {
            this.filteredItems.splice(event.currentIndex, 0, { ...event.previousContainer.data.items[event.previousIndex] });
            delete this.filteredItems[event.currentIndex]._id;
            if (!this.schedule.items) {
                this.schedule.items = [];
            }
            this.schedule.items.splice(realCurrentIndex, 0, { ...event.previousContainer.data.items[event.previousIndex] });
            delete this.schedule.items[realCurrentIndex]._id;
        } else if (fromDate === toDate) {
            // moving to realCurrentIndex moves directly before the hidden items
            // moving to event.currentIndex would move to the beginning of the hidden items
            moveItemInArray(this.filteredItems, event.previousIndex, event.currentIndex);
            moveItemInArray(this.schedule.items, realPreviousIndex, realCurrentIndex);
        } else {
            // add to this.schedule.items
            if (!this.schedule.items) {
                this.schedule.items = [];
            }
            this.schedule.items.splice(realCurrentIndex, 0, event.previousContainer.data?.items?.[event.previousIndex]);
            // move from other date's filteredItems
            transferArrayItem(event.previousContainer.data?.items, this.filteredItems, event.previousIndex, event.currentIndex);
            // remove from other date's schedule.items
            event.previousContainer.data.allItems.splice(realPreviousIndex, 1);
        }
        this.filteredItems[event.currentIndex].synced = undefined;
        this.filteredItems[event.currentIndex].roasts = undefined;
        this.filteredItems[event.currentIndex].roasted = undefined;
        this.schedule.items[realCurrentIndex].synced = undefined;
        this.schedule.items[realCurrentIndex].roasts = undefined;
        this.schedule.items[realCurrentIndex].roasted = undefined;

        this.deselect(true);

        this.movingTimer = setTimeout(() => this.isMoving = true, 600);
        this.isMovingNow = true;
        this.schedulerService.moveItem(realCurrentIndex, this.schedule.date, realPreviousIndex, fromDate, clone)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .pipe(finalize(() => { clearTimeout(this.movingTimer); this.isMoving = this.isMovingNow = false; }))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        if (response?.result) {
                            // set new _id if a clone was created
                            this.filteredItems[event.currentIndex]._id = response.result;
                            this.schedule.items[realCurrentIndex]._id = response.result;
                        }

                        if (this.isPlaceholder) {
                            this.newScheduleItems.emit(this.schedule.items);
                            this.newFilteredItems.emit(this.filteredItems);
                        }
                        // this.itemReceived.emit({ item: this.filteredItems[event.currentIndex], fromDate, toDate });
                        if (fromDate === toDate) {
                            this.schedulerService.addSummary(this.schedule, this.filteredItems, this.mainUnit);
                        } else {
                            // this updates the summaries in the source and this schedule
                            this.emitMovedAcrossDays({ fromDate: fromDate, toDate: this.schedule.date });
                        }
                        if (clone) {
                            this.itemAdded.emit(this.schedule.items[realCurrentIndex]);
                        }
                    } else {
                        this.reloadSchedule();
                        this.utils.handleError('error updating the schedule', response.error);
                    }
                },
                error: error => {
                    // // undo local movement (not tested)
                    // if (clone) {
                    //     this.filteredItems.splice(event.currentIndex, 1);
                    //     this.schedule.items.splice(realCurrentIndex, 1);
                    // } else if (fromDate === toDate) {
                    //     moveItemInArray(this.schedule.items, realCurrentIndex, realPreviousIndex);
                    //     moveItemInArray(this.filteredItems, event.currentIndex, event.previousIndex);
                    // } else {
                    //     event.previousContainer.data.allItems.splice(realPreviousIndex, 0, this.filteredItems[event.currentIndex]);
                    //     transferArrayItem(this.filteredItems, event.previousContainer.data?.items, event.currentIndex, realPreviousIndex);
                    // }
                    this.reloadSchedule();
                    this.utils.handleError('error updating the schedule', error);
                }
            });
    }

    /**
     * Predicate function that prevents dragging an item
     * before or between synced items.
     */
    preventDragBeforeSynced = (index: number) => {
        // if (this.schedule.date > this.today) {
        //     return true;
        // }
        let lastSyncedIndex = -1;
        for (let i = 0; i < this.filteredItems?.length; i++) {
            if (this.filteredItems[i].synced || this.filteredItems[i].roasts?.length) {
                lastSyncedIndex = i;
            } else {
                // assume all synced items to be in the front
                break;
            }
        }
        return index > lastSyncedIndex;
    }

    /**
     * Decides whether the item can be removed or needs to be cloned.
     * @param fromDate the date from which the item would be removed
     * @param item the item to remove
     * @returns true if the item should not be removed
     */
    protected needsClone(fromDate: string, item: RoastScheduledItem): boolean {
        return item?.synced || (fromDate < this.today) || item?.roasts?.length > 0;
    }

    dragStarted(data: CdkDragStart<{ item: RoastScheduledItem, idx: number, date: string }>) {
        if (this.readOnly) { return; }

        // the dragMode setter will set the copy cursor if true
        if (data.event.altKey) {
            this.dragMode = DRAGMODES.CLONE;
            return;
        }
        const fromDate = data?.source?.data?.date ?? DateTime.now().toISODate();
        this.dragMode = this.needsClone(fromDate, data?.source?.data?.item) ? DRAGMODES.CLONE : DRAGMODES.MOVE;
        this.dragIndex = data?.source?.data?.idx;
    }

    dragMoved(data: CdkDragMove<{ item: RoastScheduledItem, idx: number, date: string }>) {
        if (this.readOnly) { return; }

        if (this.dragMode !== DRAGMODES.CLONE) {
            if (data.event.altKey) {
                this.dragMode = DRAGMODES.CLONE;
                // this.dragExited();
                if (this.dragHasExited) {
                    this.showClone = true;
                }
            }
        } else { // CLONE
            if (!data.event.altKey && !this.needsClone(data?.source?.data?.date, data?.source?.data?.item)) {
                this.dragMode = DRAGMODES.MOVE;
            }
        }
    }

    dragExited() {
        if (this.readOnly) { return; }

        if (this.dragMode === DRAGMODES.CLONE) {
            // display cloned placeholder
            this.showClone = true;
        }
        this.dragHasExited = true;
        this.bodyElement.classList.remove('inheritCursors');
        this.bodyElement.style.cursor = 'unset';
    }

    dragEntered(data: { item?: { data?: { fromFavorites?: boolean, items?: RoastScheduledItem[], date?: string, allItems?: RoastScheduledItem[], item?: RoastScheduledItem } } }) {
        if (this.readOnly) { return; }

        if (data.item?.data?.fromFavorites || data.item?.data?.item?.synced) {
            this.bodyElement.classList.add('inheritCursors');
            this.bodyElement.style.cursor = 'copy';
        }
        this.showClone = false;
        this.dragHasExited = false;
    }

    dragReleased() {
        if (this.readOnly) { return; }

        this.dragMode = DRAGMODES.NONE;
        this.showClone = false;
        this.dragHasExited = false;
        this.dragIndex = -1;
        this.bodyElement.classList.remove('inheritCursors');
        this.bodyElement.style.cursor = 'unset';
    }

    protected reloadSchedule(): void {
        this.initiateReload.emit();
    }

    /**
     * Opens a dialog to allow to create copies of the given item or whole day
     * @param item if given, uses the info to calculate max number of 
     * repititions according to stock; if undefined, offers to clone the whole day
     */
    openRecurringDialog(item?: RoastScheduledItem) {
        let batchSize = 0;
        let stock = 0;
        let minRepsIdx = -1;
        let minRepsCoffeeInfo: { coffee: Coffee, amount: number };
        if (item) {
            stock = item.coffee?.totalStock ?? item.blend?.totalStock ?? 0;
            batchSize = (item.count * item.amount) || 0;
        } else if (this.schedule.items?.length) {
            // make sure totalStock has been set
            this.schedulerService.calculateStock(this.schedule.items, this.mainUnit);

            // need to sum up all items that use the same coffee
            // TODO need to take replacement coffees into account
            let haveMultipleItemsWithSameCoffee = false;
            const coffeeUsageMap = new Map<string, { coffee: Coffee, amount: number }>();
            for (const itm of this.schedule.items) {
                if (itm.coffee) {
                    const entry = coffeeUsageMap.get(itm.coffee._id);
                    if (!entry) {
                        coffeeUsageMap.set(itm.coffee._id, { coffee: itm.coffee, amount: itm.count * itm.amount });
                    } else {
                        coffeeUsageMap.set(itm.coffee._id, { coffee: itm.coffee, amount: entry.amount + itm.count * itm.amount });
                        haveMultipleItemsWithSameCoffee = true;
                    }
                } else if (itm.blend) {
                    for (let b = 0; b < itm.blend.ingredients?.length; b++) {
                        const ing = itm.blend.ingredients[b];
                        // const stockAtLoc = getStockAtLocation(ing.coffee, item.location);
                        const entry = coffeeUsageMap.get(ing.coffee._id);
                        if (!entry) {
                            coffeeUsageMap.set(ing.coffee._id, { coffee: ing.coffee, amount: itm.count * itm.amount * (ing.ratio || 0) });
                        } else {
                            coffeeUsageMap.set(ing.coffee._id, { coffee: ing.coffee, amount: entry.amount + itm.count * itm.amount * (ing.ratio || 0) });
                            haveMultipleItemsWithSameCoffee = true;
                        }
                    }
                }
            }

            if (!haveMultipleItemsWithSameCoffee) {
                // find item with least number of possible repetitions
                let minReps = Number.POSITIVE_INFINITY;
                for (let i = 0; i < this.schedule.items.length; i++) {
                    const itm = this.schedule.items[i];
                    const itmStock = itm.coffee?.totalStock ?? itm.blend?.totalStock ?? 0;
                    const itmBatchSize = (itm.count * itm.amount) || 0;
                    const maxReps = Math.floor(itmStock / itmBatchSize);
                    if (maxReps < minReps) {
                        minReps = maxReps;
                        minRepsIdx = i;
                    }
                }
                if (minRepsIdx >= 0) {
                    stock = this.schedule.items[minRepsIdx].coffee?.totalStock ?? this.schedule.items[minRepsIdx].blend?.totalStock ?? 0;
                    batchSize = (this.schedule.items[minRepsIdx].count * this.schedule.items[minRepsIdx].amount) || 0;
                }
            } else {
                // find coffee with least number of possible repetitions
                let minReps = Number.POSITIVE_INFINITY;
                coffeeUsageMap.forEach(coffeeInfo => {
                    const itmStock = coffeeInfo.coffee?.totalStock ?? 0;
                    const itmBatchSize = coffeeInfo.amount || 0;
                    const maxReps = Math.floor(itmStock / itmBatchSize);
                    if (maxReps < minReps) {
                        minReps = maxReps;
                        minRepsCoffeeInfo = coffeeInfo;
                    }
                });
                if (minRepsCoffeeInfo) {
                    stock = minRepsCoffeeInfo.coffee.totalStock ?? 0;
                    batchSize = minRepsCoffeeInfo.amount || 0;
                }
            }
        }
        const dialogRef = this.dialog.open<RecurringDialogComponent, RecurringDialogData, { add?: DateTime[], delete?: DateTime[] }>(RecurringDialogComponent, {
            closeOnNavigation: true,
            data: {
                item,
                items: !item ? this.schedule.items : undefined,
                minRepsIdx: !item ? minRepsIdx : undefined,
                minRepsCoffeeInfo: !item ? minRepsCoffeeInfo : undefined,
                batchSize,
                stock,
                mainUnit: this.mainUnit,
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result?.add?.length) {
                if (this.isAddingNow) return;
                this.isAddingNow = true;

                this.addingTimer = setTimeout(() => this.isAdding = true, 600);
                this.schedulerService.addClones(result.add, item?.date ?? this.schedule.date, item?._id?.toString())
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .pipe(finalize(() => { clearTimeout(this.addingTimer); this.isAdding = this.isAddingNow = false; }))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                if (response.result) {
                                    this.alertService.success(this.tr.anslate('Successfully added') + ` ${response.result}`);
                                } else {
                                    this.alertService.success(this.tr.anslate('Nothing to change'));
                                }
                            } else {
                                this.utils.handleError('error updating the schedule', response.error);
                            }
                            this.reloadSchedule();
                        },
                        error: error => {
                            this.reloadSchedule();
                            this.utils.handleError('error updating the schedule', error);
                        }
                    });
            } else if (result?.delete?.length) {
                if (this.isDeletingNow) return;
                this.isDeletingNow = true;

                this.deletingTimer = setTimeout(() => this.isDeleting = true, 600);
                this.schedulerService.deleteClones(result.delete, item?.date ?? this.schedule.date, item?._id?.toString())
                    .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
                    .pipe(finalize(() => { clearTimeout(this.deletingTimer); this.isDeleting = this.isDeletingNow = false; }))
                    .subscribe({
                        next: response => {
                            if (response.success === true) {
                                if (response.result) {
                                    this.alertService.success(this.tr.anslate('Successfully removed') + ` ${response.result}`);
                                } else {
                                    this.alertService.success(this.tr.anslate('Nothing to change'));
                                }
                            } else {
                                this.utils.handleError('error updating the schedule', response.error);
                            }
                            this.reloadSchedule();
                        },
                        error: error => {
                            this.reloadSchedule();
                            this.utils.handleError('error updating the schedule', error);
                        }
                    });
            }
        });
    }

    /**
     * Opens a dialog showing the list of associated roasts
     * @param item the scheduled item for which to show the lists of roasts
     */
    openRoastsDialog(item: RoastScheduledItem) {
        if (!item) { return; }

        const dialogRef = this.dialog.open<RoastsDialogComponent, { roasts: Roast[], mainUnit: UnitSystemType }>(RoastsDialogComponent, {
            closeOnNavigation: true,
            data: {
                roasts: item.roasts,
                mainUnit: this.mainUnit,
            },
        });

        dialogRef.afterClosed().subscribe();
    }

    showAllItems(date: string): void {
        if (this.openItemDate === date) {
            this.openItemDate = undefined;
        } else {
            this.openItemDate = date;
        }
    }

    openDate(date: string): void {
        if (date) {
            this.jumpToDate.emit(DateTime.fromISO(date));
        }
    }

    updateCoffeesAndBlends(coffees: Coffee[]): void {
        if (!coffees?.length || !this.schedule?.items?.length) {
            return;
        }
        for (const item of this.schedule.items) {
            if (item?.coffee) {
                for (const cof of coffees) {
                    if (item.coffee?._id === cof?._id) {
                        item.coffee = cof;
                        break;
                    }
                }
            } else if (item?.blend) {
                let someChanged = false;
                for (let ingIdx = 0; ingIdx < item.blend.ingredients?.length; ingIdx++) {
                    const ing = item.blend.ingredients[ingIdx];
                    let foundBoth = 0;
                    for (const cof of coffees) {
                        if (ing?.coffee?._id === cof?._id) {
                            ing.coffee = cof;
                            someChanged = true;
                            foundBoth += 1;
                        }
                        if (ing?.replace_coffee?._id === cof?._id) {
                            ing.replace_coffee = cof;
                            someChanged = true;
                            foundBoth += 1;
                        }
                        if (foundBoth === 2) {
                            break;
                        }
                    }
                }
                if (someChanged) {
                    // need to recalc stock
                    item.blend.totalStock = this.utils.getBlendStock(item.blend, item.location ? [item.location?._id?.toString()] : 'all', true);
                    item.blend.totalStockStr = this.utils.formatAmount(item.blend.totalStock, undefined, this.currentUser?.unit_system, 1);
                }
            }
        }
    }

    isRecurringEnabled(): boolean {
        console.log('calling isRecurringEnabled');
        for (const item of this.schedule.items) {
            if (item.blend && !item.blend.hr_id) {
                return false;
            }
            if (item.coffee && !item.coffee.hr_id) {
                return false;
            }
        }
        this.isRecurringEnabledCached = true;
        return true;
    }
}
