<div class="scheduler-planner-div-container"
    [ngClass]="{
        'scheduler-planner-main': isMainDay,
        'scheduler-planner-div-micro': zoomState === 's' && previousDay,
        'scheduler-planner-div_formonth': zoomState === 'm',
        'cursor-pointer': zoomState === 'm' && filteredItems?.length,
    }">
    @if (zoomState === 'm') {
    <!-- <div class="scheduler-planner-item-border" [ngClass]="{'scheduler-planner-item-border-placeholder': !schedule?.items?.length}"
        (click)="openDate(schedule.date)">
    </div> -->
    <div class="scheduler-planner-item-border"
        (click)="openDate(schedule.date)" (keydown.enter)="openDate(schedule.date); $event.preventDefault()" role="button" [tabindex]="0">
    </div>
    }

    <div class="scheduler-planner-div-container-top cursor-pointer"
        [ngClass]="{
            'scheduler-planner-div-container-top-today': schedule.date === today,
            'scheduler-planner-div-container-top-today-m': schedule.date === today && zoomState === 'm',
        }">
    </div>

    @if (zoomState === 's' && previousDay) {
    <!-- micro small column -->
    @if (filteredItems.length) {
    <div class="scheduler-planner-div-micro">
        <div class="scheduler-list" style="width: unset;">
            <div class="scheduler-list-header scheduler-list-header-micro">
                <span class="scheduler-list-header-weekday">{{schedule.weekdayShort}}</span><br />
                <span class="scheduler-list-header-subtitle">{{schedule.summaryMin}}</span>
            </div>
            @for (item of filteredItems; track item?._id) {
            <!-- [ngStyle]="{'min-height': item.roasts?.length ? '46px' : '41px'}" -->
            <div class="scheduler-list-item scheduler-list-item-micro"
                [ngClass]="{'scheduler-list-synced-item': item.synced || item.roasts?.length || (today > schedule.date)}"
                [matTooltip]="item.tooltip" matTooltipShowDelay="750" matTooltipPosition="after">
                <div class="scheduler-list-item-amount-micro">
                    <span class="scheduler-list-item-organic-micro">
                        <app-organicicon [isOrganic]="item.blend?.organic || item.coffee?.organic" [size]="8" [rightMargin]="5" verticalAlign="bottom"></app-organicicon>
                        {{item.count}}x
                    </span>
                    <!-- <br /> -->
                    {{parseFloat((item.amount * utils.getUnitFactor(mainUnit)).toPrecision(3)).toString().slice(0, 4)}}{{mainUnit}}
                </div>
                @if ((item.synced || today > schedule.date || item.roasts?.length) && (item.count > (item.roasts?.length ?? 0))) {
                <div class="scheduler-list-item-progress" [ngClass]="{'scheduler-list-item-progress-full': !item.roasts?.length}" [ngStyle]="{'width': (Math.max(100 - (item.roasts?.length || 0) * 100 / item.count, 0)) + '%'}">
                </div>
                }
            </div>
            <!-- just a placeholder to adjust the height in this specific case -->
            <!-- @if (filteredItems.length === 2) {
            <div style="height: 10px;"></div>
            } -->
            }
        </div>
    </div>
    } @else { <!-- i.e. !filteredItems.length -->
    <app-scheduler-planner-placeholder
        [schedule]="schedule"
        [isMainDay]="isMainDay"
        [previousDay]="true"
        [zoomState]="zoomState"
        [index]="index"
        [startMonthIndex]="startMonthIndex">
    </app-scheduler-planner-placeholder>
    }
    } <!-- zoomState === 's' && previousDay -->

    @if (zoomState === 'm' || !previousDay) {
    @if (filteredItems?.length) {
    <div class="scheduler-planner-div" [ngClass]="{'scheduler-planner-unset-width': zoomState === 'm'}">
        <div class="scheduler-list"
            cdkDropList [cdkDropListDisabled]="readOnly"
            [cdkDropListData]="{items: filteredItems, date: schedule.date, allItems: schedule.items}"
            [cdkDropListSortPredicate]="preventDragBeforeSynced"
            (cdkDropListDropped)="drop($event)"
            (cdkDropListEntered)="dragEntered($event)"
            (cdkDropListExited)="dragExited()"
            [ngClass]="{'scheduler-planner-unset-width': zoomState === 'm'}">
            <div class="scheduler-list-header"
                [ngClass]="{'cursor-pointer': zoomState === 'm'}"
                (click)="zoomState === 'm' && openDate(schedule.date)" (keydown.enter)="zoomState === 'm' && openDate(schedule.date); $event.preventDefault()" role="button" [tabindex]="0">
                @if (zoomState === 's') {
                <span class="scheduler-list-header-date">{{schedule.date | date:'shortDate'}}</span>
                } @else {
                @if (index >= startMonthIndex && startMonthIndex + 7 > index) {
                <span class="scheduler-list-header-weekday-small">{{schedule.weekdayShort}} </span>
                }
                <span class="scheduler-list-header-date-small" [ngClass]="{'scheduler-list-header-date-smaller': index < startMonthIndex || index > 20 && schedule.date.substring(8, 9) === '0'}">{{schedule.date.substring(8, 10)}}</span><br />
                <span class="scheduler-list-header-subtitle">{{schedule.summaryMin}}</span>
                }
                @if (zoomState === 's') {
                <span class="scheduler-list-header-weekday">{{schedule.weekday}}</span><br />
                <span class="scheduler-list-header-summary" [matTooltip]="schedule.timeStr || ''">{{schedule.summary}}</span>
                }
                @if (zoomState === 's' && !readOnly) {
                @if (hasDeletableItems()) {
                <span class="scheduler-list-header-delete">
                    <mat-icon class="smallicon" (click)="deleteSchedule()">delete</mat-icon>
                </span>
                }
                <span class="scheduler-list-header-clone">
                    <input matInput [matDatepicker]="picker" [min]="DateTime.fromISO(schedule.date).plus({days: 1})" class="hidden-datepicker-input"
                        [value]="DateTime.fromISO(schedule.date)"
                        (dateChange)="cloneSchedule($event)">
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-icon class="smallicon" (click)="picker.open()">content_copy</mat-icon>
                </span>
                @if (isRecurringEnabledCached || isRecurringEnabled()) {
                <span class="scheduler-list-header-repeat">
                    <mat-icon class="smallicon" (click)="openRecurringDialog()">event_repeat</mat-icon>
                </span>
                }
                }
            </div>

            <!-- @if (showClone && dragMode === DRAGMODES.CLONE && filteredItems[dragIndex]; as item) {
            <div class="scheduler-list-item scheduler-list-drop-placeholder"
                [ngClass]="{'scheduler-list-synced-item': (schedule.date === today && item.synced) || (today > schedule.date)}">
                <div class="scheduler-list-item-border">
                </div>
                <div class="scheduler-list-item-inner">
                    <div class="scheduler-list-item-left"
                        [ngClass]="{'scheduler-list-item-roasted': item.roasts?.length}"
                        style="margin-right: 5px">
                        <div class="scheduler-list-item-count">
                            {{item.count}}x
                        </div>
                        <div class="scheduler-list-item-title-container">
                            <app-organicicon [isOrganic]="item.blend?.organic || item.coffee?.organic" [size]="12"></app-organicicon>
                            <div class="scheduler-list-item-title">{{item.title}}</div>
                        </div>
                    </div>
                    <div class="scheduler-list-item-amount">
                        {{item.itemAmount}}
                    </div>
                </div>
            </div>
            } -->

            <div (click)="showAllItems(schedule.date); $event.stopPropagation()" (keydown.enter)="showAllItems(schedule.date); $event.stopPropagation(); $event.preventDefault()" role="button" [tabindex]="0"
                [ngClass]="{
                    'scheduler-list-items-container': zoomState === 'm',
                    'scheduler-list-items-container-open': 3 >= filteredItems.length || openItemDate === schedule.date,
                }">
                @for (item of filteredItems; track item?._id) {
                @if (zoomState === 's') {
                <div class="scheduler-list-item cursor-move"
                    [ngClass]="{
                        'selected': item.selected,
                        'scheduler-list-synced-item': item.synced || item.roasts?.length || (today > schedule.date)}"
                    [matTooltip]="item.selected ? (tr.anslate('Added {{date}}', { date: (item.created_at ?? DateTime.now().toJSDate()) | date:'mediumDate' })) : item.tooltip"
                    [matTooltipShowDelay]="item.selected ? 1500 : 750" matTooltipPosition="after"
                    (click)="selectItem($index)" (keydown.enter)="selectItem($index); $event.preventDefault()" role="button" [tabindex]="0"
                    cdkDrag [cdkDragData]="{item, idx: $index, date: schedule.date}"
                    (cdkDragStarted)="dragStarted($event)"
                    (cdkDragMoved)="dragMoved($event)"
                    (cdkDragReleased)="dragReleased()"
                    cdkDragPreviewContainer="parent">
                    <div class="scheduler-list-custom-placeholder" *cdkDragPlaceholder></div>
                    <div class="scheduler-list-item-border">
                    </div>
                    @if (isUpdating) {
                    <div class="schedule-list-item-spinner">
                        <mat-spinner diameter="15"></mat-spinner>
                    </div>
                    }
                    <div class="scheduler-list-item-inner">
                        <div class="scheduler-list-item-left"
                            [ngClass]="{'scheduler-list-item-roasted': (item.synced || item.roasts?.length) && item.count > (item.roasts?.length ?? 0)}"
                            [ngStyle]="{'margin-right': animateItem[$index] && item.marginRightForDelete ? item.marginRightForDelete : '5px'}">
                            <div class="scheduler-list-item-count">
                                {{item.count}}x
                            </div>
                            <div class="scheduler-list-item-title-container">
                                <app-organicicon [isOrganic]="item.blend?.organic || item.coffee?.organic" [size]="12"></app-organicicon>
                                <div class="scheduler-list-item-title">{{item.title}}</div>
                            </div>
                        </div>
                        <div class="scheduler-list-item-amount">
                            <!-- {{item.amount * utils.getUnitFactor(mainUnit) | number:'1.0-3'}}{{mainUnit}} -->
                            {{item.itemAmount}}
                        </div>
                        @if (!readOnly && schedule.date >= today && !item.synced && !item.roasts?.length) {
                        <div class="scheduler-list-item-delete-overlay"
                            [ngStyle]="{'width': animateItem[$index] ? deleteWidth : '0'}"
                            (click)="deleteItem($index)" (keydown.enter)="deleteItem($index); $event.preventDefault()" role="button" [tabindex]="0">
                        </div>
                        <div [@showDelete]="{value: animateItem[$index] ? 'end' : 'start', params: {deleteWidth: deleteWidth}}" class="scheduler-list-item-delete">
                            <mat-icon class="scheduler-list-item-delete-icon">delete</mat-icon>
                        </div>
                        }
                    </div>

                    @if (item.selected) {
                    @if (item.blendInfo) {
                    <div class="scheduler-list-item-blendinfo">{{item.blendInfo}}</div>
                    }
                    @if (item.info1) {
                    <div class="scheduler-list-item-info">{{item.info1}}</div>
                    }
                    @if (item.info2) {
                    <div class="scheduler-list-item-info">{{item.info2}}</div>
                    }
                    @if (item.note) {
                    <div class="scheduler-list-item-info"><i>{{item.note}}</i></div>
                    }
                    @if (isRecurringEnabledCached || isRecurringEnabled()) {
                    <mat-icon class="scheduler-list-item-recurring-icon smallicon" (click)="openRecurringDialog(item); $event.stopPropagation()">event_repeat</mat-icon>
                    }
                    @if (item.roasts?.length) {
                    <mat-icon svgIcon="roast" class="scheduler-list-item-roasts-icon smallicon" (click)="openRoastsDialog(item); $event.stopPropagation()" matTooltip="List of roasts" i18n-matTooltip="roasts$$link or heading to a list of roasts@@List of roasts"></mat-icon>
                    }
                    }
                    @if (item.synced || today > schedule.date || item.roasts?.length) {
                    @if (item.count > (item.roasts?.length ?? 0)) {
                    <div class="scheduler-list-item-progress"
                        [ngClass]="{'scheduler-list-item-progress-full': !item.roasts?.length}"
                        [ngStyle]="{'width': (Math.max(100 - (item.roasts?.length || 0) * 100 / item.count, 0)) + '%'}">
                    </div>
                    }
                    @let moreBatches = (item.roasts?.length || 0) > item.count;
                    @let moreWeight = item.roasted > item.count * item.amount * 1.01;
                    @if (moreBatches || moreWeight) {
                    <div class="scheduler-list-item-progress scheduler-list-item-progress-placeholder"
                        [ngClass]="{'scheduler-list-item-more-without-progress': !(item.count > (item.roasts?.length ?? 0))}">
                        @if (moreBatches) {
                        <span class="scheduler-list-item-more-batches">
                            +++
                        </span>
                        }
                        @if (moreWeight) {
                        <span class="scheduler-list-item-more-weight">
                            +++
                        </span>
                        }
                    </div>
                    }
                    }
                </div>
                } @else { <!-- @if (zoomState === 's') { -->
                <div class="scheduler-list-item-countamount" style="cursor: default">
                    <div class="scheduler-list-item-countamount-wrapper"
                        [ngClass]="{'cursor-rowresize': filteredItems.length > 3}">
                        <span class="scheduler-list-item-countamount-beans">{{item.count}}x <span>{{item.coffee?.label || item.blend?.label}}</span></span>
                        <span>{{parseFloat(item.amount.toPrecision(2))}}{{mainUnit}}</span>
                    </div>
                </div>
                <!-- <mat-icon>circle</mat-icon> -->
                }
                }
                <div class="scheduler-list-item-smallsummary">
                    <span>{{schedule.summaryMin}}</span>
                </div>
            </div>

            @if (zoomState === 's' && showClone && dragMode === DRAGMODES.CLONE && filteredItems[dragIndex]; as item) {
            <div class="scheduler-list-item"
                [ngClass]="{'scheduler-list-synced-item': item.synced || item.roasts?.length || (today > schedule.date)}">
                <div class="scheduler-list-item-border">
                </div>
                <div class="scheduler-list-item-inner">
                    <div class="scheduler-list-item-left"
                        [ngClass]="{'scheduler-list-item-roasted': (item.synced || item.roasts?.length) && item.count > (item.roasts?.length ?? 0)}"
                        style="margin-right: 5px">
                        <div class="scheduler-list-item-count">
                            {{item.count}}x
                        </div>
                        <div class="scheduler-list-item-title-container">
                            <app-organicicon [isOrganic]="item.blend?.organic || item.coffee?.organic" [size]="12"></app-organicicon>
                            <div class="scheduler-list-item-title">{{item.title}}</div>
                        </div>
                    </div>
                    <div class="scheduler-list-item-amount">
                        {{item.itemAmount}}
                    </div>
                </div>
            </div>
            }
            <!-- TODO allow clone within same day -->
            <!-- @if (filteredItems.length === 1 && dragMode === DRAGMODES.CLONE) {
            <div class="scheduler-list-item scheduler-list-drop-placeholder"
                cdkDrag cdkDragPreviewContainer="parent">
            </div>
            } -->
        </div>
        @if (zoomState === 's') {
        <div class="scheduler-list-buttons">
            @if (!readOnly && schedule.date >= today) {
            <button [disabled]="isAddingNow" type="button" mat-raised-button
                class="add-button action-button" style="margin-bottom: 15px;"
                (click)="addCurrentItem.emit(schedule.date)">
                @if (!isAdding) {
                <mat-icon>add</mat-icon>
                } @else {
                <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
                }
            </button>
            }
        </div>
        }
    </div>
    } @else { <!-- i.e. !filteredItems?.length -->
    <app-scheduler-planner-placeholder
        [schedule]="schedule"
        [isMainDay]="isMainDay"
        [previousDay]="false"
        [zoomState]="zoomState"
        [index]="index"
        [startMonthIndex]="startMonthIndex"
        [readOnly]="readOnly"
        (initiateReload)="reloadSchedule()"
        (movedAcrossDays)="emitMovedAcrossDays($event)"
        (addCurrentItem)="addCurrentItem.emit($event)"
        (itemAdded)="itemAdded.emit($event)"
        (newScheduleItems)="schedule.items = $event"
        (newFilteredItems)="filteredItems = $event">
    </app-scheduler-planner-placeholder>
    }
    }
</div>