<ng-template #coffeeEntry let-coffee="coffee">
    <div class="smallerfont">
        @if (coffee?.hr_id) {
        <span>{{coffee.hr_id}}</span>
        }
        <app-organicicon
            [isOrganic]="utils.isOrganicCoffee(coffee)"
            [size]="7"
            [leftMargin]="3">
        </app-organicicon>
        @if (coffee?.origin) {
        <span> {{tr.anslate(coffee.origin)}}</span>
        }
        @if (coffee?.yearLabel) {
        <span> {{coffee.yearLabel}}</span>
        }
    </div>
    @if (coffee?.label) {
    <div> {{coffee.label}}</div>
    }
</ng-template>

<ng-template #labelEntry let-obj="obj">
    @if (obj?.hr_id) {
    <div class="smallerfont">{{obj.hr_id}}</div>
    }
    @if (obj?.label) {
    <div>{{obj.label}}</div>
    }
</ng-template>


<ng-template #smallSpinner>
    <mat-spinner diameter="15" strokeWidth="1" class="small-spinner"></mat-spinner>
</ng-template>




@if (!data.transaction) {
<h1 mat-dialog-title i18n="transaction$$add sale header@@add sale">Add Sale</h1>
} @else {
<h1 mat-dialog-title i18n="transaction$$edit sale header@@edit sale">Edit Sale</h1>
}
<mat-dialog-content>

    <form class="narrow-form" id="form" name="form" #f="ngForm">
        @if (!secondPage) {
        <div>
            <div class="right-align">
                <div class="flex-wrap">
                    @if (coffees?.length > 0) {
                    <mat-form-field>
                        <mat-label i18n="generic$$Beans@@Beans">Beans</mat-label>
                        <mat-select required name="coffee"
                            panelClass="twoLineOption" panelWidth="null" hideSingleSelectionIndicator="true"
                            placeholder="Beans" i18n-placeholder="generic$$Beans@@Beans"
                            [(ngModel)]="coffee" [compareWith]="utils.compareObjectsFn"
                            (selectionChange)="coffeeChanged()">
                            <mat-select-trigger>
                                <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: coffee}"></ng-container>
                            </mat-select-trigger>
                            @if (coffees?.length >= 5) {
                            <mat-option>
                                <ngx-mat-select-search ngModel (ngModelChange)="filteredCoffees = utils.filterObjects($event, coffees)" name="coffeeSelect"
                                    placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                                    noEntriesFoundLabel="">
                                </ngx-mat-select-search>
                            </mat-option>
                            }
                            @if (filteredCoffees) {
                            @for (coff of filteredCoffees; track coff?._id) {
                            <mat-option [value]="coff">
                                <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: coff}"></ng-container>
                            </mat-option>
                            }
                            }
                        </mat-select>
                    </mat-form-field>
                    }<br />
                    @if (!filteredCoffees) {
                    <ng-container *ngTemplateOutlet="smallSpinner"></ng-container>
                    }
                </div>
            </div>
            <div class="right-align">
                <div class="flex-wrap">
                    @if (!doneLoadingStocks || (stores?.length > 0)) {
                    <mat-form-field>
                        <mat-label i18n="generic$$storage location@@Store">Store</mat-label>
                        <mat-select required name="store" hideSingleSelectionIndicator="true"
                            panelClass="twoLineOption" panelWidth="null"
                            placeholder="From" i18n-placeholder="transaction$$source store placeholder@@from source store"
                            [(ngModel)]="store" [compareWith]="utils.compareObjectsFn"
                            (selectionChange)="storeChanged()">
                            @if (store) {
                            <mat-select-trigger class="twoLineOption ellipsed">
                                <ng-container *ngTemplateOutlet="labelEntry; context: {obj: store}"></ng-container>
                            </mat-select-trigger>
                            }
                            @if (store && (!filteredStores || filteredStores.length === 0)) {
                            <mat-option [value]="store">
                                <ng-container *ngTemplateOutlet="labelEntry; context: {obj: store}"></ng-container>
                            </mat-option>
                            }
                            @for (thestore of filteredStores; track thestore?._id) {
                            <mat-option [value]="thestore">
                                <ng-container *ngTemplateOutlet="labelEntry; context: {obj: thestore}"></ng-container>
                            </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                    } @else {
                    <div i18n="transaction$$no stores with beans message@@no stores with beans">You do not have any stores with beans.</div>
                    }
                    @if (!stores || !doneLoadingStocks) {
                    <ng-container *ngTemplateOutlet="smallSpinner"></ng-container>
                    }
                </div>
            </div>
            @if (coffee) {
            <div>
                @if (coffee.default_unit?.name) {
                <mat-form-field class="amount-input-ff1">
                    <mat-label i18n="generic$$Amount (of beans, e.g. in kg or bags)@@Amount">Amount</mat-label>
                    <input matInput required class="amount-input" type="text" name="amount"
                        (change)="checkChangesUnits(this, 'amount', amount, $event.target['value'], true)"
                        [value]="amount | number:'1.0-3'">
                    @if (amount === 1) {
                    <span>{{tr.anslate(coffee.default_unit.name)}}</span>
                    } @else {
                    {{tr.anslate('plural_' + coffee.default_unit.name)}}
                    }
                </mat-form-field>
                }
                <mat-form-field class="amount-input-ff2">
                    <mat-label i18n="transaction$$weight of beans in, e.g. kg@@Weight in {{mainUnit}}">Weight in {{mainUnit}}</mat-label>
                    <input matInput [required]="!coffee.default_unit?.name"
                        name="unitamount" class="amount-input" type="text"
                        (change)="checkChangesUnits(this, 'unitamount', unitamount, $event.target['value'], false)"
                        [value]="unitamount * utils.getUnitFactor(mainUnit) | number:'1.0-3'">{{mainUnit}}
                </mat-form-field><br />
                @if (getAmountStr(); as available) {
                <div class="comment">
                    <span [ngClass]="{'warn-amount': round(this.unitamount * utils.getUnitFactor(mainUnit) * 1000) > round(available.value * 1000)}" i18n="transaction$$label before showing how much of those beans are available in this store@@Amount of beans available">Available:</span>
                    @if (doneLoadingStocks) {
                    <span class="spaceleft" [ngClass]="{'warn-amount': round(this.unitamount * utils.getUnitFactor(mainUnit) * 1000) > round(available.value * 1000)}">{{available.pre}}{{available.value | number:'1.0-2'}}{{available.post}}</span>
                    } @else {
                    <mat-spinner diameter="15" strokeWidth="1" class="small-spinner spaceleft"></mat-spinner>
                    }
                </div>
                }
                <div class="right-align">
                    <div class="flex-wrap">
                        @if (!addNewCustomerMode && !editNewCustomerMode) {
                        <mat-form-field class="form-group left-large">
                            <mat-label i18n="contacts$$customer@@Customer">Customer</mat-label>
                            <mat-select required name="customer"
                                panelWidth="null" hideSingleSelectionIndicator="true"
                                placeholder="Customer" i18n-placeholder="contacts$$customer@@Customer"
                                [(ngModel)]="customer" [compareWith]="utils.compareObjectsFn"
                                (selectionChange)="customerChanged()">
                                @if (!customers) {
                                @if (customer && customer.toString() !== '######') {
                                <mat-option [value]="customer">
                                    @if (customer.label) {
                                    <span>{{customer.label}}</span>
                                    }
                                </mat-option>
                                }
                                @if (!customer) {
                                <mat-option></mat-option>
                                }
                                <mat-option value="######" class="highlight-add-option">
                                    @if (editingCustomer?.label && customer?.toString() === '######') {
                                    <span>{{editingCustomer.label}}</span>
                                    } @else {
                                    <ng-container i18n="contacts$$add new customer option@@add a new customer">Add new Customer</ng-container>
                                    }
                                </mat-option>
                                } @else {
                                <mat-option value="######" class="highlight-add-option">
                                    @if (editingCustomer?.label && customer?.toString() === '######') {
                                    <span>{{editingCustomer.label}}</span>
                                    } @else {
                                    <ng-container i18n="contacts$$add new customer option@@add a new customer">Add new Customer</ng-container>
                                    }
                                </mat-option>
                                @for (cstmr of customers; track cstmr?._id) {
                                <mat-option [value]="cstmr">
                                    @if (cstmr.label) {
                                    <span>{{cstmr.label}}</span>
                                    }
                                </mat-option>
                                }
                                }
                            </mat-select>
                        </mat-form-field>
                        }
                        @if (!customers && (!customer || (!addNewCustomerMode && !editNewCustomerMode))) {
                        <ng-container *ngTemplateOutlet="smallSpinner"></ng-container>
                        }
                    </div>
                    @if (!addNewCustomerMode && !editNewCustomerMode && customer?.toString() === '######') {
                    <button matButton type="button" class="smallButton" i18n="transaction$$button to rename entity@@rename" (click)="editCustomer()">Rename</button>
                    }
                </div>
                @if (addNewCustomerMode || editNewCustomerMode) {
                <div class="no-space-below text-align-right">
                    <mat-form-field class="form-group no-bottom-margin">
                        <mat-label i18n="contacts$$add new customer option@@add a new customer">Add new Customer</mat-label>
                        <input matInput required type="text" name="clabel"
                            placeholder="Add new Customer" i18n-placeholder="contacts$$add new customer option@@add a new customer" [(ngModel)]="editingCustomer.label">
                    </mat-form-field>
                    <button matButton class="smallButton positionright" type="button" i18n="transaction$$pick customer from list@@pick customer from list" (click)="closeCustomer()">Choose customer</button>
                </div>
                }
                <app-ap-datetime [value]="date" [dateRequired]="true" (valueChange)="date=$event"></app-ap-datetime>
            </div>
            } @else {
            @if (coffees?.length === 0) {
            <div>
                <span i18n="transaction$$no beans to transfer / sell etc.@@no beans">Please add beans first!</span>
            </div>
            }
            @if (coffees?.length) {
            <div i18n="transaction$$need to select some beans before continuing@@select coffee">Please select beans first!</div>
            }
            }
        </div>
        } @else {
        <!-- optional details -->
        <mat-form-field>
            <mat-label i18n="transaction$add a sales number@@sales_number">Sales Number</mat-label>
            <input matInput [disabled]="waiting" type="string" name="sales_number"
                placeholder="Sales Number" i18n-placeholder="transaction$add a sales number@@sales_number"
                [(ngModel)]="sales_number">
        </mat-form-field><br />
        <mat-form-field class="amount-input-ff1">
            <mat-label i18n="transaction$$total price@@total price">Total price</mat-label>
            <input matInput required [disabled]="waiting" class="amount-input" type="text"
                name="price" inputmode="numeric" pattern="[\-0-9,.]*" value="0"
                (change)="utils.checkChanges(this, 'price', price, $event.target['value'], doAdd)"
                [value]="price | currency:currency:'symbol-narrow':'1.0-2'">
            /{{unitamount * utils.getUnitFactor(mainUnit) | number:'1.0-3'}}{{mainUnit}}
        </mat-form-field>
        <mat-form-field class="amount-input-ff2">
            <mat-label i18n="transaction$$Price per unit, e.g. kg@@Price per">Price per</mat-label>
            <input matInput required [disabled]="waiting" class="amount-input" type="text"
                name="pricePerUnit" inputmode="numeric" pattern="[\-0-9,.]*" value="0"
                (change)="utils.checkChanges(this, 'pricePerUnit', pricePerUnit, $event.target['value'], doAdd)"
                [value]="pricePerUnit | currency:currency:'symbol-narrow':'1.2-2'">
            /{{mainUnitSingular}}<br />
        </mat-form-field>
        <mat-form-field>
            <mat-label i18n="transaction$add a tracking number@@tracking_number">Tracking Number</mat-label>
            <input matInput type="string" [disabled]="waiting" name="tracking_number"
                placeholder="Tracking Number" i18n-placeholder="transaction$add a tracking number@@tracking_number"
                [(ngModel)]="tracking_number">
        </mat-form-field><br />
        <mat-form-field>
            <mat-label i18n="transaction$add a product id@@product_id">Product ID</mat-label>
            <input matInput type="string" [disabled]="waiting" name="product_id"
                placeholder="Product ID" i18n-placeholder="transaction$add a product id@@product_id"
                [(ngModel)]="product_id">
        </mat-form-field><br />
        <mat-form-field>
            <mat-label i18n="transaction$placeholder, an arbitrary reference string@@reference">Reference</mat-label>
            <input matInput type="string" [disabled]="waiting" name="reference"
                placeholder="Reference" i18n-placeholder="transaction$placeholder, an arbitrary reference string@@reference"
                [(ngModel)]="reference">
        </mat-form-field><br />
        }
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    @if (secondPage) {
    <button (click)="prev()" [disabled]="waiting" data-test="bttn_prev" mat-raised-button class="action-button">
        <mat-icon>navigate_before</mat-icon>
    </button>
    }
    <button mat-button (click)="handleExit(undefined, undefined, true)" data-test="bttn_close" type="button" class="cancel-button">
        <mat-icon>close</mat-icon>
    </button>
    @if (!secondPage) {
    <button (click)="next()" [disabled]="!f.form.valid || waiting || !unitamount" data-test="bttn_next" mat-raised-button class="action-button">
        <mat-icon>navigate_next</mat-icon>
    </button>
    }
    @if (secondPage) {
    <button [disabled]="waiting || !f.form.valid" ng-submit data-test="bttn_done"
        (click)="add()" mat-raised-button form="form" type="submit" class="action-button spinner-button">
        @if (showWaiting) {
        <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
        } @else {
        <mat-icon>done</mat-icon>
        }
    </button>
    }
</mat-dialog-actions>