import { ActivatedRoute, Router } from '@angular/router';
import { Purchase } from 'src/app/models/Purchase';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy, Inject, LOCALE_ID, Output, EventEmitter } from '@angular/core';
import { Coffee } from 'src/app/models/Coffee';
import { Location } from 'src/app/models/Location';
import { StandardService } from 'src/app/util/services/standard.service';
import { PropertiesType, UnitSystemType, Utils } from 'src/app/util/utils';
import { UserService, UserType } from 'src/app/modules/frame/services/user.service';
import { Enumerations } from 'src/app/models/Enumerations';
import { TranslatorService } from 'src/app/util/services/translator.service';
import { AlertService } from 'src/app/util/alert/alert.service';
import { ObjectChangedService } from 'src/app/util/services/objectchanged.service';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { BreakpointObserver } from '@angular/cdk/layout';
import { YesNoDialogComponent } from 'src/app/modules/ui/dialog/yesno-dialog.component';
import { Variety } from 'src/app/models/Variety';
import { Certification } from 'src/app/models/Certification';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject } from 'rxjs';
import { ENTER } from '@angular/cdk/keycodes';
import { Producer } from 'src/app/models/Producer';
import { PropertiesService } from 'src/app/util/services/properties.service';
import { Property } from 'src/app/models/Property';
import { debounceTime, distinctUntilChanged, map, throttleTime, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CantDeleteDialogComponent } from 'src/app/modules/ui/dialog/cant-delete-dialog.component';
import { Tag } from 'src/app/models/Tag';
import { Supplier } from 'src/app/models/Supplier';
import { SupplierPartner } from 'src/app/models/SupplierPartner';
import { StockType } from '../stock/store-stock.component';
import { Constants } from 'src/app/util/constants';
import cloneDeep from 'lodash-es/cloneDeep';
import { Utils2 } from 'src/app/util/utils2';
import { Buffer } from 'buffer/';
import { ClipboardService } from 'ngx-clipboard';
import { ServerLogService } from 'src/app/util/services/server-log.service';
import { APBeans } from 'src/app/models/protobuf/generated/beans_pb';
import { DateTime } from 'luxon';

export interface InternalOriginType {
    origin: string;
    label: string;
    country: string;
    region: string;
}

@Component({
    selector: 'app-coffee',
    templateUrl: './coffee.component.html',
    styleUrls: ['./coffee.component.scss'],
    providers: []
})
export class CoffeeComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(
        // used by "that"
        public propertiesService: PropertiesService,
        private standardService: StandardService,
        private breakpointObserver: BreakpointObserver,
        private userService: UserService,
        private objectChangedService: ObjectChangedService,
        public tr: TranslatorService,
        public utils: Utils,
        private utils2: Utils2,
        private router: Router,
        private logger: NGXLogger,
        private alertService: AlertService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private clipboardService: ClipboardService,
        private serverLogService: ServerLogService,
        @Inject(LOCALE_ID) public locale: string,
    ) {
        this.allCountries = this.utils.getCountries(true);
    }

    hiddenProperties = ['updated_at', 'updated_by', '__v', '__t', 'tags', 'refs'];
    fixedProperties = ['_id', 'hr_id', 'created_at', 'created_by', 'deleted', 'internal_hr_id', 'owner'];

    isOrganic = false;
    isDarkmode = false;

    curStock: { pre: string, value: number, post: string };
    curStocks: StockType[];

    private _coffee: Coffee;
    get coffee(): Coffee { return this._coffee; }
    @Input() set coffee(c: Coffee) {
        if (!c?.default_unit?.name) {
            c.default_unit = { name: Enumerations.CoffeeUnits._NONE, size: 1 }
        }
        this._coffee = c;
        this.recalcBeansYearLabel();
        if (this.currentUser) {
            this.recalcStock();
        }
        this.isOrganic = this.utils.isOrganicCoffee(c);
        if (this.properties?.length) {
            // update origin / ICO origin
            this.checkOrigin();
        }
        // this.loadRegionsForOrigin((this._coffee?.origin as unknown as InternalOriginType)?.origin);
        if (this.editMode === this.index) {
            this.edit();
        }
    }

    private props: PropertiesType;
    get properties(): PropertiesType { return this.props; }
    @Input() set properties(val: PropertiesType) {
        this.props = val;
        if (this.props) {
            // convert origin to InternalOriginType if necessary
            if (typeof this.coffee.origin === 'string') {
                this.coffee.origin = this.findOriginProperty(this.coffee.origin) as unknown as string;
                if (this.coffeecopy) {
                    this.coffeecopy.origin = this.findOriginProperty(this.coffeecopy.origin) as unknown as string;
                }
            }
            // this.loadRegionsForOrigin((this.coffee.origin as unknown as InternalOriginType)?.origin || (this.coffeecopy?.origin as unknown as InternalOriginType)?.origin);
            this.checkOrigin();

            this.icoOptions = this.getICOOptions();
        }
    }

    @Input() index: number;
    @Input() idToHighlight: string;

    currentUser: UserType;
    @Input() readOnly = false;
    translatedUnitName: string;
    unitSize: number;
    @Input() editMode = -1;
    @Input() isNew = -1;
    isExpanded = false;
    @Input() showHidden = false;

    @Input() allCertifications: Certification[];
    @Input() fields: Location[];
    @Output() fieldAdded = new EventEmitter<Location>();
    @Input() stores: Location[];
    @Input() producers: Producer[];
    // producers: Producer[];
    // // eslint-disable-next-line @angular-eslint/no-input-rename
    // @Input('producers') set _producers(prods: Producer[]) {
    //     this.producers = prods;
    //     // same code as in this.edit() but now we have the producers array
    //     if (typeof this._producers !== 'undefined' && this.coffeecopy?.producer) {
    //         // check whether the producer can be found
    //         let found = false;
    //         for (let p = 0; p < (this.producers || []).length; p++) {
    //             if (this.producers[p].label === this.coffeecopy.producer.label) {
    //                 // TODO check if we can do this; has been added for the protobuf import case
    //                 this.coffeecopy.producer = this.producers[p];
    //                 this.coffee.producer = this.producers[p];
    //                 found = true;
    //                 break;
    //             }
    //         }
    //         if (!found) {
    //             // not found, happens if producer was deleted; remove and ignore
    //             this.coffeecopy.producer = null;
    //         }
    //     }
    // }
    @Output() producerAdded = new EventEmitter<Producer>();
    suppliers: Supplier[];
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('suppliers') set _suppliers(supps: Supplier[]) {
        this.suppliers = supps;
        for (let s = 0; s < this.suppliers?.length; s++) {
            const sup = this.suppliers[s];
            if ((sup as SupplierPartner)?.partner) {
                sup['image'] = (sup as SupplierPartner).partner;
            }
        }
        this.setInitialSupplier();
    }
    private _initialSupplier: Partial<SupplierPartner>;
    @Input() set initialSupplier(insup: Partial<SupplierPartner>) {
        this._initialSupplier = insup;
        this.setInitialSupplier();
    }
    get initialSupplier(): Partial<SupplierPartner> {
        return this._initialSupplier;
    }
    @Input() allVarietals: Variety[][];
    @Input() allVarietalCategories: string[] = [];
    @Input() dontShowCloneDelete: boolean;

    loadingRegions = false;
    // additional regions to be used; and saved as properties on doSave
    @Input() customExternalRegions: string[];
    regionsForOrigin: string[];
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('regions') set _regionsForOrigin(regions: string[]) {
        this.regionsForOrigin = regions ?? [];
        if (regions?.length) {
            this.regionsForOrigin = regions.filter(r => r);
            this.regionsAlreadyRetrievedFor = (this.coffeecopy?.origin as unknown as InternalOriginType)?.origin ?? this.coffeecopy?.origin;
            this.filteredRegionsForOrigin = this.regionsForOrigin.slice();
            // remove all regions (added previously to the current beans) that are not in the new origin
            if (this.coffeecopy?.regions) {
                this.coffeecopy.regions = this.coffeecopy.regions.filter(reg => this.regionsForOrigin.indexOf(reg) >= 0);
            }
            const sepIdx = regions.indexOf('');
            if (sepIdx >= 0) {
                // have custom entries; mark those so that they can be removed by the user
                this.customRegions = regions.slice(0, sepIdx);
            }
        } else {
            this.regionsForOrigin = [];
        }
    }

    showstockfrom: string[] | 'all' = 'all';
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('showstockfrom') set _showstockfrom(ssf: { _id: string }[] | 'all') {
        this.showstockfrom = ssf === 'all' ? 'all' : ssf.map(ssf => ssf._id);
        if (this.currentUser) {
            this.recalcStock();
        }
    }
    @Input() existingId: string;
    @Input() special: string;
    @Input() showStockPlaceholder = true;

    // if given a location, the "add new field" mode is activated
    newFieldData: Location;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('newFieldData') set _newFieldData(nfd: Location) {
        this.newFieldData = nfd;
        if (nfd) {
            this.addNewField();
            Object.assign(this.editingField, nfd);
        }
    }

    // if given a producer, the "add new producer" mode is activated
    newProducerData: Producer;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('newProducerData') set _newProducerData(npd: Producer) {
        this.newProducerData = npd;
        if (npd) {
            this.addNewProducer();
            Object.assign(this.editingProducer, npd);
        }
    }

    icoOptions: { label: string, value: string }[] = [];

    coffeecopy: Coffee;
    // helpers to convert array <-> string
    flavors: string;
    varietalInput = '';
    varietals: string[] = [];
    filteredVarietals: Variety[][] = [];

    allCountries: string[];
    allOrigins: InternalOriginType[][] = [];
    filteredOrigins: InternalOriginType[][] = [];
    allOriginRegions: string[] = [];
    loadingOrigins = false;
    originDifferentFromICO = false;

    filteredRegionsForOrigin: string[] = [];
    curRegionsFilter: string;
    // stores regions that a user added and should thus also be able to remove
    customRegions: string[] = [];
    // used to avoid unnecessary call to server
    regionsAlreadyRetrievedFor: string;

    filteredSelections: { label: string, value: string }[] = [];

    hierarchicalProperties: { categories: string[], properties: string[][], options: { label: string, value: string }[][] }[];
    procString: string;

    addNewFieldMode = false;
    addNewProducerMode = false;
    editNewFieldMode = false;
    editNewProducerMode = false;
    editingProducer: Producer;
    editingField: Location;
    addNewSupplierMode = false;
    editNewSupplierMode = false;
    editingSupplier: Supplier;
    supplier: Supplier;
    filteredCountriesProd: string[] = [];
    filteredCountriesSupp: string[] = [];
    filteredCountriesField: string[] = [];

    store: Location;
    amount = 0;
    unitamount = 0;
    mainUnit: UnitSystemType = 'kg';
    mainUnitSingular = 'kg';
    unit_system = Enumerations.UNIT_SYSTEM.METRIC;
    currency = 'EUR';
    date: DateTime;
    price = 0;
    pricePerUnit = 0;

    months: string[];
    notes: string;
    notesChanged: Subject<string> = new Subject<string>();

    pbShareLink: string;
    justCopied = false;
    copyFailed = false;

    submitPressed = false;
    waitingForChanges = false;
    isSaving = false;
    checkingIfDeletable = false;

    // after an image changed, need to wait for the local server to reload; only for localhost setup
    waitForLocalServer = false;

    Enumerations = Enumerations;
    separatorKeysCodes: number[] = [ENTER];

    // isSmall$: Observable<boolean>;
    isLarge$: Observable<boolean>;
    // used to cancel all pending requests if user navigates away
    private ngUnsubscribe = new Subject();

    @ViewChild('appCoffee') coffeeElem: ElementRef;
    @ViewChild(MatExpansionPanel) expPanel: MatExpansionPanel;
    @ViewChild('autoComplete') matAutocomplete: MatAutocomplete;
    @ViewChild('varietalInputElem') varietalInputElem: ElementRef<HTMLInputElement>;
    @ViewChild('scoreInputElem') scoreInputElem: ElementRef<HTMLInputElement>;

    @Output() editCancelled = new EventEmitter();
    @Output() saved = new EventEmitter();
    @Output() uniqueLabelChanged = new EventEmitter<{ prop: string, value: string }>();
    uniqueChecker = new Subject<{ prop: string, value: string }>();

    Math = Math;
    // used for clipboard only
    @ViewChild('maindiv') maindiv: ElementRef;


    ngOnInit(): void {
        this.currentUser = this.userService.getCurrentUser(this.route.snapshot);
        if (!this.currentUser) {
            this.userService.navigateToLogin(this.router.url);
            return;
        }
        this.unit_system = this.currentUser.unit_system;
        if (this.currentUser.unit_system === Enumerations.UNIT_SYSTEM.IMPERIAL) {
            this.mainUnit = 'lbs';
            this.mainUnitSingular = 'lb';
        }
        if (this.currentUser.account) {
            this.currency = this.currentUser.account.currency || 'EUR';
        }

        // this.isSmall$ = this.breakpointObserver.observe('(max-width: 599px)')
        //     .pipe(map(result => result.matches));
        this.isLarge$ = this.breakpointObserver.observe('(min-width: 900px)')
            .pipe(map(result => result.matches));

        this.months = this.utils.getAllMonthsLocalized();

        this.isDarkmode = this.userService.isDarkModeEnabled();
        this.userService.darkmodeMode$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(dm => this.isDarkmode = this.userService.isDarkModeEnabled(dm));

        this.recalcStock();

        if (this.coffee) {
            this.notes = this.coffee.notes;
            this.updateUnitStrings();
        }

        this.uniqueChecker
            .pipe(
                distinctUntilChanged(),
                takeUntil(this.ngUnsubscribe),
                debounceTime(1500),
                throttleTime(environment.RELOADTHROTTLE))
            .subscribe(event => this.uniqueLabelChanged.emit(event));

        if (this.editMode === this.index && typeof this.coffeecopy === 'undefined' && this.coffee) {
            // don't notify parent - command comes from parent anyway (and would reset isNew flag)
            this.edit(false);
        }

        if (this.expPanel && this.idToHighlight &&
            (this.coffee._id === this.idToHighlight || this.coffee.hr_id === this.idToHighlight)) {

            this.expPanel.open();
        }

        this.updatePBShareLink(this.coffee);

        this.notesChanged.pipe(
            debounceTime(4000),
            distinctUntilChanged(),
            throttleTime(environment.RELOADTHROTTLE),
            takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.saveNotes());
    }

    ngAfterViewInit(): void {
        if (this.expPanel && this.idToHighlight && this.coffee &&
            (this.coffee?._id === this.idToHighlight || this.coffee?.hr_id === this.idToHighlight || this.coffee?.internal_hr_id?.toString() === this.idToHighlight?.toString())) {

            setTimeout(() => {
                if (!this.expPanel.expanded) {
                    this.expPanel.open();
                }
                this.coffeeElem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            });
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next('');
        this.ngUnsubscribe.complete();
    }

    loadRegionsForOrigin(origin: string): void {
        // if (!origin) {
        //     this.regionsForOrigin = [];
        // } else {
        if (origin && origin !== this.regionsAlreadyRetrievedFor) {
            const regionsBackup = this.regionsForOrigin?.length ? this.regionsForOrigin.slice() : [];
            this.regionsForOrigin = undefined;
            this.loadingRegions = true;
            this.utils.loadRegionsForOrigin(origin, this.ngUnsubscribe, (regions: string[]) => {
                // avoid loading (and overwriting!) regions when set using the regionsForOrigin setter
                if (origin !== this.regionsAlreadyRetrievedFor) {
                    if (regions) {
                        this.regionsForOrigin = regions.filter(r => r);
                        this.regionsAlreadyRetrievedFor = origin;
                        this.filteredRegionsForOrigin = this.regionsForOrigin.slice();
                        // remove all regions (added previously to the current beans) that are not in the new origin
                        if (this.coffeecopy?.regions) {
                            this.coffeecopy.regions = this.coffeecopy.regions.filter(reg => this.regionsForOrigin.indexOf(reg) >= 0);
                        }
                        const sepIdx = regions.indexOf('');
                        if (sepIdx >= 0) {
                            // have custom entries; mark those so that they can be removed by the user
                            this.customRegions = regions.slice(0, sepIdx);
                        }
                    } else {
                        this.regionsForOrigin = regionsBackup;
                    }
                }
                this.loadingRegions = false;
            });
        }
    }

    updatePBShareLink(coffee: Coffee): void {
        let apBeans: APBeans;
        let bin: Uint8Array;
        try {
            apBeans = this.utils.convertBeansToPBBeans(coffee);

            // // TODO remove, just for testing
            // APBeans.getLocation()?.setLabel('totally new location');
            // APBeans.getProducer()?.setLabel('totally new producer');

            const bin = apBeans.serializeBinary();
            const str = Buffer.from(bin.buffer).toString('base64');
            this.pbShareLink = encodeURIComponent(str);
        } catch (err) {
            this.logger.warn(`updatePBShareLink: could not create protobuf string, coffee=${JSON.stringify(coffee)}, apBeans=${apBeans}, bin=${bin}`, err);
            this.serverLogService.sendError({ message: 'updatePBShareLink: could not create protobuf string', details: `coffee=${coffee}, APBeans=${apBeans}, bin=${bin}` }, 'CoffeeComponent.updatePBShareLink');
        }
    }

    copyLink() {
        if (this.clipboardService.copyFromContent(`https://artisan.plus/add?data=${this.pbShareLink}`, this.maindiv.nativeElement)) {
            this.justCopied = true;
            setTimeout(() => {
                this.justCopied = false;
            }, 2000);
        } else {
            this.copyFailed = true;
            setTimeout(() => {
                this.copyFailed = false;
            }, 2000);
        }
    }

    deleteCustomRegion(region: string): void {
        // remove locally
        this.regionsForOrigin = this.regionsForOrigin.filter(r => r !== region);
        this.filteredRegionsForOrigin = this.filteredRegionsForOrigin.filter(r => r !== region);
        this.customRegions = this.customRegions.filter(r => r !== region);
        if (this.coffeecopy?.regions) {
            this.coffeecopy.regions = this.coffeecopy.regions.filter(r => r !== region);
        }
        // remove on server
        const newProp = new Property();
        newProp.categories = ['coffee'];
        newProp.property = 'Region';
        newProp.label = (this.coffeecopy?.origin as unknown as InternalOriginType)?.origin;
        newProp.value = region;
        newProp.donttranslate = true;
        this.propertiesService.removeProperty(newProp)
            .pipe(throttleTime(environment.RELOADTHROTTLE))
            .subscribe({
                next: response => {
                    if (response?.error) {
                        this.utils.handleError('error adding the region', response.error);
                    }
                    // silent but happy
                },
                error: error => {
                    this.utils.handleError('error adding the region', error);
                }
            });
    }

    setInitialSupplier(): void {
        if (this.initialSupplier && this.suppliers) {
            for (const supp of this.suppliers) {
                if (this.initialSupplier.partner === (supp as SupplierPartner).partner) {
                    this.supplier = supp;
                    break;
                }
            }
        }
    }

    updateUnitStrings(): void {
        if (this.coffee.default_unit?.name) {
            this.translatedUnitName = this.tr.anslate('plural_' + this.coffee.default_unit.name);
            if (this.locale.substring(0, 2).toUpperCase() === 'IT') {
                this.translatedUnitName = this.translatedUnitName.charAt(0).toUpperCase() + this.translatedUnitName.slice(1);
            }
        }
        if (this.coffee.default_unit?.size) {
            this.unitSize = this.coffee.default_unit.size * this.utils.getUnitFactor(this.mainUnit);
        }
    }

    recalcStock(): void {
        let amount = 0;
        const res: StockType[] = [];
        const stocks = this.coffee.stock;
        if ((this.showstockfrom === 'all' || this.showstockfrom?.length > 0) && stocks?.length) {
            for (const stock of stocks) {
                if (this.showstockfrom === 'all'
                    || this.showstockfrom.indexOf((stock.location._id || stock.location).toString()) >= 0) {
                    amount += stock.amount || 0;
                    res.push({
                        coffeeLabel: this.coffee.label, coffeeId: this.coffee._id, default_unit: this.coffee.default_unit, coffee_internal_hr_id: this.coffee.internal_hr_id,
                        amount: stock.amount, location: stock.location, location_id: stock.location._id ? stock.location._id : stock.location as unknown as string,
                        low_limit: stock.low_limit, cost: stock.value, fifo_cost: stock.fifo_cost,
                    });
                }
            }
        }
        this.curStock = this.utils.formatAmountForPipe(amount, this.coffee.default_unit, this.currentUser.unit_system);
        this.curStocks = res;
        // store it in the coffee object so that the coffees.component can use it for filtering
        this.coffee.curStock = amount;
    }

    recalcBeansYearLabel(): void {
        if (this.coffee) {
            this.coffee.yearLabel = this.utils.createBeansYearLabel(this.coffee);
        }
    }

    fieldChanged(): void {
        if (this.coffeecopy?.location?.toString() === '######') {
            this.addNewField();
        } else {
            this.editingField = undefined;
        }
    }

    addNewField(): void {
        this.addNewFieldMode = true;
        this.editingField = new Location();
        this.editingField.type = [Enumerations.LocationTypes.FIELD];
        if (this.coffeecopy?.origin && this.coffeecopy.origin['country'] && this.allCountries.indexOf(this.coffeecopy.origin['country']) >= 0) {
            this.editingField.country = this.coffeecopy.origin['country'];
            this.filteredCountriesField = [this.editingField.country];
        } else {
            this.filteredCountriesField = this.allCountries;
        }
    }

    editField(): void {
        this.editNewFieldMode = true;
        const locId = (this.coffeecopy.location._id || this.coffeecopy.location).toString();
        for (const field of this.fields) {
            if ((field._id || field).toString() === locId) {
                this.coffeecopy.location = field;
                this.editingField = field;
                break;
            }
        }
        if (!this.editingField) {
            // not found, might happen if editing a deleted field
            this.editNewFieldMode = false;
            this.addNewField();
        }
    }

    closeField(): void {
        const emptyLoc = new Location();
        emptyLoc.type = [Enumerations.LocationTypes.FIELD];
        if (!this.editingField?.label ||
            (this.addNewFieldMode && JSON.stringify(this.editingField) === JSON.stringify(emptyLoc))) {
            this.editingField = null;
            this.coffeecopy.location = null;
        }
        this.addNewFieldMode = false;
        this.editNewFieldMode = false;
    }

    producerChanged(): void {
        if (this.coffeecopy.producer?.toString() === '######') {
            this.addNewProducer();
        } else {
            this.editingProducer = null;
        }
    }

    addNewProducer(): void {
        this.addNewProducerMode = true;
        if (!this.editingProducer?.label) {
            this.editingProducer = new Producer();
            this.editingProducer.location = new Location();
            this.editingProducer.location.type = [Enumerations.LocationTypes.PRODUCER];
            if (this.coffeecopy?.origin && this.coffeecopy.origin['country'] && this.allCountries.indexOf(this.coffeecopy.origin['country']) >= 0) {
                this.editingProducer.location.country = this.coffeecopy.origin['country'];
                this.filteredCountriesProd = [this.editingProducer.location.country];
            } else {
                this.filteredCountriesProd = this.allCountries;
            }
        }
    }

    editProducer(): void {
        this.editNewProducerMode = true;
        const prodId = (this.coffeecopy.producer._id || this.coffeecopy.producer).toString();
        if (prodId === '######' && this.editingProducer) {
            return;
        }
        for (const prod of this.producers) {
            if (prod._id.toString() === prodId) {
                if (!prod.location) {
                    prod.location = new Location();
                }
                this.coffeecopy.producer = prod;
                this.editingProducer = prod;
                break;
            }
        }
        if (!this.editingProducer) {
            // not found, might happen if editing a deleted producer
            this.editNewProducerMode = false;
            this.addNewProducer();
        }
    }

    closeProducer(): void {
        this.coffeecopy.producer = '######' as unknown as Producer;
        const emptyProducer = new Producer();
        emptyProducer.location = new Location();
        emptyProducer.location.type = [Enumerations.LocationTypes.PRODUCER];
        if (!this.editingProducer?.label ||
            (this.addNewProducerMode && JSON.stringify(this.editingProducer) === JSON.stringify(emptyProducer))) {
            this.editingProducer = null;
            this.coffeecopy.producer = null;
        }

        this.addNewProducerMode = false;
        this.editNewProducerMode = false;
    }

    supplierChanged(): void {
        if (this.supplier?.toString() === '######') {
            this.addNewSupplier();
        } else {
            this.editingSupplier = undefined;
        }
    }

    addNewSupplier(): void {
        this.addNewSupplierMode = true;
        this.editingSupplier = new Supplier();
        this.editingSupplier.location = new Location();
        this.editingSupplier.location.type = [Enumerations.LocationTypes.SUPPLIER];
    }

    editSupplier(): void {
        this.editNewSupplierMode = true;
        const suppId = (this.supplier._id || this.supplier).toString();
        for (const supp of this.suppliers) {
            if (supp._id.toString() === suppId) {
                if (!supp.location) {
                    supp.location = new Location();
                }
                this.supplier = supp;
                this.editingSupplier = supp;
                break;
            }
        }
        if (!this.editingSupplier) {
            // not found, might happen if editing a deleted supplier
            this.editNewSupplierMode = false;
            this.addNewSupplier();
        }
    }

    closeSupplier(): void {
        const emptySupplier = new Supplier();
        emptySupplier.location = new Location();
        emptySupplier.location.type = [Enumerations.LocationTypes.SUPPLIER];
        if (!this.editingSupplier?.label ||
            (this.addNewSupplierMode && JSON.stringify(this.editingSupplier) === JSON.stringify(emptySupplier))) {
            this.editingSupplier = null;
            this.supplier = null;
        }

        this.addNewSupplierMode = false;
        this.editNewSupplierMode = false;
    }

    priceChanged(ppuChanged: boolean): void {
        if (ppuChanged) {
            // pricePerUnit changed
            this.price = this.pricePerUnit * this.utils.convertToUserUnit(this.unitamount, this.unit_system);
        } else {
            // price changed
            this.pricePerUnit = this.unitamount ? this.price / this.utils.convertToUserUnit(this.unitamount, this.unit_system) : 0;
        }
    }

    calcAmount(toUnit: boolean): void {
        if (toUnit) {
            this.unitamount = this.utils.convertAmount(this.amount, toUnit, this.coffeecopy.default_unit);
        } else {
            this.amount = this.utils.convertAmount(this.unitamount, toUnit, this.coffeecopy.default_unit);
        }
        this.priceChanged(true);
    }

    getCertProperties(cert: Certification): string {
        let str = '';
        if (cert.organic) { str += this.tr.anslate(Enumerations.CertificationProperties.ORGANIC) + ', '; }
        if (cert.fair) { str += this.tr.anslate(Enumerations.CertificationProperties.FAIR) + ', '; }
        if (cert.sustainable) { str += this.tr.anslate(Enumerations.CertificationProperties.SUSTAINABLE) + ', '; }
        if (cert.bird_friendly) { str += this.tr.anslate(Enumerations.CertificationProperties.BIRD_FRIENDLY) + ', '; }
        if (cert.shade_grown) { str += this.tr.anslate(Enumerations.CertificationProperties.SHADE_GROWN) + ', '; }
        if (cert.high_quality) { str += this.tr.anslate(Enumerations.CertificationProperties.HIGH_QUALITY) + ', '; }
        if (cert.social) { str += this.tr.anslate(Enumerations.CertificationProperties.SOCIAL) + ', '; }
        str = str.substring(0, str.length - 2);
        return str;
    }

    saveNotes(): void {
        if (this.coffee.notes === this.notes) {
            this.logger.trace('ignore update:', this.notes);
            return;
        }

        this.logger.debug('save notes:', this.notes);
        this.standardService.update<Coffee>('coffees', { _id: this.coffee._id, notes: this.notes, label: undefined })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        this.coffee.notes = this.notes;
                    } else {
                        this.utils.handleError('error updating the beans information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('error updating the beans information', error);
                }
            });
    }

    delete(): void {
        if (this.readOnly) { return; }

        this.checkingIfDeletable = true;
        this.standardService.getRefs('coffees', this.coffee._id)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    this.checkingIfDeletable = false;
                    if (response.success === true) {
                        if (response.result.count > 0) {
                            const dialogRef = this.dialog.open(CantDeleteDialogComponent, {
                                closeOnNavigation: true,
                                data: {
                                    label: this.coffee.hr_id + ' ' + this.coffee.label,
                                    count: response.result.count,
                                    refs: this.utils.dateifyObjects(response.result.refs, ['date']),
                                    mainUnit: this.mainUnit,
                                    currency: this.currency
                                }
                            });

                            dialogRef.afterClosed().subscribe(result => {
                                if (result === true) {
                                    this.doDelete();
                                }
                            });

                        } else {
                            const dialogRef = this.dialog.open(YesNoDialogComponent, {
                                closeOnNavigation: true,
                                data: { text: this.tr.anslate('Do you really want to delete {{name}}?', { name: this.coffee.hr_id + ' ' + this.coffee.label }) }
                            });

                            dialogRef.afterClosed().subscribe(result => {
                                if (result === true) {
                                    this.doDelete();
                                }
                            });
                        }
                    } else {
                        this.utils.handleError('could not delete the data', response.error);
                    }
                },
                error: error => {
                    this.checkingIfDeletable = false;
                    this.utils.handleError('could not delete the data', error);
                }
            });
    }

    private doDelete(): void {
        this.standardService.remove('coffees', this.coffee._id)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        this.alertService.success(this.tr.anslate('Successfully removed'));
                        // notify dashboard of the change
                        this.coffee.deleted = true;
                        this.objectChangedService.objectChanged({ model: 'coffees', info: { object: this.coffee }, reload: false });
                        this.editMode = -1;
                        this.isNew = -1;
                        this.addNewFieldMode = false;
                    } else {
                        this.utils.handleError('Error updating the coffee information', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('Error updating the coffee information', error);
                }
            });
    }

    getOptions(prop: string): { value: string, label: string }[] | undefined {
        if (!this.props) {
            return undefined;
        }
        if (!this.props[prop]) {
            return [];
        }
        return this.props[prop];
    }

    getICOOptions(): { value: string, label: string }[] | undefined {
        const options = this.getOptions('ICO_origin');
        if (!options) {
            return undefined;
        }
        return options.sort((o1, o2) => o1.label < o2.label ? -1 : o1.label > o2.label ? 1 : 0);
    }

    /**
     * Returns the translated label (the part after a '#') and the category (the part before a '#')
     * @param value the value of the property
     * @param translate whether value (and catgeory) should be tranlsated or not
     * @returns .label and .cat
     */
    getLabelAndCat(value: string, translate = false): { label: string, cat?: string } {
        const idx = value.indexOf(Constants.LABEL_SEPARATOR);
        if (idx >= 0) {
            const split = value.split(Constants.LABEL_SEPARATOR);
            return {
                label: translate ? this.tr.anslate(split[1]) : split[1],
                cat: translate ? this.tr.anslate(split[0]) : split[0],
            };
        }
        return { label: translate ? this.tr.anslate(value) : value };
    }

    /**
     * returns the property hierarchy of a given property
     */
    getHierarchicalProperty(propName: string): { categories: string[], options: { label: string, value: string }[][] } {
        if (this.hierarchicalProperties && this.hierarchicalProperties[propName] &&
            this.hierarchicalProperties[propName].options && this.hierarchicalProperties[propName].options.length > 0) {
            return this.hierarchicalProperties[propName];
        }

        if (!this.hierarchicalProperties) {
            this.hierarchicalProperties = [];
        }
        this.hierarchicalProperties[propName] = { categories: [], options: [] };
        const props = this.getOptions(propName);
        if (!props) {
            return this.hierarchicalProperties[propName];
        }

        for (const prop of props) {
            if (prop.label === '') {
                // don't generate empty option since this would require a group
                continue;
            }
            // Example data: db.properties.insert({owner: null, categories: ['coffee'], property: 'Processing', label: 'Hybrid#wet-hulled'); cnt++;
            const seperatorIdx = prop.label.indexOf(Constants.LABEL_SEPARATOR);
            const cat = prop.label.substring(0, seperatorIdx);
            let idx = this.hierarchicalProperties[propName].categories.indexOf(cat);
            if (idx < 0) {
                idx = this.hierarchicalProperties[propName].categories.length;
                this.hierarchicalProperties[propName].categories.push(cat);
            }
            if (typeof this.hierarchicalProperties[propName].options[idx] === 'undefined') {
                this.hierarchicalProperties[propName].options[idx] = [];
            }
            const label = prop.label.substring(seperatorIdx + 1);
            this.hierarchicalProperties[propName].options[idx].push({ label, value: prop.value });
        }
        return this.hierarchicalProperties[propName];
    }

    // // returns a mapping between a processing value ('fully washed') and its category ('Wet')
    // getProcCatMap(): Map<string, string> {
    //     if (this.procCatMap) {
    //         return this.procCatMap;
    //     }
    //     this.getHierarchicalProperty('Processing');
    //     return this.procCatMap;
    // }

    isLoadingOrigins(): boolean {
        if (this.props && this.coffeecopy && typeof this.coffeecopy.origin === 'string') {
            // origin is a string, we work with InternalOriginType
            const ori = this.findOriginProperty(this.coffeecopy.origin) as unknown as string;
            if (ori) {
                this.coffeecopy.origin = ori;
            }
        }
        return this.loadingOrigins;
    }

    clone(): void {
        if (this.readOnly) { return; }

        this.objectChangedService.objectChanged({ model: 'coffees', info: { object: this.coffee, action: 'clone' }, reload: false });
    }

    edit(notifyParent = true): void {
        if (this.readOnly || (this.editMode >= 0 && this.editMode !== this.index)) {
            // already another coffee in editmode, ignore
            return;
        }

        this.coffeecopy = cloneDeep(this.coffee);
        this.loadRegionsForOrigin((this.coffeecopy?.origin as unknown as InternalOriginType)?.origin);

        if (typeof this.coffeecopy.default_unit === 'undefined') {
            this.coffeecopy.default_unit = { name: Enumerations.CoffeeUnits._NONE, size: 1 };
        }
        if (typeof this.coffeecopy.crop_date === 'undefined') {
            this.coffeecopy.crop_date = { landed: [], picked: [] };
        } else {
            if (typeof this.coffeecopy.crop_date.landed === 'undefined') {
                this.coffeecopy.crop_date.landed = [];
            }
            if (typeof this.coffeecopy.crop_date.picked === 'undefined') {
                this.coffeecopy.crop_date.picked = [];
            }
        }
        if (typeof this.coffeecopy.altitude === 'undefined') {
            this.coffeecopy.altitude = { min: undefined, max: undefined };
        }
        if (typeof this.coffeecopy.stock === 'undefined') {
            this.coffeecopy.stock = [];
        }
        if (typeof this.coffeecopy.ICO === 'undefined') {
            this.coffeecopy.ICO = { origin: undefined, exporter: undefined, lot: undefined };
        }
        if (typeof this.coffeecopy.screen_size === 'undefined') {
            this.coffeecopy.screen_size = { min: undefined, max: undefined };
        }
        if (typeof this.coffeecopy.defects_unit === 'undefined') {
            this.coffeecopy.defects_unit = 350;
        }
        if (typeof this.coffeecopy.tags === 'undefined') {
            this.coffeecopy.tags = [];
        }
        if (typeof this.coffeecopy.certifications === 'undefined') {
            this.coffeecopy.certifications = [];
        }
        if (Array.isArray(this.coffeecopy.flavors)) {
            this.flavors = this.coffeecopy.flavors ? this.coffeecopy.flavors.join(', ') : '';
        } else if (!this.coffeecopy.flavors) {
            this.flavors = '';
        } else {
            this.coffeecopy.flavors = (this.coffeecopy.flavors as string)?.toString().split(', ') || [];
            this.flavors = this.coffeecopy.flavors ? this.coffeecopy.flavors.join(', ') : '';
        }
        this.varietals = this.coffeecopy.varietals ? this.coffeecopy.varietals : [];

        // convert origin to InternalOriginType if necessary
        if (typeof this.coffeecopy.origin === 'string') {
            this.coffeecopy.origin = this.findOriginProperty(this.coffeecopy.origin) as unknown as string;
        }
        // check whether ICO origin and origin match
        if (this.coffeecopy.origin && this.coffeecopy.ICO && this.coffeecopy.ICO.origin) {
            const originICOs: string[] = [];
            const originsICO = this.getOptions('ICO_origin');
            if (originsICO) {
                for (const icoo of originsICO) {
                    if (icoo.value.toString() === this.coffeecopy.ICO.origin.toString()) {
                        originICOs.push(icoo.label);
                    }
                }
            }
            if (originICOs.length) {
                this.originDifferentFromICO = originICOs.indexOf((this.coffeecopy.origin as unknown as InternalOriginType).country) < 0;
            }
        }

        if (typeof this.producers !== 'undefined' && this.coffeecopy.producer && this.coffeecopy.producer.toString() !== '######') {
            // check whether the producer can be found
            let found = false;
            const prodId = (this.coffeecopy.producer._id || this.coffeecopy.producer).toString();
            for (let p = 0; p < (this.producers || []).length; p++) {
                if (this.producers[p]._id.toString() === prodId) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                // not found, happens if producer was deleted
                this.coffeecopy.producer = null;
            }
        }

        if (typeof this.coffeecopy.selection !== 'undefined') {
            this.coffeecopy.selection = this.tr.anslate(this.coffeecopy.selection);
        }

        this.editMode = this.index;
        if (notifyParent) {
            this.objectChangedService.objectChanged({ model: 'coffees', info: { editMode: this.index }, reload: false });
        }
    }

    findOriginProperty(origin: string): InternalOriginType {
        if (!this.allOrigins?.length) {
            this.getOrigins();
        }
        if (!this.allOrigins?.length) {
            return origin as unknown as InternalOriginType;
        }
        for (const origContainer of this.allOrigins) {
            for (const orig of origContainer) {
                if (orig.label === origin || orig.origin === origin) {
                    return orig;
                }
            }
        }
        return undefined;
    }

    cancel(): void {
        this.editMode = -1;
        this.addNewFieldMode = false;
        this.addNewProducerMode = false;
        this.editNewProducerMode = false;
        this.editNewFieldMode = false;
        this.coffeecopy = undefined;
        if (this.isNew === this.index) {
            this.isNew = -1;
            this.coffee.deleted = true;
        }
        this.objectChangedService.objectChanged({ model: 'coffees', info: { object: this.coffee }, reload: false });
        this.isNew = -1;
        this.editCancelled.emit();
    }

    fixSpecialCases(variable: string, val: number): number {
        if (!Number.isFinite(val)) {
            return val;
        }
        if (variable === 'altitude.min' || variable === 'altitude.max') {
            // fix accidently entered values such as 1.75
            if (Math.round(val) !== val) {
                if (val < 8) {
                    val = Math.round(val * 1000);
                } else {
                    val = Math.round(val);
                }
            }
        } else if (variable === 'screen_size.min' || variable === 'screen_size.max') {
            // fix accidently entered values such as 1.75
            val = Math.round(val);
        }
        return val;
    }

    checkChanges(variable: string, oldValue: number, newValueStr: string, digits = 3, clamp?: ((n: number) => number)): void {
        if (variable.indexOf('.') > 0) {
            const varSplit = variable.split('.');
            this.coffeecopy[varSplit[0]][varSplit[1]] = undefined;
        } else {
            this.coffeecopy[variable] = undefined;
        }
        this.waitingForChanges = true;
        setTimeout(() => {
            const { val, changed } = this.utils.checkChangedValue(oldValue, newValueStr, digits, false, true, clamp);
            const fixedVal = this.fixSpecialCases(variable, val);
            if (variable.indexOf('.') > 0) {
                const varSplit = variable.split('.');
                this.coffeecopy[varSplit[0]][varSplit[1]] = fixedVal;
            } else {
                this.coffeecopy[variable] = fixedVal;
            }
            this.waitingForChanges = false;
            if (changed && this.submitPressed === true && fixedVal !== oldValue) {
                this.doSave();
            }
            this.submitPressed = false;
        });
    }

    save(): void {
        if (this.readOnly) { return; }

        this.submitPressed = true;
        if (!this.waitingForChanges) {
            this.doSave();
        }
    }

    doSave(that?: CoffeeComponent, changedPrice?: string): void {
        if (!that) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            that = this;
        }
        that.priceChanged(changedPrice === 'pricePerUnit');
        if (!that.submitPressed) {
            return;
        }
        that.submitPressed = false;
        that.isSaving = true;

        // need a copy of the copy as we change it for sending to the server
        // and we would otherwise change the object that is currently displayed
        const myccopy = cloneDeep(that.coffeecopy);

        if (myccopy.default_unit.name as string === '') {
            myccopy.default_unit.name = Enumerations.CoffeeUnits._NONE;
        }
        // if unit name is None, unit size must be 1
        if (!myccopy.default_unit.name) {
            myccopy.default_unit.size = 1;
        }

        if (that.flavors) {
            myccopy.flavors = that.flavors.split(',').map(s => s.trim());
        } else {
            myccopy.flavors = [];
        }
        if (that.varietals) {
            myccopy.varietals = that.varietals.map(v => v.trim());
        } else {
            myccopy.varietals = [];
        }

        // // TODO set but needs location - No, do it after origin changed (if location.country is not set)
        // if (myccopy.origin) {
        //     if (myccopy.origin['country']) {
        //         myccopy.location.country = myccopy.origin['country'];
        //     }
        // }

        if (myccopy.origin?.['origin']) {
            myccopy.origin = myccopy.origin['origin'];
        } else {
            myccopy.origin = '';
        }

        // need to de-populate the objects
        myccopy.certifications = myccopy.certifications.map(c => c._id) as unknown[] as Certification[];

        delete myccopy.yearLabel;
        delete myccopy.stock;

        let newProducerAdded = false;
        if (that.editingProducer) {
            myccopy.producer = that.editingProducer;
            if (that.locale.substring(0, 2) !== 'en') {
                myccopy.producer.location.country = that.utils.getCountryValue(myccopy.producer.location.country);
            }
            myccopy.producer.location.label = myccopy.producer.label;
            if (!myccopy.producer.label) {
                // as a workaround for #405
                myccopy.producer.label = that.tr.anslate('NEW') + '_' + that.tr.anslate('producer');
            }
            newProducerAdded = true;
        } else if (myccopy.producer?._id) {
            // de-populate
            myccopy.producer = myccopy.producer._id as unknown as Producer;
        }
        let newFieldAdded = false;
        if (that.editingField) {
            myccopy.location = that.editingField;
            if (that.locale.substring(0, 2) !== 'en') {
                myccopy.location.country = that.utils.getCountryValue(myccopy.location.country);
            }
            newFieldAdded = true;
        } else if (myccopy.location?._id) {
            myccopy.location = myccopy.location._id as unknown as Location;
        }

        // don't depopulate location since this might have changed
        // if (myccopy.location?._id && !that.addNewFieldMode) {
        //     myccopy.location = myccopy.location._id as any;
        // }
        //
        // if (myccopy.producer?._id && !that.addNewProducerMode) {
        //     myccopy.producer = myccopy.producer._id as any;
        // }

        // if all entries within crop_date.landed and .picked are empty, remove them
        if (myccopy.crop_date) {
            if (myccopy.crop_date.landed) {
                let allNull = true;
                for (const landed of myccopy.crop_date.landed) {
                    if (landed != null) {
                        allNull = false;
                        break;
                    }
                }
                if (allNull) {
                    // myccopy.crop_date.landed = [];
                    delete myccopy.crop_date.landed;
                }
            }
            if (myccopy.crop_date.picked) {
                let allNull = true;
                for (const picked of myccopy.crop_date.picked) {
                    if (picked != null) {
                        allNull = false;
                        break;
                    }
                }
                if (allNull) {
                    // myccopy.crop_date.picked = [];
                    delete myccopy.crop_date.picked;
                }
            }
        }
        if (!myccopy.crop_date?.landed && !myccopy.crop_date?.picked) {
            delete myccopy.crop_date;
        }

        // save custom regions that have not yet been saved
        for (let r = 0; r < that.customExternalRegions?.length; r++) {
            const customRegion = that.customExternalRegions[r];
            that.addCustomRegionToDB(customRegion, that);
        }
        that.customExternalRegions = undefined;

        // save custom selection entry
        if (myccopy.selection) {
            let found = false;
            const allSelections = that.getOptions('Selection');
            for (let s = 0; allSelections && s < allSelections.length; s++) {
                if (allSelections[s].label === myccopy.selection || allSelections[s].value === myccopy.selection) {
                    found = true;
                    myccopy.selection = allSelections[s].value;
                    break;
                }
            }
            if (!found) {
                that.addCustomSelectionToDB(myccopy.selection, that);
                // add locally
                if (that.props && that.props['Selection'] && that.props['Selection'].length > 0) {
                    that.props['Selection'].push({ label: myccopy.selection, value: myccopy.selection });
                }
            }
        }

        if (myccopy.tags?.length > 0) {
            myccopy.tags = myccopy.tags.map(t => t._id ? t._id : t) as Tag[];
        }

        // note that isEqual removes all equal properties from myccopy
        if (that.isNew !== that.index && !that.coffee['cloned']) {
            if (that.isEqual(that.coffee, myccopy)) {
                that.alertService.success(that.tr.anslate('Nothing to change'));
                that.objectChangedService.objectChanged({ model: 'blends', info: undefined, reload: false });
                that.editMode = -1;
                that.isSaving = false;
                return;
            }
        } else {
            that.utils2.cleanResult(myccopy);
        }
        if (myccopy.hr_id && myccopy._id) {
            // make sure only one of _id and hr_id is present in the update object
            delete myccopy.hr_id;
        }
        delete that.coffee['cloned'];

         
        let obs: Observable<{ success: boolean, result: Coffee, error: string }>;
        if (that.isNew === that.index && !that.existingId) {
            obs = that.standardService.add<Coffee>('coffees', myccopy, that.special ? { i: that.special } : undefined);
        } else {
            // if an existing item is updated, isEqual deleted _id and hr_id; re-add one of them (prefer _id)
            if (that.coffee?._id || that.existingId) {
                myccopy._id = that.coffee._id || that.existingId;
            } else {
                myccopy.hr_id = that.coffee.hr_id;
            }
            obs = that.standardService.update<Coffee>('coffees', myccopy, that.special ? { i: that.special } : undefined);
        }
        obs.pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(that.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response) {
                        // nothing changed
                        that.alertService.success(that.tr.anslate('Nothing to change'));
                        that.objectChangedService.objectChanged({ model: 'coffees', info: { editMode: -1 }, reload: false });

                    } else if (response.success === true) {
                        that.coffee = response?.result ? that.utils.dateifyCoffees([response.result])?.[0] : cloneDeep(that.coffeecopy);
                        if (newProducerAdded && that.coffee.producer) {
                            that.producerAdded.emit(that.coffee.producer);
                        }
                        if (newFieldAdded && that.coffee.location) {
                            that.fieldAdded.emit(that.coffee.location);
                        }

                        that.recalcBeansYearLabel();
                        // convert origin to InternalOriginType if necessary
                        if (typeof that.coffee.origin === 'string') {
                            that.coffee.origin = that.findOriginProperty(that.coffee.origin) as unknown as string;
                        }
                        const haveInitialStock: boolean = that.store && typeof that.unitamount !== 'undefined' && that.unitamount !== 0;
                        if (haveInitialStock) {
                            // create initial stock
                            const purchase = new Purchase();
                            delete purchase.reconciled;
                            purchase.amount = that.unitamount;
                            purchase.coffee = (that.coffee?._id || that.coffee).toString() as unknown as Coffee;
                            if (that.editingSupplier) {
                                purchase.supplier = that.editingSupplier;
                                purchase.supplier.location.country = that.utils.getCountryValue(purchase.supplier.location.country);
                                purchase.supplier.location.label = purchase.supplier.label;
                            } else {
                                purchase.supplier = that.supplier ? (that.supplier._id || that.supplier).toString() as unknown as Supplier : undefined;
                            }
                            if (that.date) {
                                purchase.date = that.date;
                            } else {
                                purchase.date = DateTime.now();
                            }
                            purchase.location = (that.store._id || that.store).toString() as unknown as Location;
                            purchase.price = that.price;

                            that.standardService.add<Purchase>('purchases', purchase)
                                .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(that.ngUnsubscribe))
                                .subscribe({
                                    next: response2 => {
                                        if (response2.success === true) {
                                            // no need to inform the user - has been done in update to coffee below
                                        } else {
                                            that.utils.handleError('error adding purchase', response2.error);
                                        }
                                    },
                                    error: error => {
                                        that.utils.handleError('error adding purchase', error);
                                    }
                                });
                        }
                        that.updateUnitStrings();

                        that.alertService.success(that.tr.anslate('Successfully updated'));
                        if (that.addNewFieldMode || that.editNewFieldMode) {
                            // notify about the added / changed location (field)
                            that.objectChangedService.objectChanged({ model: 'coffees', info: 'loadPlaces', reload: false });
                        }
                        if (that.addNewProducerMode || that.editNewProducerMode) {
                            // notify about the added / changed producer
                            that.objectChangedService.objectChanged({ model: 'coffees', info: 'loadProducers', reload: false });
                        }
                        if (that.addNewSupplierMode || that.editNewSupplierMode) {
                            // notify about the added / changed supplier
                            that.objectChangedService.objectChanged({ model: 'coffees', info: 'loadSuppliers', reload: false });
                        }
                        // notify about the change
                        that.objectChangedService.objectChanged({ model: 'coffees', info: { object: that.coffee }, reload: haveInitialStock });
                        // specific notification for import
                        that.saved.emit();
                        that.updatePBShareLink(that.coffee);

                    } else {
                        that.utils.handleError('error updating the information', response.error);
                    }
                    that.editMode = -1;
                    that.addNewFieldMode = false;
                    that.addNewProducerMode = false;
                    that.editNewProducerMode = false;
                    that.editNewFieldMode = false;
                    that.editingProducer = undefined;
                    that.editingField = undefined;
                    that.isSaving = false;
                },
                error: error => {
                    const regex = RegExp('beans with .* already exist');
                    const err = error?.error?.error ? error.error.error : error?.error;
                    if (regex.test(err)) {
                        // combination of label and crop_date.picked[0] already exists
                        const year = myccopy.crop_date?.picked ? myccopy.crop_date.picked[0] : undefined;
                        if (err.indexOf('hidden') >= 0) {
                            that.utils.handleError('Hidden beans with this name + picked year + origin already exist, {{info}}#' + (year || '') + ' ' + (myccopy.origin ? that.tr.anslate(myccopy.origin) : '') + ' ' + (myccopy.label || ''));
                        } else {
                            that.utils.handleError('Beans with this name + picked year + origin already exist, {{info}}#' + (year || '') + ' ' + (myccopy.origin ? that.tr.anslate(myccopy.origin) : '') + ' ' + (myccopy.label || ''));
                        }
                    } else {
                        that.utils.handleError('error updating the information', error);
                    }
                    that.isSaving = false;
                }
            });
    }

    // ICO_origin_changed(event: { option: { value: string } }): void {
    ICO_origin_changed(event: Event | MatAutocompleteSelectedEvent): void {
        if (event?.['option'] && this.coffeecopy.ICO) {
            this.coffeecopy.ICO.origin = Number.parseInt(event['option'].value, 10);
        }
        if (Number.isFinite(this.coffeecopy.ICO.origin)) {
            this.coffeecopy.ICO.origin = Math.round(this.coffeecopy.ICO.origin);
        }
        this.originDifferentFromICO = false;
        if (this.coffeecopy.ICO && this.coffeecopy.ICO.origin && this.coffeecopy.ICO.origin >= 0) {
            let originICO: { value: string, label: string };
            const originsICO = this.getOptions('ICO_origin');
            if (originsICO) {
                for (const icoo of originsICO) {
                    if (icoo.value.toString() === this.coffeecopy.ICO.origin.toString()) {
                        originICO = icoo;
                        break;
                    }
                }
            }
            if (originICO) {
                if (!this.coffeecopy.origin || (this.coffeecopy.origin['label'] === '')) {
                    this.coffeecopy.origin = this.findOriginProperty(originICO.label) as unknown as string;
                } else {
                    this.originDifferentFromICO = (typeof this.coffeecopy.origin === 'string' && this.coffeecopy.origin !== originICO.label) ||
                        this.coffeecopy.origin['country'] !== originICO.label;
                }
            } else if (this.coffeecopy.origin && (this.coffeecopy.origin['country'] !== '')) {
                let c_orig: { value: string, label: string };
                if (originsICO) {
                    for (const icoo of originsICO) {
                        if (this.coffeecopy.origin['country']?.trim() === icoo.label) {
                            c_orig = icoo;
                            break;
                        }
                    }
                }
                if (c_orig) {
                    // unknown ICO number entered but there is an ICO number for the origin
                    this.originDifferentFromICO = true;
                }
            }
        }
        this.loadRegionsForOrigin((this.coffeecopy.origin as unknown as InternalOriginType)?.origin ?? this.coffeecopy.origin);
    }

    origin_changed(): void {
        const thecoffee = this.coffeecopy ?? this.coffee;
        this.originDifferentFromICO = false;
        if (thecoffee) {
            const coffeeOrigin = (typeof thecoffee.origin === 'string' ? thecoffee.origin : (thecoffee.origin && thecoffee.origin['country'] ? thecoffee.origin['country'] : undefined))?.trim();
            if (coffeeOrigin) {
                let originICO: { value: string, label: string };
                const originsICO = this.getOptions('ICO_origin');
                if (originsICO) {
                    for (const icoo of originsICO) {
                        if (coffeeOrigin === icoo.label) {
                            originICO = icoo;
                            break;
                        }
                    }
                }
                if (originICO) {
                    if (!thecoffee.ICO?.origin) {
                        if (!thecoffee.ICO) {
                            thecoffee.ICO = {};
                        }
                        thecoffee.ICO.origin = parseInt(originICO.value, 10);
                    } else {
                        this.originDifferentFromICO = originICO.value.toString() !== thecoffee.ICO.origin.toString();
                    }
                }
            }
        }
    }

    /**
     * checks all main and tableProperties for equality
     * deletes all fixed and hidden properties (except _id, hr_id) from cUpdate as well as all others that are equal
     */
    isEqual(obj: Coffee, updateObj: Coffee): boolean {
        let equal = true;

        const props = Object.getOwnPropertyNames(updateObj);
        for (const prop of props) {
            if (this.fixedProperties.indexOf(prop) >= 0 || this.hiddenProperties.indexOf(prop) >= 0) {
                // these can be ignored
                // if (prop !== 'hr_id' && prop !== '_id') {
                //     delete updateObj[prop];
                // }
                delete updateObj[prop];
                continue;

            } else if (prop === 'stock') {
                continue;

            } else {
                // it's a mainProperty

                if ((obj[prop] == null || obj[prop]?.length === 0 || (Object.keys(obj[prop]).length === 0 && obj[prop].constructor === Object))
                    && (updateObj[prop] == null || updateObj[prop]?.length === 0 || (Object.keys(updateObj[prop]).length === 0 && updateObj[prop].constructor === Object))) {
                    // both are empty (undefined and null and [] and {} are considered equal here)
                    delete updateObj[prop];
                    continue;
                }

                if (obj[prop] == null || obj[prop]?.length === 0 || (Object.keys(obj[prop]).length === 0 && obj[prop].constructor === Object)
                    || updateObj[prop] == null || updateObj[prop]?.length === 0 || (Object.keys(updateObj[prop]).length === 0 && updateObj[prop].constructor === Object)) {
                    // only one is set
                    equal = false;
                    continue;
                }

                if (prop === 'certifications') {
                    if (obj.certifications && updateObj.certifications && obj.certifications.length !== updateObj.certifications.length) {
                        equal = false;
                        continue;
                    }
                    if (obj.certifications && updateObj.certifications) {
                        // since this is a very limited number of objects, this method of comparison seems passable
                        const cCerts = obj.certifications.map(c => (c._id || c).toString()).sort().reduce((pre, cur) => pre + cur, '');
                        const uCerts = updateObj.certifications.map(c => (c._id || c).toString()).sort().reduce((pre, cur) => pre + cur, '');
                        if (cCerts !== uCerts) {
                            equal = false;
                            continue;
                        }
                    }
                    delete updateObj.certifications;
                    continue;
                }

                if (prop === 'origin') {
                    if (obj.origin === updateObj.origin || obj.origin['origin'] === updateObj.origin) {
                        delete updateObj.origin;
                    }
                }

                // this should also work for props 'location' and 'producer'
                if (this.utils.compareObjectsFn(obj[prop], updateObj[prop]) || JSON.stringify(obj[prop]) === JSON.stringify(updateObj[prop])) {
                    delete updateObj[prop];
                } else {
                    equal = false;
                }
            }
        }
        return equal;
    }

    getUnits(): string[] {
        const keys = Object.values(Enumerations.CoffeeUnits);
        keys.shift();
        return keys;
    }

    getAmount(amount: number): string {
        return this.utils.formatAmount(amount, this.coffee.default_unit, this.currentUser.unit_system);
    }

    getCertTrigger(certs: Certification[]): string {
        // we know that certs.length > 1

        // .label.length > 30 ? coffeecopy.certifications[0].label.substring(0, 28) + '...' :
        let str = (certs[0].abbrev && certs[0].abbrev !== '') ? certs[0].abbrev : certs[0].label;
        let c = 1;
        while (c < certs.length && str.length < 25) {
            str += ', ' + ((certs[c].abbrev && certs[c].abbrev !== '') ? certs[c].abbrev : certs[c].label);
            c += 1;
        }
        if (c < certs.length) {
            str = str.substring(0, 25);
            str += ' ...';
        }
        return str;
    }

    addVarietal(event: MatChipInputEvent): void {
        const input = event.chipInput.inputElement;
        let value = event.value;

        // add if it is possible value
        value = value ? value.trim() : undefined;
        if (!value) {
            return;
        }

        const varietal = this.findVarietal(value);
        if (varietal) {
            if (this.varietals.indexOf(varietal.variety) < 0 && varietal.category === this.coffeecopy.species) {
                this.varietals.push(varietal.variety);
                this.updateSpecies();
                this.changeVarietalFilter();
            }
        }

        // reset the input value
        if (input) {
            input.value = '';
        }
        this.varietalInput = '';
    }

    removeVarietal(varietal: string): void {
        const index = this.varietals.indexOf(varietal);
        if (index >= 0) {
            this.varietals.splice(index, 1);
        }
    }

    varietalSelected(event: MatAutocompleteSelectedEvent): void {
        if (this.varietals.indexOf(event.option.value) < 0) {
            this.varietals.push(event.option.value);
            this.updateSpecies();
        }
        this.varietalInput = '';
        this.varietalInputElem.nativeElement.value = '';
        this.changeVarietalFilter();
        this.scoreInputElem.nativeElement.focus();
        setTimeout(() => {
            this.varietalInputElem.nativeElement.focus();
        });
    }

    changeVarietalFilter(): void {
        // if (!this.allVarietals || this.allVarietals.length === 0) {
        //     this.loadVarietals();
        // }
        this._varietalFilter(this.varietalInput);
    }

    updateSpecies(): void {
        if ((!this.coffeecopy.species) && this.varietals && this.varietals.length > 0) {
            const varietal = this.findVarietal(this.varietals[0]);
            if (varietal) {
                // don't translate species and varietals
                // this.coffeecopy.species = this.tr.anslate('Species#' + varietal.category);
                this.coffeecopy.species = varietal.category;
            }
        }
    }

    speciesSelected(): void {
        // remove all varietals that don't fit in the new species category
        this.varietals = this.varietals.filter(varietal => {
            const vary = this.findVarietal(varietal);
            return vary && vary.category === this.coffeecopy.species;
        });
    }

    filterRegionsForOrigin(value: string): string[] {
        this.curRegionsFilter = value;
        if (!value) {
            return this.regionsForOrigin;
        }
        const filterValue = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return this.regionsForOrigin.filter(region => region.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(filterValue) >= 0);
    }

    addCustomSelectionToDB(selection: string, that?: CoffeeComponent): void {
        if (!that) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            that = this;
        }
        // add the property
        // db.properties.insert({owner: null, categories: ['coffee'], property: 'Selection', label: 'peaberry', value: 'peaberry'}); cnt++;
        const newProp = new Property();
        newProp.categories = ['coffee'];
        newProp.property = 'Selection';
        newProp.label = selection;
        newProp.value = selection;
        newProp.donttranslate = true;
        that.propertiesService.addProperty(newProp)
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(that.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        // silent but happy
                    } else {
                        that.utils.handleError('error adding the custom selection property', response.error);
                    }
                },
                error: error => {
                    that.utils.handleError('error adding the custom selection property', error);
                }
            });
    }

    addCustomRegionToDB(region: string, that?: CoffeeComponent): void {
        if(!that) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            that = this;
        }
        // add as property
        const newProp = new Property();
        newProp.categories = ['coffee'];
        newProp.property = 'Region';
        newProp.label = (that.coffeecopy?.origin as unknown as InternalOriginType)?.origin;
        newProp.value = region;
        newProp.donttranslate = true;
        that.propertiesService.addProperty(newProp)
            .pipe(throttleTime(environment.RELOADTHROTTLE))
            .subscribe({
                next: response => {
                    if (response.success === true) {
                        // silent but happy
                    } else {
                        that.utils.handleError('error adding the region', response.error);
                    }
                },
                error: error => {
                    that.utils.handleError('error adding the region', error);
                }
            });
    }

    regionsChanged(value: string): void {
        if (value.indexOf(this.curRegionsFilter) >= 0 && this.regionsForOrigin.indexOf(this.curRegionsFilter) < 0) {
            // add locally
            this.regionsForOrigin.unshift(this.curRegionsFilter);
            this.customRegions.unshift(this.curRegionsFilter);
            this.addCustomRegionToDB(this.curRegionsFilter, this);
        }
    }

    private _varietalFilter(value: string) {
        if (!value) {
            if (!this.coffeecopy.species) {
                this.filteredVarietals = this.allVarietals.map(varcat =>
                    varcat.filter(varietal => this.varietals.indexOf(varietal.variety) < 0));
                return;
            }
            this.filteredVarietals = this.allVarietals.map(varcat =>
                varcat.filter(varietal => varietal.category === this.coffeecopy.species &&
                    this.varietals.indexOf(varietal.variety) < 0));
            return;
        }

        const filterValue = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        // for each category add those varietals that match the filter
        if (!this.coffeecopy.species || this.coffeecopy.species === '') {
            this.filteredVarietals = this.allVarietals.map(varcat =>
                varcat.filter(varietal =>
                    varietal.variety.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(filterValue) >= 0 &&
                    this.varietals.indexOf(varietal.variety) < 0));
            return;
        }
        this.filteredVarietals = this.allVarietals.map(varcat =>
            varcat.filter(varietal =>
                varietal.category === this.coffeecopy.species &&
                varietal.variety.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(filterValue) >= 0 &&
                this.varietals.indexOf(varietal.variety) < 0));
    }

    findVarietal(varietal: string): Variety {
        const varietalStr = varietal.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        for (const varArr of this.allVarietals) {
            for (const varContainer of varArr) {
                if (varContainer.variety.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') === varietalStr) {
                    return varContainer;
                }
            }
        }
        return undefined;
    }

    checkVarietal(): void {
        if (this.varietalInput) {
            if (!this.allVarietals || this.allVarietals.length === 0) {
                this.varietalInput = '';
            }
            if (!this.findVarietal(this.varietalInput)) {
                this.varietalInput = '';
            }
        }
    }

    getOrigins(): void {
        // get all the origins
        this.loadingOrigins = true;
        this.allOriginRegions = [];
        this.allOrigins = [];
        const origins = this.getOptions('Origin');
        if (!origins) {
            return;
        }
        // sort by label (translated value)
        origins.sort((o1, o2) => o1.label < o2.label ? -1 : o1.label > o2.label ? 1 : 0);
        for (const origin of origins) {
            // db.properties.insert({owner: null, categories: ['coffee'], property: 'Origin', label: 'Asia#Bali (Indonesia)', value: 'Bali'}); cnt++;
            if (!origin.label) {
                origin.label = '';
                origin.value = '';
            }
            const labelSep = origin.label.indexOf(Constants.LABEL_SEPARATOR);
            const region = origin.label.substring(0, labelSep).trim();
            let idx = this.allOriginRegions.indexOf(region);
            if (idx < 0) {
                idx = this.allOriginRegions.length;
                this.allOriginRegions.push(region);
            }
            if (typeof this.allOrigins[idx] === 'undefined') {
                this.allOrigins[idx] = [];
            }
            let country: string;
            let label = origin.label.substring(labelSep + 1).trim();
            const startIdx = origin.label.indexOf('(') + 1;
            if (startIdx > 0) {
                // have specific country specified in '(...)'
                country = origin.label.substring(startIdx, origin.label.indexOf(')')).trim();
                label = origin.label.substring(labelSep + 1, startIdx - 2).trim();
            } else {
                // just use country after '#'
                // don't use value as this is / should not be translated
                country = origin.label.substring(origin.label.indexOf(Constants.LABEL_SEPARATOR) + 1).trim();
            }
            // this.logger.debug('load origin as category=', region, {origin: origin.value, country: country});
            this.allOrigins[idx].push({ origin: origin.value, label: label, country: country, region: region });
        }
        if (this.coffeecopy?.origin) {
            this._originFilter(this.coffeecopy.origin);
        }
        this.loadingOrigins = false;
    }

    changeOriginFilter(): void {
        if (!this.allOrigins || this.allOrigins.length === 0) {
            this.getOrigins();
        }
        this._originFilter(this.coffeecopy.origin);

        if (this.coffeecopy.origin && this.coffeecopy.origin['region']) {
            this.origin_changed();
            if (!this.coffeecopy.default_unit.name) {
                // origin has been set, default_unit has not been touched; set defaults per region/country
                this.coffeecopy.default_unit = { name: Enumerations.CoffeeUnits.BAG, size: 60 };
                if (this.coffeecopy.origin['region'] === 'Central America' || this.coffeecopy.origin['country'] === 'Peru') {
                    this.coffeecopy.default_unit = { name: Enumerations.CoffeeUnits.BAG, size: 69 };
                } else if (this.coffeecopy.origin['country'] === 'Colombia' || this.coffeecopy.origin['country'] === 'Bolivia') {
                    this.coffeecopy.default_unit = { name: Enumerations.CoffeeUnits.BAG, size: 70 };
                } else if (this.coffeecopy.origin['country'] === 'Hawaii') {
                    this.coffeecopy.default_unit = { name: Enumerations.CoffeeUnits.BAG, size: 45 };
                } else if (this.coffeecopy.origin['country'] === 'Jamaica') {
                    this.coffeecopy.default_unit = { name: Enumerations.CoffeeUnits.BARREL, size: 70 };
                }
                this.updateUnitStrings();
            }
        }
    }

    private _originFilter(value: string) {
        if (!value) { value = ''; }
        if (typeof value['origin'] !== 'undefined') { value = value['origin']; }
        const filterValue = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        for (let v = 0; v < this.allOrigins.length; v++) {
            const vars = this.allOrigins[v];
            this.filteredOrigins[v] = vars.filter(origin =>
                (origin.origin && origin.origin.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(filterValue) >= 0) ||
                (origin.country && origin.country.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(filterValue) >= 0));
        }
    }

    displayOriginFn(origin?: InternalOriginType): string | undefined {
        return origin ? origin.label : undefined;
    }

    checkOrigin(): void {
        const thecoffee = this.coffeecopy ?? this.coffee;
        if (thecoffee?.origin && !thecoffee.origin['origin']) {
            const or = this.findOriginProperty(thecoffee.origin);
            if (or) {
                thecoffee.origin = or as unknown as string;
                this.origin_changed();
            } else {
                thecoffee.origin = undefined;
            }
        } else {
            this.origin_changed();
        }
    }

    changeCountryFilter(target: 'producer' | 'supplier' | 'field', value: string): void {
        if (!value) {
            value = '';
        }
        const filterValue = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        if (target === 'producer') {
            this.editingProducer.location.country = value;
            this.filteredCountriesProd = this.allCountries.filter(country =>
                (country.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(filterValue) >= 0));
        } else if (target === 'supplier') {
            this.editingSupplier.location.country = value;
            this.filteredCountriesSupp = this.allCountries.filter(country =>
                (country.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(filterValue) >= 0));
        } else if (target === 'field') {
            this.editingField.country = value;
            this.filteredCountriesField = this.allCountries.filter(country =>
                (country.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(filterValue) >= 0));
        }

        // if (this.coffeecopy.origin && this.coffeecopy.origin['region']) {
        //     this.origin_changed();
        // }
    }

    checkCountry(target: 'producer' | 'supplier' | 'field', value: string): void {
        const cValue = this.utils.getCountryValue(value);
        if (!cValue) {
            if (target === 'producer') {
                if (this.editingProducer?.location) {
                    this.editingProducer.location.country = undefined;
                }
            } else if (target === 'supplier') {
                if (this.editingSupplier?.location) {
                    this.editingSupplier.location.country = undefined;
                }
            } else if (target === 'field') {
                if (this.editingField) {
                    this.editingField.country = undefined;
                }
            }
        }
    }

    changeSelectionFilter(): void {
        const allSelections = this.getOptions('Selection');
        if (!allSelections || allSelections.length === 0) {
            return;
        }

        if (!this.coffeecopy.selection) {
            this.coffeecopy.selection = '';
        }
        const filterValue = this.coffeecopy.selection.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        this.filteredSelections = allSelections.filter(slctn =>
            (slctn.label && slctn.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(filterValue) >= 0));
    }

    /**
     * decides whether to show the table of detailed properties or not
     */
    hasAProperty(): boolean {
        const props = Object.getOwnPropertyNames(this.coffee);
        for (const prop of props) {
            if (prop !== '_id' && prop !== 'created_at' && prop !== 'created_by' && prop !== 'deleted' && prop !== 'internal_hr_id' &&
                prop !== 'owner' && prop !== 'label' && prop !== 'ref_id' && prop !== 'picture' && prop !== 'files' && prop !== 'stock' && prop !== 'default_unit' &&
                prop !== 'producer' && prop !== 'origin' && prop !== 'origin_region' && prop !== 'reference' && prop !== 'low_limit' && prop !== 'certifications' &&
                prop !== 'updated_at' && prop !== 'updated_by' && prop !== 'hr_id' && prop !== '__v' && prop !== '__t' &&
                prop !== 'defects_unit' && prop !== 'location' && prop !== 'refs' && prop !== 'tags' && prop !== 'average_cost' && prop !== 'average_fifo_cost' &&
                prop !== 'curStock' && prop !== 'hidden') {

                // not one of the properties shown elsewhere

                if (this.utils.isaProperty(this.coffee[prop])) {
                    return true;
                }
            }
        }
        return false;
    }

    panel(opened: boolean): void {
        this.isExpanded = opened;
    }

    initialStockSet(): void {
        if (!this.date) {
            this.date = DateTime.now();
        }
    }

    // setProcString(): void {
    //     if (!this.coffee?.processing) {
    //         this.procString = undefined;
    //     }
    //     const pcmap = this.getProcCatMap();
    //     if (!pcmap) {
    //         this.procString =  undefined;
    //     }
    //     const str = pcmap.get(this.coffee.processing);
    //     if (str) {
    //         this.procString =  str.toLowerCase() + ', ' + this.coffee.processing;
    //     } else {
    //         this.procString =  undefined;
    //     }
    // }

    addImage(): void {
        if (this.readOnly || !this.coffee || (!this.coffee._id && !this.coffee.internal_hr_id)) {
            return;
        }

        this.utils.addImage(this.coffee, 'coffees', 'picture', this.ngUnsubscribe,
            () => { this.isSaving = true; }, // dialog closed
            imagePath => { // saving done (or no changes)
                if (typeof imagePath !== 'undefined') {
                    if (imagePath && environment.BASE_API_URL.indexOf('localhost') >= 0) {
                        if (this.coffeecopy) {
                            this.coffeecopy.picture = null;
                            this.waitForLocalServer = true;
                            setTimeout(() => {
                                this.waitForLocalServer = false;
                                this.coffeecopy.picture = imagePath;
                                // this.logger.debug('image reloaded for coffeecopy');
                            }, 7250);
                        }
                        this.coffee.picture = null;
                        this.waitForLocalServer = true;
                        setTimeout(() => {
                            this.waitForLocalServer = false;
                            this.coffee.picture = imagePath;
                            // this.logger.debug('image reloaded for coffee');
                        }, 6750);
                    } else {
                        if (this.coffeecopy) {
                            this.coffeecopy.picture = imagePath;
                        }
                        this.coffee.picture = imagePath;
                    }
                }
                this.isSaving = false
            },
        );
    }

    filesChanged(newFiles: string[]): void {
        if (this.readOnly) { return; }

        this.coffee.files = newFiles;
        this.standardService.update<Coffee>('coffees', { _id: this.coffee._id, files: this.coffee.files, label: undefined })
            .pipe(throttleTime(environment.RELOADTHROTTLE), takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: response => {
                    if (!response || response.success === true) {
                        this.alertService.success(this.tr.anslate('Successfully updated'));
                        this.logger.debug('update successful');
                    } else {
                        this.utils.handleError('Could not update documents', response.error);
                    }
                },
                error: error => {
                    this.utils.handleError('Could not update documents', error);
                }
            });
    }

    packagingUnitChanged(): void {
        switch (this.coffeecopy.default_unit.name) {
            case Enumerations.CoffeeUnits._NONE:
                this.coffeecopy.default_unit.size = Enumerations.CoffeeUnitSizesInKg._NONE;
                break;
            case Enumerations.CoffeeUnits.BAG:
                this.coffeecopy.default_unit.size = Enumerations.CoffeeUnitSizesInKg.BAG;
                break;
            case Enumerations.CoffeeUnits.BARREL:
                this.coffeecopy.default_unit.size = Enumerations.CoffeeUnitSizesInKg.BARREL;
                break;
            case Enumerations.CoffeeUnits.BOX:
                this.coffeecopy.default_unit.size = Enumerations.CoffeeUnitSizesInKg.BOX;
                break;
            default:
                this.coffeecopy.default_unit.size = Enumerations.CoffeeUnitSizesInKg._NONE;
        }
    }

    getUnitPlaceholder(unit: 'kg'): string {
        return this.tr.anslate('Weight of a ' + this.coffeecopy.default_unit.name + ' in ' + (unit ?? this.mainUnit) + '?');
    }

    checkChangesUnits(parent: unknown, variable: string, oldValue: number, newValueStr: string, toUnit: boolean, digits = 3): void {
        parent[variable] = undefined;
        this.waitingForChanges = true;
        setTimeout(() => {
            const { val, changed } = this.utils.checkChangedValue(oldValue * (!toUnit ? this.utils.getUnitFactor(this.mainUnit) : 1), newValueStr, digits);
            parent[variable] = val / (!toUnit ? this.utils.getUnitFactor(this.mainUnit) : 1);
            this.waitingForChanges = false;
            if (changed) {
                this.calcAmount(toUnit);
                if (this.submitPressed === true && parent[variable] !== oldValue) {
                    this.doSave();
                }
            }
            this.submitPressed = false;
        });
    }

    hiddenChanged(object: { _id: string; hidden: boolean }): void {
        this.coffee.hidden = object.hidden;
        if (this.expPanel) {
            this.expPanel.close();
        }
        this.objectChangedService.objectChanged({ model: 'coffees', info: { type: 'unhidden', coffee: object }, reload: false });
    }

    imageLoadError(supplier: Supplier): void {
        supplier['image'] = undefined;
    }
}
