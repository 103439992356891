@if (coffee) {

<ng-template #labelEntry let-obj="obj">
    @if (obj?.hr_id) {
    <div class="smallerfont">{{obj.hr_id}}</div>
    }
    @if (obj?.label) {
    <div>{{obj.label}}</div>
    }
</ng-template>

<span [ngClass]="{'hideme': (coffee.hidden && !showHidden) || (!coffee.hidden && showHidden)}" #maindiv>
    <mat-expansion-panel hideToggle="true" [expanded]="isNew === index || isExpanded" (closed)="panel(false)" (opened)="panel(true)" [disabled]="editMode === index" [ngClass]="{'hideme': (coffee.hidden && !showHidden) || (!coffee.hidden && showHidden), 'hidden-label': coffee.hidden}">
        <mat-expansion-panel-header [ngClass]="{'hidden-label': coffee.hidden}" expandedHeight="auto" collapsedHeight="auto">
            <!-- <mat-expansion-panel-header> -->
            <mat-panel-title class="paneltitle" #appCoffee [ngClass]="{'shortTitle': (coffee.varietals && coffee.varietals.length > 0) || (coffee.certifications && coffee.certifications.length > 0) || procString}">
                <div class="panel-titel-amount-wrapper">
                    @if (showStockPlaceholder || curStock.value || curStock.pre) {
                    <span class="head-stock-wrapper">
                        @if (curStock.value) {
                        <span class="head-stock-line" [ngClass]="{'negative-amount': curStock.value < 0}">{{curStock.pre}}</span>
                        }
                        @if (curStock.pre) {
                        <br />
                        }
                        @if (Math.abs(curStock.value) >= 10) {
                        <span class="head-stock-line-unitamount" [ngClass]="{'negative-amount': curStock.value < 0}">{{curStock.value | number:'1.0-0'}}{{curStock.post}}</span>
                        }
                        <!-- condition same as Math.abs(curStock.value) < 10 but code indent did not work -->
                        @if (curStock.value && 10 > Math.abs(curStock.value)) {
                        <span class="head-stock-line-unitamount" [ngClass]="{'negative-amount': curStock.value < 0}">{{curStock.value | number:'1.0-1'}}{{curStock.post}}</span>
                        }
                    </span>
                    }
                    @if (showHidden) {
                    <span class="head-hidden-placeholder"></span>
                    }
                    <span class="head-label-wrapper">
                        <span class="head-hrid">{{coffee.hr_id}}</span>
                        <app-organicicon [isOrganic]="isOrganic" [size]="11" [spacer]=true></app-organicicon>
                        @if (coffee.origin?.['country']) {
                        <span class="head-origin"> {{coffee.origin['country']}}</span>
                        }
                        @if (coffee.yearLabel) {
                        <span> {{coffee.yearLabel}}</span>
                        }
                        <div class="head-label" [ngClass]="{'hidden-label': coffee.hidden}"> {{coffee.label}}</div>
                    </span>
                </div>
            </mat-panel-title>
            @if (!isExpanded && (isLarge$ | async) && (coffee.varietals?.length || coffee.processing || coffee.certifications?.length)) {
            <mat-panel-description [ngStyle]="{'max-width': ((isLarge$ | async) ? '40%' : '25%')}" style="display: block">
                <div class="head-desc-wrapper">
                    <div class="head-certs">
                        @if (coffee.certifications?.length) {
                        @for (cert of coffee.certifications; track cert?.abbrev; let c = $index) {
                        {{cert.abbrev ? cert.abbrev : cert.label}}@if (coffee.certifications.length -1 > c) {
                        <span>, </span>
                        }
                        }
                        } @else {
                        &nbsp;
                        }
                    </div>
                    @if (coffee.varietals?.length) {
                    <span>{{coffee.varietals.join(', ')}}</span>
                    }
                    @if (coffee.processing) {
                    <span>
                        @if (coffee.varietals?.length) {
                        <span class="littlespaceleft">&mdash;</span>
                        }
                        {{getLabelAndCat(coffee.processing)?.cat?.toLowerCase()}}
                    </span>
                    }
                </div>
            </mat-panel-description>
            }
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            @if (!readOnly && editMode === index) {
            <div>
                <form id="form" name="form" (ngSubmit)="f.form.valid && save()" #f="ngForm">
                    @if (coffeecopy) {
                    <div class="outerdiv">
                        <div class="nottable">
                            <mat-card appearance="outlined" class="highlight-important">
                                <mat-card-content style="padding-bottom: 0">
                                    <!-- label -->
                                    <mat-form-field class="form-group" subscriptSizing="dynamic">
                                        <mat-label i18n="generic$$Name@@Name">Name</mat-label>
                                        <input matInput required autofocus type="text"
                                            [(ngModel)]="coffeecopy.label"
                                            name="label" data-test="npt_label"
                                            placeholder="Name" i18n-placeholder="generic$$Name@@Name"
                                            (ngModelChange)="uniqueChecker.next({ prop: 'label', value: $event })"
                                            (blur)="uniqueLabelChanged.emit({ prop: 'label', value: coffeecopy.label })">
                                    </mat-form-field><br />
                                    <!-- origin (country) -->
                                    @if (loadingOrigins) {
                                    <div class="spinner-with-comment">
                                        <mat-spinner diameter="20"></mat-spinner> <span class="spinner-comment" i18n="beans$$currently loading the list of possible origins@@loading origins">Loading origins ...</span>
                                    </div>
                                    }
                                    @if (!isLoadingOrigins()) {
                                    <mat-form-field class="form-group" subscriptSizing="dynamic">
                                        <mat-label i18n="beans$$origin@@Origin">Origin</mat-label>
                                        <input matInput type="text" data-test="npt_origin"
                                            i18n-placeholder="beans$$origin@@Origin" placeholder="Origin"
                                            (input)="changeOriginFilter()" (focus)="changeOriginFilter()" name="origin" id="origin"
                                            [(ngModel)]="coffeecopy.origin" [matAutocomplete]="autoCompleteOrigin"
                                            (ngModelChange)="uniqueChecker.next({ prop: 'origin', value: $event })"
                                            (blur)="checkOrigin(); loadRegionsForOrigin(coffeecopy.origin?.['origin']); uniqueLabelChanged.emit({ prop: 'origin', value: coffeecopy.origin })">
                                        <mat-autocomplete #autoCompleteOrigin="matAutocomplete" [displayWith]="displayOriginFn">
                                            @for (region of allOriginRegions; track region; let regionIdx = $index) {
                                            @if (filteredOrigins[regionIdx] && filteredOrigins[regionIdx].length) {
                                            <mat-optgroup [label]="region">
                                                @for (origin of filteredOrigins[regionIdx]; track origin?.label) {
                                                <mat-option [value]="origin">
                                                    {{origin.label}}
                                                </mat-option>
                                                }
                                            </mat-optgroup>
                                            }
                                            }
                                        </mat-autocomplete>
                                    </mat-form-field>
                                    }
                                    @if (originDifferentFromICO) {
                                    <div class="comment warn-amount" i18n="beans$$origin differs@@Origin differs from ICO origin">Different to ICO origin!</div>
                                    }
                                    @if (!originDifferentFromICO) {
                                    <br />
                                    }
                                    <!-- regions -->
                                    <div class="form-field-with-spinner">
                                        <mat-form-field class="form-group" subscriptSizing="dynamic">
                                            <mat-label i18n="graphs$$graph title@@Regions">Regions</mat-label>
                                            <mat-select [disabled]="readOnly || !coffeecopy.origin" multiple matInput
                                                [compareWith]="utils.compareObjectsFn" name="regions" data-test="slct_regions"
                                                placeholder="Regions" i18n-placeholder="graphs$$graph title@@Regions"
                                                [(ngModel)]="coffeecopy.regions"
                                                (selectionChange)="regionsChanged($event?.value)"
                                                panelClass="with-delete-button">
                                                <!-- (selectionChange)=" regionsChanged()" -->
                                                @if (coffeecopy.regions?.length) {
                                                <mat-select-trigger>
                                                    {{coffeecopy.regions.join(', ')}}
                                                </mat-select-trigger>
                                                }
                                                @if (regionsForOrigin) {
                                                <mat-option>
                                                    <ngx-mat-select-search ngModel (ngModelChange)="filteredRegionsForOrigin = filterRegionsForOrigin($event)" name="regionSelect"
                                                        placeholderLabel="Find / Add ..." i18n-placeholderLabel="transaction$$Find / Add ...@@Find / Add ..."
                                                        noEntriesFoundLabel="" i18n-noEntriesFoundLabel="generic$$no matches found using the given filter@@no match for filter">
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                @if (curRegionsFilter) {
                                                <mat-option [value]="curRegionsFilter">
                                                    {{curRegionsFilter}}
                                                </mat-option>
                                                }
                                                @for (region of filteredRegionsForOrigin; track region) {
                                                <mat-option [value]="region">
                                                    {{region}}
                                                    @if (customRegions.indexOf(region) >= 0) {
                                                    <a (click)="deleteCustomRegion(region)" (keydown.enter)="deleteCustomRegion(region); $event.preventDefault()" role="button" [tabindex]="0">
                                                        <mat-icon class="smallish" style="vertical-align: sub;">delete</mat-icon>
                                                    </a>
                                                    }
                                                </mat-option>
                                                }
                                                }
                                            </mat-select>
                                        </mat-form-field>
                                        @if (loadingRegions) {
                                        <mat-spinner diameter="15" style="margin-left: 5px;"></mat-spinner>
                                        }
                                    </div>
                                    <!-- crop date picked -->
                                    <div style="position: relative; margin-top: 4px; margin-bottom: 0; margin-left: 3px;">
                                        <span i18n="beans$$beans property crop date - picked@@crop_date_picked">Picked</span>
                                        <span i18n="beans$$beans property crop date - landed@@crop_date_landed" style="position: absolute; left: 54%;">Landed</span>
                                    </div>
                                    <mat-form-field class="form-group quarter1"
                                        subscriptSizing="dynamic" style="margin-bottom: 15px">
                                        <mat-label i18n="generic$$Year@@Year@@Regions">Year</mat-label>
                                        <input matInput type="number" min="2000" max="2999" step="1" class="halfwidth"
                                            name="crop_date_picked_year" data-test="npt_picked0"
                                            [(ngModel)]="coffeecopy.crop_date.picked[0]"
                                            (change)="coffeecopy.crop_date.picked[0] = utils.clamp(2000, 2999, 0)(coffeecopy.crop_date.picked[0])"
                                            placeholder="Year" i18n-placeholder="generic$$Year@@Year"
                                            (ngModelChange)="uniqueChecker.next({ prop: 'pickedYear', value: $event?.toString() })" (blur)="uniqueLabelChanged.emit({ prop: 'pickedYear', value: coffeecopy.crop_date.picked[0]?.toString() })">
                                    </mat-form-field>
                                    <mat-form-field class="form-group quarter2" subscriptSizing="dynamic">
                                        <mat-label i18n="generic$$Month@@Month">Month</mat-label>
                                        <mat-select class="nexttonumber" panelWidth="null"
                                            name="crop_date_picked_month" data-test="slct_picked1"
                                            i18n-placeholder="generic$$Month@@Month" placeholder="Month"
                                            [(ngModel)]="coffeecopy.crop_date.picked[1]">
                                            <mat-option [value]="null"></mat-option>
                                            @for (month of months; track month) {
                                            <mat-option [value]="$index">
                                                {{month}}
                                            </mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- crop date landed -->
                                    <mat-form-field class="form-group quarter1" subscriptSizing="dynamic">
                                        <mat-label i18n="generic$$Year@@Year">Year</mat-label>
                                        <input matInput type="number" min="2000" max="2999" class="halfwidth"
                                            name="crop_date_landed_year" data-test="npt_landed0"
                                            placeholder="Year" i18n-placeholder="generic$$Year@@Year"
                                            [(ngModel)]="coffeecopy.crop_date.landed[0]"
                                            (change)="coffeecopy.crop_date.landed[0] = utils.clamp(2000, 2999, 0)(coffeecopy.crop_date.landed[0])">
                                    </mat-form-field>
                                    <mat-form-field class="form-group quarter4" subscriptSizing="dynamic">
                                        <mat-label i18n="generic$$Month@@Month">Month</mat-label>
                                        <mat-select class="nexttonumber" panelWidth="null"
                                            name="crop_date_landed_month" data-test="slct_landed1"
                                            placeholder="Month" i18n-placeholder="generic$$Month@@Month"
                                            [(ngModel)]="coffeecopy.crop_date.landed[1]">
                                            <mat-option [value]="null"></mat-option>
                                            @for (month of months; track month) {
                                            <mat-option [value]="$index">
                                                {{month}}
                                            </mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                </mat-card-content>
                            </mat-card>
                            <!-- ref id (lot) -->
                            <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                <mat-label i18n="beans$$beans property lot@@lot">Lot</mat-label>
                                <input matInput [(ngModel)]="coffeecopy.ref_id"
                                    type="text" name="lot" data-test="npt_ref_id"
                                    placeholder="Lot" i18n-placeholder="beans$$beans property lot@@lot">
                            </mat-form-field>
                            <!-- reference -->
                            <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                <mat-label i18n="transaction$$placeholder, an arbitrary reference string@@reference">Reference</mat-label>
                                <input matInput [(ngModel)]="coffeecopy.reference"
                                    type="text" name="reference" data-test="npt_reference"
                                    placeholder="Reference" i18n-placeholder="transaction$$placeholder, an arbitrary reference string@@reference">
                            </mat-form-field><br />
                            <!-- certifications -->
                            @if (!allCertifications) {
                            <div class="spinner-with-comment">
                                <mat-spinner diameter="20"></mat-spinner> <span class="spinner-comment" i18n="coffee$$currently loading all possible certifications@@loading certifications">Loading certifications ...</span>
                            </div>
                            }
                            @if (allCertifications) {
                            <mat-form-field class="form-group" subscriptSizing="dynamic">
                                <mat-label i18n="beans$$certifications@@certifications">Certifications</mat-label>
                                <mat-select multiple panelWidth="null"
                                    [(ngModel)]="coffeecopy.certifications"
                                    placeholder="Certifications" i18n-placeholder="beans$$certifications@@certifications"
                                    name="certifications" data-test="slct_certifications"
                                    [compareWith]="utils.compareByLabelFn" panelClass="twoLineOption">
                                    <mat-select-trigger>
                                        @if (coffeecopy.certifications?.length > 1) {
                                        <span>
                                            {{getCertTrigger(coffeecopy.certifications)}}
                                        </span>
                                        } @else {
                                        {{coffeecopy.certifications?.length ? (coffeecopy.certifications[0].abbrev ? coffeecopy.certifications[0].abbrev + ' (' + coffeecopy.certifications[0].label + ')' : coffeecopy.certifications[0].label) : ''}}
                                        }
                                    </mat-select-trigger>
                                    @for (cert of allCertifications; track cert?.abbrev) {
                                    <mat-option [value]="cert">
                                        <div style="line-height: 18px;">
                                            <span data-test=ptn_certification_text>{{cert.abbrev ? cert.abbrev + (cert.abbrev !== cert.label ? (' (' + cert.label + ')') : '') : cert.label}}</span>@if (cert.web) {
                                            <a href="{{cert.web}}" target="_blank" rel="noopener">&nbsp;<mat-icon class="small">launch</mat-icon></a>
                                            }
                                        </div>
                                        <div class="certification-classes" style="line-height: 18px;">{{getCertProperties(cert)}}</div>
                                    </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                            }<br />
                            <!-- producer -->
                            @if (!producers) {
                            <div class="spinner-with-comment">
                                <mat-spinner diameter="20"></mat-spinner> <span class="spinner-comment" i18n="beans$$currently loading list of producers@@loading producers">Loading producers ...</span>
                            </div>
                            }
                            <div class="flex-wrap">
                                @if (producers && !addNewProducerMode && !editNewProducerMode) {
                                <mat-form-field
                                    class="form-group left-large" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$producer@@producer">Producer</mat-label>
                                    <mat-select name="producer" data-test="slct-producer"
                                        placeholder="Producer" i18n-placeholder="contacts$$producer@@producer"
                                        [(ngModel)]="coffeecopy.producer" [compareWith]="utils.compareObjectsFn"
                                        (selectionChange)="producerChanged()">
                                        <mat-option [value]="null"></mat-option>
                                        <mat-option value="######" class="highlight-add-option">
                                            @if (editingProducer?.label && coffeecopy?.producer?.toString() === '######') {
                                            <span>{{editingProducer.label}}</span>
                                            } @else {
                                            <ng-container i18n="beans$$add new producer@@add a new producer">Add new producer</ng-container>
                                            }
                                        </mat-option>
                                        @for (producer of producers; track producer?.label) {
                                        <mat-option [value]="producer">
                                            @if (producer.label) {
                                            <span>{{producer.label}}</span>
                                            }
                                            @if (producer?.location?.country) {
                                            <span> ({{tr.anslate(producer.location.country)}})</span>
                                            }
                                        </mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                                }
                                @if (producers && !addNewProducerMode && !editNewProducerMode && coffeecopy.producer?.toString() === '######') {
                                <button matButton type="button" class="smallButton right-button" i18n="generic$$edit some details, button@@edit" (click)="editProducer()">Edit</button>
                                }
                            </div>
                            @if (producers && editingProducer && (addNewProducerMode || editNewProducerMode)) {
                            <div><span i18n="contacts$$producer@@producer">Producer</span>:</div>
                            <div class="new-place-part">
                                <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                    <mat-label i18n="generic$$Name@@Name">Name</mat-label>
                                    <input required matInput type="text" name="plabel" data-test="npt_prod_label"
                                        placeholder="Name" i18n-placeholder="generic$$Name@@Name"
                                        [(ngModel)]="editingProducer.label">
                                </mat-form-field>
                                @if (editingProducer.location) {
                                <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$street@@street">Street</mat-label>
                                    <input matInput type="text" name="pstreet" data-test="npt_prod_street"
                                        placeholder="Street" i18n-placeholder="contacts$$street@@street"
                                        [(ngModel)]="editingProducer.location.street">
                                </mat-form-field><br />
                                <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$address@@Address">Address</mat-label>
                                    <input matInput type="text" name="paddress" data-test="npt_prod_address"
                                        placeholder="Address" i18n-placeholder="contacts$$address@@Address"
                                        [(ngModel)]="editingProducer.location.address">
                                </mat-form-field>
                                <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$Postal Code@@Postal Code">Postal Code</mat-label>
                                    <input matInput type="text" name="pzip_code" data-test="npt_prod_zip_code"
                                        placeholder="Postal Code" i18n-placeholder="contacts$$Postal Code@@Postal Code"
                                        [(ngModel)]="editingProducer.location.zip_code">
                                </mat-form-field><br />
                                <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$City@@city">City</mat-label>
                                    <input matInput type="text" name="pcity" data-test="npt_prod_city"
                                        placeholder="City" i18n-placeholder="contacts$$City@@city"
                                        [(ngModel)]="editingProducer.location.city">
                                </mat-form-field>
                                <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$region@@region">Region</mat-label>
                                    <input matInput type="text" name="pregion" data-test="npt_prod_region"
                                        placeholder="Region" i18n-placeholder="contacts$$region@@region"
                                        [(ngModel)]="editingProducer.location.region">
                                </mat-form-field><br />
                                <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$subregion@@subregion">Subregion</mat-label>
                                    <input matInput type="text" name="psubregion" data-test="npt_prod_subregion"
                                        placeholder="Subregion" i18n-placeholder="contacts$$subregion@@subregion"
                                        [(ngModel)]="editingProducer.location.subregion">
                                </mat-form-field>
                                <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$country@@Country">Country</mat-label>
                                    <input matInput type="text" name="pcountry" id="country" data-test="npt_prod_country"
                                        placeholder="Country" i18n-placeholder="contacts$$country@@Country"
                                        (input)="changeCountryFilter('producer', $event?.target['value'])"
                                        (focus)="changeCountryFilter('producer', $event?.target['value'])"
                                        [ngModel]="editingProducer.location.country" [matAutocomplete]="autoCompleteCountryProd"
                                        (blur)="checkCountry('producer', $event?.target['value'])">
                                    <mat-autocomplete #autoCompleteCountryProd="matAutocomplete">
                                        @for (country of filteredCountriesProd; track country) {
                                        <mat-option [value]="country">{{country}}</mat-option>
                                        }
                                    </mat-autocomplete>
                                </mat-form-field><br />
                                <!-- coordinates
                          <mat-form-field class="form-group" subscriptSizing="dynamic">
                            <input matInput i18n-placeholder="contacts$$street@@street" type="text" placeholder="Street" name="street" [(ngModel)]="editingProducer.location.street">
                          </mat-form-field><br/>
                          -->
                                }
                                <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$some user defined code for the contact@@code">Code</mat-label>
                                    <input matInput type="text" name="pcode" data-test="npt_prod_code"
                                        placeholder="Code" i18n-placeholder="contacts$$some user defined code for the contact@@code"
                                        [(ngModel)]="editingProducer.code">
                                </mat-form-field>
                                @if (properties?.['Organizational form']; as options) {
                                <mat-form-field
                                    class="form-group halfsizeright" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$org form e.g. single / cooperation, placeholder@@organizational_form">Organizational form</mat-label>
                                    <mat-select name="porganizational_form" data-test="npt_prod_organizational_form"
                                        placeholder="Organizational form" i18n-placeholder="contacts$$org form e.g. single / cooperation, placeholder@@organizational_form"
                                        [(ngModel)]="editingProducer.organizational_form">
                                        @for (orgform of options; track orgform) {
                                        <mat-option [value]="orgform.value">{{orgform.label}}</mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                                } @else {
                                <td>
                                    <mat-spinner diameter="15" strokeWidth="1" class="centered"></mat-spinner>
                                </td>
                                }<br />
                                <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$Phone@@Phone">Phone</mat-label>
                                    <input matInput type="tel" name="pphone" data-test="npt_prod_phone"
                                        placeholder="Phone" i18n-placeholder="contacts$$Phone@@Phone"
                                        [(ngModel)]="editingProducer.phone">
                                </mat-form-field>
                                <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                    <mat-label i18n="users$$email address@@email">Email</mat-label>
                                    <input matInput type="email" name="pemail" data-test="npt_prod_email"
                                        placeholder="Email" i18n-placeholder="users$$email address@@email"
                                        [(ngModel)]="editingProducer.email">
                                </mat-form-field><br />
                                <mat-form-field class="form-group" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$farm owner name@@farmowner">Farm owner</mat-label>
                                    <input matInput type="text" name="pfarmowner" data-test="npt_prod_farmowner"
                                        placeholder="Farm owner" i18n-placeholder="contacts$$farm owner name@@farmowner"
                                        [(ngModel)]="editingProducer.farmowner">
                                </mat-form-field>
                                <mat-form-field class="form-group" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$internet address@@Web">Web</mat-label>
                                    <input matInput type="url" name="pweb" data-test="npt_prod_web"
                                        placeholder="Web" i18n-placeholder="contacts$$internet address@@Web"
                                        [(ngModel)]="editingProducer.web">
                                </mat-form-field><br />
                                @if (addNewProducerMode || editNewProducerMode) {
                                <button matButton type="button"
                                    class="smallButton" data-test="bttn_producer_close"
                                    i18n="generic$$close edit mode, button@@close edit mode"
                                    (click)="closeProducer()">Close</button>
                                }
                            </div>
                            }
                            @if (producers && (addNewProducerMode || editNewProducerMode)) {
                            <br />
                            }
                            <!-- field -->
                            @if (!fields) {
                            <div class="spinner-with-comment">
                                <mat-spinner diameter="20"></mat-spinner> <span class="spinner-comment" i18n="contacts$$loading already defined locations@@loading fields">Loading places ...</span>
                            </div>
                            }
                            <div class="flex-wrap">
                                @if (fields && !addNewFieldMode) {
                                <mat-form-field class="form-group left-large" subscriptSizing="dynamic">
                                    <mat-label i18n="locations$$beans field@@field">Field</mat-label>
                                    <mat-select name="field" data-test="slct_field"
                                        placeholder="Field" i18n-placeholder="locations$$beans field@@field"
                                        [(ngModel)]="coffeecopy.location" [compareWith]="utils.compareObjectsFn" (selectionChange)="fieldChanged()">
                                        <mat-option [value]="null"></mat-option>
                                        <mat-option value="######" class="highlight-add-option">
                                            @if (editingField?.label && coffeecopy?.location?.toString() === '######') {
                                            <span>{{editingField.label}}</span>
                                            } @else {
                                            <ng-container i18n="contacts$$add a new beans field@@add a new field">Add new field</ng-container>
                                            }
                                        </mat-option>
                                        @for (field of fields; track field?._id) {
                                        <mat-option [value]="field">
                                            @if (field.label) {
                                            <span>{{field.label}}</span>
                                            }
                                            @if (field.country) {
                                            <span> ({{tr.anslate(field.country)}})</span>
                                            }
                                        </mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                                }
                                @if (fields && !addNewFieldMode && !editNewFieldMode && coffeecopy.location) {
                                <button matButton type="button" class="smallButton right-button" i18n="generic$$edit some details, button@@edit" (click)="editField()">Edit</button>
                                }
                            </div>
                            @if (fields && !addNewFieldMode) {
                            <br />
                            }
                            @if (fields && editingField && (addNewFieldMode || editNewFieldMode)) {
                            <div class="new-place-part">
                                <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                    <mat-label i18n="generic$$Name@@Name">Name</mat-label>
                                    <input required matInput type="text" name="fieldlabel" data-test="npt_field_label"
                                        placeholder="Name" i18n-placeholder="generic$$Name@@Name"
                                        [(ngModel)]="editingField.label">
                                </mat-form-field>
                                <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$street@@street">Street</mat-label>
                                    <input matInput type="text" name="street" data-test="npt_field_street"
                                        placeholder="Street" i18n-placeholder="contacts$$street@@street"
                                        [(ngModel)]="editingField.street">
                                </mat-form-field><br />
                                <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$address@@Address">Address</mat-label>
                                    <input matInput type="text" name="address" data-test="npt_field_address"
                                        placeholder="Address" i18n-placeholder="contacts$$address@@Address"
                                        [(ngModel)]="editingField.address">
                                </mat-form-field>
                                <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$Postal Code@@Postal Code">Postal Code</mat-label>
                                    <input matInput type="text" name="zip_code" data-test="npt_field_zip_code"
                                        placeholder="Postal Code" i18n-placeholder="contacts$$Postal Code@@Postal Code"
                                        [(ngModel)]="editingField.zip_code">
                                </mat-form-field><br />
                                <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$City@@city">City</mat-label>
                                    <input matInput type="text" name="city" data-test="npt_field_city"
                                        placeholder="City" i18n-placeholder="contacts$$City@@city"
                                        [(ngModel)]="editingField.city">
                                </mat-form-field>
                                <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$region@@region">Region</mat-label>
                                    <input matInput type="text" name="region" data-test="npt_field_region"
                                        placeholder="Region" i18n-placeholder="contacts$$region@@region"
                                        [(ngModel)]="editingField.region">
                                </mat-form-field><br />
                                <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$subregion@@subregion">Subregion</mat-label>
                                    <input matInput type="text" name="subregion" data-test="npt_field_subregion"
                                        placeholder="Subregion" i18n-placeholder="contacts$$subregion@@subregion"
                                        [(ngModel)]="editingField.subregion">
                                </mat-form-field>
                                <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$country@@Country">Country</mat-label>
                                    <input matInput type="text" name="fcountry" id="country" data-test="npt_field_country"
                                        placeholder="Country" i18n-placeholder="contacts$$country@@Country"
                                        (input)="changeCountryFilter('field', $event?.target['value'])"
                                        (focus)="changeCountryFilter('field', $event?.target['value'])"
                                        [ngModel]="editingField.country" [matAutocomplete]="autoCompleteCountryField"
                                        (blur)="checkCountry('field', $event?.target['value'])">
                                    <mat-autocomplete #autoCompleteCountryField="matAutocomplete">
                                        @for (country of filteredCountriesField; track country) {
                                        <mat-option [value]="country">{{country}}</mat-option>
                                        }
                                    </mat-autocomplete>
                                </mat-form-field><br />
                                <!-- coordinates -->
                                <!-- <mat-form-field class="form-group" subscriptSizing="dynamic">
                          <mat-label i18n="contacts$$producer@@producer">Producer</mat-label>
                          <input matInput i18n-placeholder="contacts$$street@@street" type="text" placeholder="Street" name="street" [(ngModel)]="editingField.street">
                        </mat-form-field><br/> -->
                                @if (addNewFieldMode || editNewFieldMode) {
                                <button matButton type="button"
                                    class="smallButton" data-test="bttn_field_close"
                                    i18n="generic$$close edit mode, button@@close edit mode"
                                    (click)="closeField()">Close</button>
                                }
                            </div>
                            }
                            @if (fields && (addNewFieldMode || editNewFieldMode)) {
                            <br />
                            }
                            <!-- Packaging Unit -->
                            <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic"
                                [ngClass]="{'thirdsizeleft': mainUnit === 'lbs' || mainUnit === 'lb' || mainUnit === 'oz'}">
                                <mat-label i18n="unit_system$$descriptive unit specification@@unit_specification">Packaging Unit</mat-label>
                                <mat-select name="default_unit_name" data-test="slct_packaging" hideSingleSelectionIndicator="true"
                                    [(ngModel)]="coffeecopy.default_unit.name" (selectionChange)="packagingUnitChanged()"
                                    placeholder="Packaging Unit" i18n-placeholder="unit_system$$descriptive unit specification@@unit_specification">
                                    <mat-option value=''>{{mainUnit}}</mat-option>
                                    @for (unit of getUnits(); track unit) {
                                    <mat-option [value]="unit">{{tr.anslate(unit)}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                            @if (coffeecopy.default_unit?.name) {
                            <mat-form-field [ngClass]="{'default-unit-size-kg-middle': mainUnit === 'lbs' || mainUnit === 'lb' || mainUnit === 'oz'}"
                                class="form-group halfsizeright" subscriptSizing="dynamic">
                                <mat-label>{{getUnitPlaceholder('kg')}}</mat-label>
                                <input matInput required type="text" name="default_unit_size_kg" data-test="npt_packaging_size_kg"
                                    [placeholder]="getUnitPlaceholder('kg')"
                                    (change)="coffeecopy.default_unit.size=utils.checkChangedValue(coffeecopy.default_unit.size, $event?.target['value'], 1).val; calcAmount(true)"
                                    [value]="coffeecopy.default_unit.size | number:'1.0-1'">
                            </mat-form-field>
                            }
                            @if (coffeecopy.default_unit?.name && mainUnit === 'lbs' || mainUnit === 'lb' || mainUnit === 'oz') {
                            <mat-form-field
                                class="form-group default-unit-size-lbs-right" subscriptSizing="dynamic">
                                <mat-label>lbs</mat-label>
                                <input matInput type="text" name="default_unit_size_lbs" data-test="npt_packaging_size_lbs"
                                    placeholder="lbs"
                                    (change)="coffeecopy.default_unit.size=utils.checkChangedValue(coffeecopy.default_unit.size * utils.getUnitFactor('lbs'), $event?.target['value'], 1).val / utils.getUnitFactor('lbs'); calcAmount(true)"
                                    [value]="coffeecopy.default_unit.size * utils.getUnitFactor('lbs') | number:'1.0-1'">
                            </mat-form-field>
                            }<br />
                            <!-- initial stock -->
                            @if (isNew === index && stores?.length) {
                            <div>
                                <div i18n="wizard$$add initial stock header@@add initial stock" class="small-header">Add initial stock:</div>
                                @if (coffeecopy.default_unit?.name) {
                                <mat-form-field class="amount-input-ff1">
                                    <mat-label i18n="generic$$Amount (of beans, e.g. in kg or bags)@@Amount">Amount</mat-label>
                                    <input [required]="!!store" matInput type="text" name="amount" class="amount-input"
                                        (change)="checkChangesUnits(this, 'amount', amount, $event?.target['value'], true)"
                                        [value]="amount | number:'1.0-3'">
                                    @if (coffeecopy.default_unit?.name) {
                                    @if (amount === 1) {
                                    <span>{{tr.anslate(coffeecopy.default_unit.name)}}</span>
                                    } @else {
                                    {{tr.anslate('plural_' + coffeecopy.default_unit.name)}}
                                    }
                                    }
                                </mat-form-field>
                                }
                                <mat-form-field class="amount-input-ff2">
                                    <mat-label i18n="transaction$$weight of beans in, e.g. kg@@Weight in {{mainUnit}}">Weight in {{mainUnit}}</mat-label>
                                    <input [required]="store && !coffeecopy.default_unit?.name" matInput
                                        type="text" name="unitamount" class="amount-input"
                                        (change)="checkChangesUnits(this, 'unitamount', unitamount, $event?.target['value'], false)"
                                        [value]="unitamount * utils.getUnitFactor(mainUnit) | number:'1.0-3'">{{mainUnit}}
                                </mat-form-field><br />
                                <mat-form-field class="amount-input-ff1">
                                    <mat-label i18n="generic$$price spent buying the beans@@total cost">Total cost</mat-label>
                                    <input required matInput name="price" class="amount-input"
                                        type="text" inputmode="numeric" pattern="[\-0-9,.]*" value="0"
                                        (change)="utils.checkChanges(this, 'price', price, $event?.target['value'], doSave)"
                                        [value]="price | currency:currency:'symbol-narrow':'1.0-2'">
                                    /{{unitamount * utils.getUnitFactor(mainUnit) | number:'1.0-3'}}{{mainUnit}}
                                </mat-form-field>
                                <mat-form-field class="amount-input-ff2">
                                    <mat-label i18n="generic$$price spent buying the beans per unit@@Cost per">Cost per</mat-label>
                                    <input required matInput name="pricePerUnit" class="amount-input"
                                        type="text" inputmode="numeric" pattern="[\-0-9,.]*" value="0"
                                        (change)="utils.checkChanges(this, 'pricePerUnit', pricePerUnit, $event?.target['value'], doSave)"
                                        [value]="pricePerUnit | currency:currency:'symbol-narrow':'1.2-2'">
                                    /{{mainUnitSingular}}<br />
                                </mat-form-field><br />
                                <mat-form-field>
                                    <mat-label i18n="generic$$storage location@@Store">Store</mat-label>
                                    <mat-select [required]="amount !== 0" matInput name="store"
                                        placeholder="Store" i18n-placeholder="generic$$storage location@@Store"
                                        panelClass="twoLineOption" panelWidth="null"
                                        [(ngModel)]="store" hideSingleSelectionIndicator="true"
                                        (selectionChange)="initialStockSet()">
                                        @if (store) {
                                        <mat-select-trigger class="twoLineOption ellipsed">
                                            <ng-container *ngTemplateOutlet="labelEntry; context: {obj: store}"></ng-container>
                                        </mat-select-trigger>
                                        }
                                        <mat-option [value]="null"></mat-option>
                                        @for (store of stores; track store?._id) {
                                        <mat-option [value]="store">
                                            <ng-container *ngTemplateOutlet="labelEntry; context: {obj: store}"></ng-container>
                                        </mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field><br />
                                @if (!suppliers) {
                                <div class="spinner-with-comment">
                                    <mat-spinner diameter="20"></mat-spinner> <span class="spinner-comment" i18n="beans$$currently loading list of suppliers@@loading suppliers">Loading suppliers ...</span>
                                </div>
                                }
                                <div class="flex-wrap">
                                    @if (suppliers && !addNewSupplierMode && !editNewSupplierMode) {
                                    <mat-form-field
                                        class="form-group left-large" subscriptSizing="dynamic">
                                        <mat-label i18n="contacts$$supplier@@Supplier">Supplier</mat-label>
                                        <mat-select [disabled]="!store || !unitamount" matInput name="suppliers"
                                            placeholder="Supplier" i18n-placeholder="contacts$$supplier@@Supplier"
                                            [(ngModel)]="supplier" [compareWith]="utils.compareObjectsFn"
                                            (selectionChange)="supplierChanged()" class="select-with-logo">
                                            @if (supplier?.image) {
                                            <mat-select-trigger style="display: flex; justify-content: space-between; align-items: center;">
                                                {{supplier.label}}
                                                <img class="dropdown-image" width="55px" height="35px"
                                                    [src]="'assets/images/suppliers/' + supplier.image + (isDarkmode ? '_dark' : '') + '_70x110.' + (supplier.useSVG ? 'svg' : 'png')"
                                                    onerror="this.style.display='none'"
                                                    (error)="imageLoadError(supplier)"
                                                    alt="logo of the supplier">
                                            </mat-select-trigger>
                                            }
                                            @if (!supplier?.image && supplier?.label) {
                                            <mat-select-trigger>
                                                {{supplier.label}}
                                            </mat-select-trigger>
                                            }
                                            <mat-option [value]="null"></mat-option>
                                            <mat-option value="######" class="highlight-add-option">
                                                @if (editingSupplier?.label && supplier?.toString() === '######') {
                                                <span>{{editingSupplier.label}}</span>
                                                } @else {
                                                <ng-container i18n="contacts$$add new supplier option@@add a new supplier">Add new Supplier</ng-container>
                                                }
                                            </mat-option>
                                            @for (supplier of suppliers; track supplier?.label) {
                                            <mat-option [value]="supplier">
                                                <!-- <span *ngIf="supplier.label">{{supplier.label}}</span>
                                        <span *ngIf="supplier?.location?.country"> ({{tr.anslate(supplier.location.country)}})</span> -->
                                                @if (supplier?.image) {
                                                <div class="dropdown-text-image">
                                                    @if (supplier.label) {
                                                    <span class="dropdown-text-image-label">{{supplier.label}}</span>
                                                    }
                                                    <img class="dropdown-image" width="55px" height="35px"
                                                        [src]="'assets/images/suppliers/' + supplier.image + (isDarkmode ? '_dark' : '') + '_70x110.' + (supplier.useSVG ? 'svg' : 'png')"
                                                        onerror="this.style.display='none'"
                                                        (error)="imageLoadError(supplier)"
                                                        alt="logo of the supplier">
                                                </div>
                                                }
                                                @if (!supplier?.image && supplier?.label) {
                                                <span>{{supplier.label}}</span>
                                                }
                                            </mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                    }
                                    @if (suppliers && !addNewSupplierMode && !editNewSupplierMode && supplier?.toString() === '######') {
                                    <button matButton type="button" class="smallButton right-button" i18n="generic$$edit some details, button@@edit" (click)="editSupplier()">Edit</button>
                                    }
                                </div>
                                @if (editingSupplier && suppliers && (addNewSupplierMode || editNewSupplierMode)) {
                                <div class="new-place-part">
                                    <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                        <mat-label i18n="generic$$Name@@Name">Name</mat-label>
                                        <input required matInput type="text" name="slabel"
                                            placeholder="Name" i18n-placeholder="generic$$Name@@Name"
                                            [(ngModel)]="editingSupplier.label">
                                    </mat-form-field>
                                    <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                        <mat-label i18n="contacts$$street@@street">Street</mat-label>
                                        <input matInput type="text" name="sstreet"
                                            placeholder="Street" i18n-placeholder="contacts$$street@@street"
                                            [(ngModel)]="editingSupplier.location.street">
                                    </mat-form-field><br />
                                    <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                        <mat-label i18n="contacts$$address@@Address">Address</mat-label>
                                        <input matInput type="text" name="saddress"
                                            placeholder="Address" i18n-placeholder="contacts$$address@@Address"
                                            [(ngModel)]="editingSupplier.location.address">
                                    </mat-form-field>
                                    <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                        <mat-label i18n="contacts$$Postal Code@@Postal Code">Postal Code</mat-label>
                                        <input matInput type="text" name="szip_code"
                                            placeholder="Postal Code" i18n-placeholder="contacts$$Postal Code@@Postal Code"
                                            [(ngModel)]="editingSupplier.location.zip_code">
                                    </mat-form-field><br />
                                    <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                        <mat-label i18n="contacts$$City@@city">City</mat-label>
                                        <input matInput type="text" name="scity"
                                            placeholder="City" i18n-placeholder="contacts$$City@@city"
                                            [(ngModel)]="editingSupplier.location.city">
                                    </mat-form-field>
                                    <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                        <mat-label i18n="contacts$$region@@region">Region</mat-label>
                                        <input matInput type="text" name="sregion"
                                            placeholder="Region" i18n-placeholder="contacts$$region@@region"
                                            [(ngModel)]="editingSupplier.location.region">
                                    </mat-form-field><br />
                                    <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                        <mat-label i18n="contacts$$subregion@@subregion">Subregion</mat-label>
                                        <input matInput type="text" name="ssubregion"
                                            placeholder="Subregion" i18n-placeholder="contacts$$subregion@@subregion"
                                            [(ngModel)]="editingSupplier.location.subregion">
                                    </mat-form-field>
                                    <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                        <mat-label i18n="contacts$$country@@Country">Country</mat-label>
                                        <input matInput type="text" name="scountry" id="scountry"
                                            placeholder="Country" i18n-placeholder="contacts$$country@@Country"
                                            (input)="changeCountryFilter('supplier', $event?.target['value'])"
                                            (focus)="changeCountryFilter('supplier', $event?.target['value'])"
                                            [ngModel]="editingSupplier.location.country" [matAutocomplete]="autoCompleteCountrySupp"
                                            (blur)="checkCountry('supplier', $event?.target['value'])">
                                        <mat-autocomplete #autoCompleteCountrySupp="matAutocomplete">
                                            @for (country of filteredCountriesSupp; track country) {
                                            <mat-option [value]="country">{{country}}</mat-option>
                                            }
                                        </mat-autocomplete>
                                    </mat-form-field><br />
                                    <!-- coordinates
                                <mat-form-field class="form-group" subscriptSizing="dynamic">
                                  <mat-label i18n="contacts$$street@@street">Street</mat-label>
                                  <input matInput i18n-placeholder="contacts$$street@@street" type="text" placeholder="Street" name="street" [(ngModel)]="editingProducer.location.street">
                                </mat-form-field><br/>
                                -->
                                    <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                        <mat-label i18n="contacts$$some user defined code for the contact@@code">Code</mat-label>
                                        <input matInput type="text" name="scode"
                                            placeholder="Code" i18n-placeholder="contacts$$some user defined code for the contact@@code"
                                            [(ngModel)]="editingSupplier.code">
                                    </mat-form-field><br />
                                    <mat-form-field class="form-group" subscriptSizing="dynamic">
                                        <mat-label i18n="contacts$$internet address@@Web">Web</mat-label>
                                        <input matInput type="text" name="sweb"
                                            placeholder="Web" i18n-placeholder="contacts$$internet address@@Web"
                                            [(ngModel)]="editingSupplier.web">
                                    </mat-form-field><br />
                                    @if (addNewSupplierMode || editNewSupplierMode) {
                                    <button matButton type="button" class="smallButton"
                                        i18n="generic$$close edit mode, button@@close edit mode"
                                        (click)="closeSupplier()">Close</button>
                                    }
                                </div>
                                }
                                @if (suppliers && (addNewSupplierMode || editNewSupplierMode)) {
                                <br />
                                }
                            </div>
                            }
                        </div>
                        <!-- additional properties (table) -->
                        <div class="tablediv">
                            <table class="proptable mat-elevation-z8">
                                <tbody>
                                    <tr class="inputrow">
                                        <td class="labelname" i18n-matTooltip="beans$$beans property ICO, tooltip@@ICO details" matTooltip="ICO Origin - Exporter - Lot"><span i18n="beans$$beans property ICO@@ICO">ICO</span></td>
                                        <td>
                                            <input name="ICO_origin" id="ICO_origin"
                                                type="number" inputmode="numeric" min="0" max="999"
                                                [(ngModel)]="coffeecopy.ICO.origin"
                                                (change)="ICO_origin_changed($event)"
                                                [matAutocomplete]="autoCompleteICO"
                                                class="thirdwidth" data-test="npt_ico_origin"> -
                                            <mat-autocomplete #autoCompleteICO="matAutocomplete" panelWidth="200px" (optionSelected)="ICO_origin_changed($event)">
                                                @for (option of icoOptions; track option?.label) {
                                                <mat-option value="{{option.value}}">
                                                    @if (option.label) {
                                                    <span>{{option.label}} ({{option.value}})</span>
                                                    }
                                                </mat-option>
                                                }
                                            </mat-autocomplete>
                                            <input name="ICO_exporter"
                                                type="text" pattern="[0-9]*" inputmode="numeric" min="0"
                                                [(ngModel)]="coffeecopy.ICO.exporter"
                                                (change)="coffeecopy.ICO.exporter = utils.clamp(0, undefined, 0)(coffeecopy.ICO.exporter)"
                                                class="thirdwidth" data-test="npt_ico_exporter"> -
                                            <input type="text" name="ICO_lot" inputmode="numeric" min="0"
                                                [(ngModel)]="coffeecopy.ICO.lot"
                                                (change)="coffeecopy.ICO.lot = utils.clamp(-1, undefined, 0)(coffeecopy.ICO.lot)"
                                                class="thirdwidth" data-test="npt_ico_lot">
                                        </td>
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname" i18n-matTooltip="beans$$altitude min/max explanation, tooltip@@altitude_minmax" matTooltip="Altitude min/max"><span i18n="beans$$beans property altitude@@altitude">Altitude</span></td>
                                        <td>
                                            <!-- <input class="halfwidth norightmargin" type="number" name="altitude_min" [(ngModel)]="coffeecopy.altitude.min">/<input class="halfwidth altitude_max" type="number" name="altitude_max" [(ngModel)]="coffeecopy.altitude.max"> -->
                                            <input type="text" inputmode="numeric" pattern="[0-9]*"
                                                name="altitude_min" data-test="npt_altitude_min"
                                                (change)="checkChanges('altitude.min', coffeecopy.altitude.min, $event?.target['value'], 3, utils.clamp(-999, 9999, 0))"
                                                [value]="coffeecopy.altitude.min | number:'1.0-0'"
                                                class="halfwidth norightmargin number-input">/<input type="text" inputmode="numeric" pattern="[0-9]*"
                                                name="altitude_max" data-test="npt_altitude_max"
                                                (change)="checkChanges('altitude.max', coffeecopy.altitude.max, $event?.target['value'], 3, utils.clamp(coffeecopy.altitude?.min ?? -999, 9999, 0))"
                                                [value]="coffeecopy.altitude.max | number:'1.0-0'"
                                                class="halfwidth norightmargin number-input" style="text-align: left">
                                            <a href="https://en.wikipedia.org/wiki/Metres_above_sea_level" class="littlespaceleft" target="_blank" rel="noopener">MASL</a>
                                        </td>
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname" i18n-matTooltip="beans$$screen size min/max explanation, tooltip@@screen_size_minmax" matTooltip="Screen min/max"><span i18n="beans$$beans property screen size@@screen_size">Screen</span></td>
                                        <td>
                                            <input name="screen_size_min" data-test="npt_screen_min"
                                                type="text" inputmode="numeric" min="0" max="30"
                                                (change)="checkChanges('screen_size.min', coffeecopy.screen_size.min, $event?.target['value'], 0, utils.clamp(0, 30, 0))"
                                                [value]="coffeecopy.screen_size.min | number:'1.0-0'"
                                                class="halfwidth norightmargin number-input">/<input name="screen_size_max" data-test="npt_screen_max"
                                                type="text" inputmode="numeric" [min]="coffeecopy.screen_size.min" max="30"
                                                (change)="checkChanges('screen_size.max', coffeecopy.screen_size.max, $event?.target['value'], 0, utils.clamp(coffeecopy.screen_size.min, 30, 0))"
                                                [value]="coffeecopy.screen_size.max | number:'1.0-0'"
                                                class="halfwidth norightmargin number-input" style="text-align: left">
                                            1/64"
                                        </td>
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$beans property score@@score">Score</span></td>
                                        <!-- <td><input #scoreInputElem type="number" name="score" [(ngModel)]="coffeecopy.score"></td> -->
                                        <td><input #scoreInputElem name="score" data-test="npt_score"
                                                type="text" inputmode="numeric" min="0" max="100"
                                                (change)="checkChanges('score', coffeecopy.score, $event?.target['value'], 1, utils.clamp(0, 100, 1))"
                                                [value]="coffeecopy.score | number:'1.0-1'"
                                                class="number-input"></td>
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$beans property grade@@grade">Grade</span></td>
                                        <td><input type="text" name="grade" [(ngModel)]="coffeecopy.grade" data-test="npt_grade"></td>
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$beans property species@@Species">Species</span></td>
                                        @if (properties?.['Species']; as options) {
                                        <td>
                                            <mat-select name="species" placeholder="" data-test="slct_species" panelWidth="null"
                                                class="beans-table-select" hideSingleSelectionIndicator="true"
                                                [(ngModel)]="coffeecopy.species" (selectionChange)="speciesSelected()">
                                                @for (species of options; track species?.label) {
                                                <mat-option value="{{species.value}}">{{species.label}}</mat-option>
                                                }
                                            </mat-select>
                                        </td>
                                        } @else {
                                        <td>
                                            <mat-spinner diameter="15" strokeWidth="1" class="centered"></mat-spinner>
                                        </td>
                                        }
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$beans property varietals@@Varietals">Varietals</span></td>
                                        @if (allVarietals) {
                                        <td>
                                            <mat-chip-grid #chipList>
                                                @for (varietal of varietals; track varietal) {
                                                <mat-chip-row [removable]="true" (removed)="removeVarietal(varietal)" data-test="chp_variety">
                                                    {{varietal}}
                                                    <button matChipRemove>
                                                        <mat-icon>close</mat-icon>
                                                    </button>
                                                    <!-- <mat-icon matChipRemove>close</mat-icon> -->
                                                </mat-chip-row>
                                                }
                                                <input #varietalInputElem type="text" data-test="npt_varietals"
                                                    [(ngModel)]="varietalInput" [matAutocomplete]="autoComplete"
                                                    name="varietals" id="varietals"
                                                    [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                    [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addVarietal($event)"
                                                    (input)="changeVarietalFilter()" (focus)="changeVarietalFilter()">
                                            </mat-chip-grid>
                                            <mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="varietalSelected($event)">
                                                @for (cat of allVarietalCategories; track cat; let catIdx = $index) {
                                                <!-- <ng-container *ngFor="let cat of allVarietalCategories; let catIdx=index"> -->
                                                @if (filteredVarietals[catIdx] && filteredVarietals[catIdx].length > 0) {
                                                <mat-optgroup [label]="cat">
                                                    <!-- <mat-option *ngFor="let varietal of filteredVarietals[catIdx]" value="{{varietal.variety}}"> -->
                                                    @for (varietal of filteredVarietals[catIdx]; track varietal?.variety) {
                                                    <mat-option value="{{varietal.variety}}">
                                                        {{varietal.variety}}@if (varietal.link !== '') {
                                                        <a href="{{varietal.link}}" target="_blank" rel="noopener">&nbsp;<mat-icon class="small">launch</mat-icon></a>
                                                        }
                                                        <!-- <span *ngIf="varietal.synonyms && varietal.synonyms.length > 0">&nbsp;({{varietal.synonyms.join(', ')}})</span> -->
                                                    </mat-option>
                                                    }
                                                </mat-optgroup>
                                                }
                                                <!-- </ng-container> -->
                                                }
                                            </mat-autocomplete>
                                        </td>
                                        } @else {
                                        <td>
                                            <mat-spinner diameter="15" strokeWidth="1" class="centered"></mat-spinner>
                                        </td>
                                        }
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$beans property cultivation@@cultivation">Cultivation</span></td>
                                        @if (getHierarchicalProperty('Cultivation'); as propertyHierarchy) {
                                        <td>
                                            <mat-select name="cultivation" placeholder="" data-test="slct_cultivation"
                                                class="beans-table-select" hideSingleSelectionIndicator="true" panelWidth="null"
                                                [(ngModel)]="coffeecopy.cultivation">
                                                <mat-option value=''></mat-option>
                                                @for (cat of propertyHierarchy.categories; track cat; let p = $index) {
                                                <mat-optgroup [label]="cat?.toLocaleUpperCase()">
                                                    @for (option of propertyHierarchy.options[p]; track option?.label) {
                                                    <mat-option value="{{option.value}}">{{option.label}}</mat-option>
                                                    }
                                                </mat-optgroup>
                                                }
                                            </mat-select>
                                        </td>
                                        } @else {
                                        <td>
                                            <mat-spinner diameter="15" strokeWidth="1" class="centered"></mat-spinner>
                                        </td>
                                        }
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$beans property harvesting@@harvesting">Harvesting</span></td>
                                        @if (properties?.['Harvesting']; as options) {
                                        <td>
                                            <mat-select name="harvesting" placeholder="" data-test="slct_harvesting"
                                                class="beans-table-select" hideSingleSelectionIndicator="true" panelWidth="null"
                                                [(ngModel)]="coffeecopy.harvesting">
                                                @for (option of options; track option?.label) {
                                                <mat-option value="{{option.value}}">{{option.label}}</mat-option>
                                                }
                                            </mat-select>
                                        </td>
                                        } @else {
                                        <td>
                                            <mat-spinner diameter="15" strokeWidth="1" class="centered"></mat-spinner>
                                        </td>
                                        }
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$beans property processing@@Processing">Processing</span></td>
                                        @if (getHierarchicalProperty('Processing'); as propertyHierarchy) {
                                        <td>
                                            <mat-select name="processing" placeholder="" data-test="slct_processing"
                                                class="beans-table-select" hideSingleSelectionIndicator="true" panelWidth="null"
                                                [(ngModel)]="coffeecopy.processing">
                                                <mat-option value=''></mat-option>
                                                @for (cat of propertyHierarchy.categories; track cat; let p = $index) {
                                                <mat-optgroup [label]="cat?.toUpperCase()">
                                                    @for (option of propertyHierarchy.options[p]; track option?.label) {
                                                    <mat-option value="{{option.value}}">{{option.label}}</mat-option>
                                                    }
                                                </mat-optgroup>
                                                }
                                            </mat-select>
                                        </td>
                                        } @else {
                                        <td>
                                            <mat-spinner diameter="15" strokeWidth="1" class="centered"></mat-spinner>
                                        </td>
                                        }
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$beans property fermentation@@fermentation">Fermentation</span></td>
                                        <td><input type="text" name="fermentation"
                                                data-test="npt_fermentation" class="beans-table-input"
                                                [(ngModel)]="coffeecopy.fermentation"></td>
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$beans property drying@@drying">Drying</span></td>
                                        @if (properties?.['Drying']; as options) {
                                        <td>
                                            <mat-select name="drying" placeholder="" data-test="npt_drying"
                                                class="beans-table-select" hideSingleSelectionIndicator="true" panelWidth="null"
                                                [(ngModel)]="coffeecopy.drying">
                                                @for (option of options; track option?.label) {
                                                <mat-option value="{{option.value}}">{{option.label}}</mat-option>
                                                }
                                            </mat-select>
                                        </td>
                                        } @else {
                                        <td>
                                            <mat-spinner diameter="15" strokeWidth="1" class="centered"></mat-spinner>
                                        </td>
                                        }
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$beans property decaffeination@@decaffeination">Decaffeination</span></td>
                                        @if (getHierarchicalProperty('Decaffeination'); as propertyHierarchy) {
                                        <td>
                                            <mat-select name="decaffeination" placeholder="" data-test="npt_decaffeination"
                                                class="beans-table-select" hideSingleSelectionIndicator="true" panelWidth="null"
                                                [(ngModel)]="coffeecopy.decaffeination">
                                                <mat-option value=''></mat-option>
                                                @for (cat of propertyHierarchy.categories; track cat; let p = $index) {
                                                <mat-optgroup [label]="cat?.toLocaleUpperCase()">
                                                    @for (option of propertyHierarchy.options[p]; track option?.label) {
                                                    <mat-option value="{{option.value}}">{{option.label}}</mat-option>
                                                    }
                                                </mat-optgroup>
                                                }
                                            </mat-select>
                                        </td>
                                        } @else {
                                        <td>
                                            <mat-spinner diameter="15" strokeWidth="1" class="centered"></mat-spinner>
                                        </td>
                                        }
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$beans property ageing@@ageing">Ageing</span></td>
                                        @if (properties?.['Ageing']; as options) {
                                        <td>
                                            <mat-select name="ageing" placeholder="" data-test="npt_ageing" panelWidth="null"
                                                class="beans-table-select" hideSingleSelectionIndicator="true"
                                                [(ngModel)]="coffeecopy.ageing">
                                                @for (option of options; track option?.label) {
                                                <mat-option value="{{option.value}}">{{option.label}}</mat-option>
                                                }
                                            </mat-select>
                                        </td>
                                        } @else {
                                        <td>
                                            <mat-spinner diameter="15" strokeWidth="1" class="centered"></mat-spinner>
                                        </td>
                                        }
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$beans property packaging@@packaging">Packaging</span></td>
                                        @if (properties?.['Packaging']; as options) {
                                        <td>
                                            <mat-select name="packaging" placeholder="" data-test="npt_packaging" panelWidth="null"
                                                class="beans-table-select" hideSingleSelectionIndicator="true"
                                                [(ngModel)]="coffeecopy.packaging">
                                                @for (option of options; track option?.label) {
                                                <mat-option value="{{option.value}}">{{option.label}}</mat-option>
                                                }
                                            </mat-select>
                                        </td>
                                        } @else {
                                        <td>
                                            <mat-spinner diameter="15" strokeWidth="1" class="centered"></mat-spinner>
                                        </td>
                                        }
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$beans property selection@@Selection">Selection</span></td>
                                        <td>
                                            <input matInput type="text" name="selection" id="Selection"
                                                data-test="npt_selection" class="beans-table-input"
                                                (input)="changeSelectionFilter()" (focus)="changeSelectionFilter()"
                                                [ngModel]="coffeecopy.selection === 'European Preparation' ? tr.anslate('European Preparation') : (coffeecopy.selection === 'American Preparation' ? tr.anslate('American Preparation') : coffeecopy.selection)" (ngModelChange)="coffeecopy.selection=$event" [matAutocomplete]="autoCompleteSelection">
                                            <mat-autocomplete #autoCompleteSelection="matAutocomplete">
                                                @for (option of filteredSelections; track option?.label) {
                                                <mat-option value="{{option.value}}">{{option.label === 'European Preparation' ? tr.anslate('European Preparation') : (option.label === 'American Preparation' ? tr.anslate('American Preparation') : option.label)}}</mat-option>
                                                }
                                            </mat-autocomplete>
                                        </td>
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$beans property density@@Density">Density</span></td>
                                        <td><input class="number-input" name="density" data-test="npt_density"
                                                type="text" inputmode="decimal" min="0" max="999"
                                                (change)="checkChanges('density', coffeecopy.density, $event?.target['value'], 3, utils.clamp(0, 999, 3))"
                                                [value]="coffeecopy.density | number:'1.0-3'"> g/l</td>
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$property Moisture@@Moisture">Moisture</span></td>
                                        <td><input class="number-input" name="moisture" data-test="npt_moisture"
                                                type="text" inputmode="decimal" min="0" max="100"
                                                (change)="checkChanges('moisture', coffeecopy.moisture, $event?.target['value'], 1, utils.clamp(0, 100, 1))"
                                                [value]="coffeecopy.moisture | number:'1.0-1'"> %</td>
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$beans property water activity@@water_activity">Water Activity</span></td>
                                        <td><input type="text" class="number-input" name="water_activity" data-test="npt_water"
                                                type="text" inputmode="decimal" min="0" max="100"
                                                (change)="checkChanges('water_activity', coffeecopy.water_activity, $event?.target['value'], 2, utils.clamp(0, 100, 2))"
                                                [value]="coffeecopy.water_activity | number:'1.0-2'"> aW</td>
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$beans property defects@@defects">Defects</span></td>
                                        <td class="number-dropdown">
                                            <input class="number-input" name="defects" data-test="npt_defects"
                                                type="text" inputmode="numeric" min="0" max="100"
                                                (change)="checkChanges('defects', coffeecopy.defects, $event?.target['value'], 1, utils.clamp(0, 100, 1))"
                                                [value]="coffeecopy.defects | number:'1.0-1'">
                                            <mat-select class="nexttonumber" name="defects_unit" data-test="slct_defects_unit"
                                                class="beans-table-select" hideSingleSelectionIndicator="true" panelWidth="null"
                                                [(ngModel)]="coffeecopy.defects_unit" [compareWith]="utils.compareIntStringFn">
                                                <!-- <mat-option><span>nothing selected</span></mat-option> -->
                                                <mat-option value="300">/ 300g</mat-option>
                                                <mat-option value="350">/ 350g</mat-option>
                                                <mat-option value="500">/ 500g</mat-option>
                                            </mat-select>
                                        </td>
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$beans property flavors@@flavors">Flavors</span></td>
                                        <td>
                                            <input type="text" name="flavors" [(ngModel)]="flavors"
                                                data-test="npt_flavors" class="beans-table-input">
                                            <!-- <mat-select multiple i18n-placeholder="coffee$$coffee property flavors@@flavors" placeholder="Flavors"
                                name="flavors" [(ngModel)]="coffeecopy.flavors">
                                <mat-option *ngFor="let option of ((props && props['Flavors']) || [])" value="{{option.value}}">{{option.label}}</mat-option>
                              </mat-select> -->
                                        </td>
                                    </tr>
                                    <tr class="inputrow">
                                        <td class="labelname"><span i18n="beans$$beans property crop yield@@crop_yield">Crop Yield</span></td>
                                        <td><input class="number-input" name="crop_yield" data-test="npt_crop_yield"
                                                type="text" inputmode="numeric" min="0"
                                                (change)="coffeecopy.crop_yield=utils.checkChangedValue(coffeecopy.crop_yield * utils.getUnitFactor(mainUnit), $event?.target['value'], 0).val / utils.getUnitFactor(mainUnit)"
                                                [value]="coffeecopy.crop_yield * utils.getUnitFactor(mainUnit) | number:'1.0-0'">&nbsp;{{mainUnit}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    }
                </form>
            </div>
            } @else {
            <div class="outerdiv">
                <div class="nottable">
                    @if (!readOnly) {
                    <app-ap-hide [objToHide]="coffee" objModelPath='coffees' (objHideChanged)="hiddenChanged($event)"></app-ap-hide>
                    }
                    <app-modification-date [element]="coffee" [user]="currentUser"></app-modification-date>
                    @if (coffee.imported) {
                    <div class="imported-source">
                        <span class="clipped-link-container" [ngClass]="{'clipped-link-container-small': !coffee.imported.source && coffee.imported.sourceLabel}">
                            <a [href]="coffee.imported.link" class="clipped-link" target="_blank" rel="noopener">{{coffee.imported.id?.trim()}}</a>
                        </span>
                        @if (coffee.imported.source) {
                        &#64;
                        <a [href]="coffee.imported.link" target="_blank" rel="noopener">
                            <img class="dropdown-image" width="55px" height="35px"
                                [src]="'assets/images/suppliers/' + coffee.imported.source + (isDarkmode ? '_dark' : '') + '_70x110.' + (coffee.imported.useSVG ? 'svg' : 'png')"
                                style="width: 55px;"
                                onerror="this.style.display='none'"
                                alt="logo of the supplier">
                        </a>
                        }
                        @if (!coffee.imported.source && coffee.imported.sourceLabel) {
                        &#64;
                        <a [href]="coffee.imported.link" target="_blank" rel="noopener"><span>{{coffee.imported.sourceLabel}}</span>
                        </a>
                        }
                    </div>
                    }
                    @if (coffee.regions?.length) {
                    <div>{{coffee.regions.join(', ')}}</div>
                    }
                    <!-- producer -->
                    @if (coffee.producer?.label) {
                    <div data-test="dv_coffee_producer">
                        <a [routerLink]="['/contacts', {id: coffee.producer._id}]">{{coffee.producer.label}}</a>@if (coffee.producer.web) {
                        <a href="{{coffee.producer.web}}" target="_blank" rel="noopener">&nbsp;<mat-icon class="small">launch</mat-icon></a>
                        }
                        @if (coffee.producer.location?.country) {
                        <span>&nbsp;(<span>{{tr.anslate(coffee.producer.location.country)}}</span>)
                        </span>
                        }
                    </div>
                    }
                    @if (coffee.default_unit?.name || (coffee.origin?.['country'] && coffee.origin['country'] !== coffee.origin['origin'])) {
                    <div>
                        @if (coffee.origin?.['country'] && (coffee.origin['country'] !== coffee.origin['label'])) {
                        <span class="head-origin">{{tr.anslate(coffee.origin['origin'])}}</span>
                        }
                        @if (coffee.default_unit?.name) {
                        @if (mainUnit === 'lbs' || mainUnit === 'lb' || mainUnit === 'oz') {
                        <span>{{coffee.default_unit.size | number:'1.0-0'}}kg ({{unitSize | number:'1.0-1'}}lb) {{translatedUnitName}}</span>
                        } @else {
                        <span>{{unitSize | number:'1.0-1'}}{{mainUnit}} {{translatedUnitName}}</span>
                        }
                        }
                    </div>
                    }
                    @if (coffee.ref_id) {
                    <div class="labelitem">
                        <span i18n="beans$$beans property lot@@lot" class="labelname">Lot</span>: {{coffee.ref_id}}
                    </div>
                    }
                    @if (coffee.certifications?.length) {
                    <div>
                        @for (cert of coffee.certifications; track cert?.abbrev; let c = $index) {
                        <span>
                            {{cert.abbrev ? cert.abbrev : cert.label}}&nbsp;@if (cert.web) {
                            <a href="{{cert.web}}" target="_blank" rel="noopener">
                                <mat-icon class="small">launch</mat-icon>
                            </a>
                            }
                            @if (coffee.certifications.length - 1 > c) {
                            <span>, </span>
                            }
                        </span>
                        }
                    </div>
                    }
                    @if (coffee.reference) {
                    <div class="labelitem">
                        @if (coffee.reference.indexOf('http') === 0) {
                        <span><a href="{{coffee.reference}}" target="_blank" rel="noopener">{{coffee.reference}}</a></span>
                        } @else {
                        {{coffee.reference}}
                        }
                    </div>
                    }
                    <!-- field (coffee.location) -->
                    <div data-test="dv_coffee_location">
                        @if (coffee.location?.label) {
                        <span>{{coffee.location.label}}</span>
                        }
                        @if (coffee.location?.street) {
                        <span>@if (coffee.location.label) {
                            <span>, </span>
                            }{{coffee.location.street}}</span>
                        }
                        @if (coffee.location?.address) {
                        <span>@if (coffee.location.label || coffee.location.street) {
                            <span>, </span>
                            }{{coffee.location.address}}</span>
                        }
                        @if (coffee.location?.zip_code) {
                        <span>@if (coffee.location.label || coffee.location.street || coffee.location.address) {
                            <span>, </span>
                            }{{coffee.location.zip_code}} </span>
                        }
                        @if (coffee.location?.city) {
                        <span>@if (!coffee.location.zip_code && (coffee.location.label || coffee.location.street || coffee.location.address)) {
                            <span>, </span>
                            }{{coffee.location.city}}</span>
                        }
                    </div>
                    <div data-test="dv_coffee_location_region_and_country">
                        @if (coffee.location?.subregion) {
                        <span>{{coffee.location.subregion}}</span>
                        }
                        @if (coffee.location?.region) {
                        <span>@if (coffee.location?.subregion) {
                            <span>, </span>
                            }{{coffee.location.region}}</span>
                        }
                        @if (coffee.location?.country) {
                        <span>@if (coffee.location?.subregion || coffee.location?.region) {
                            <span>, </span>
                            }{{tr.anslate(coffee.location.country)}}</span>
                        }
                    </div>
                    @if (curStocks?.length) {
                    <app-stock [currentUser]="currentUser" [stocks]="curStocks" [readOnly]="readOnly"></app-stock>
                    }
                    <app-store-transactions [currentUser]="currentUser" [store]="store" [coffee]="coffee" [readOnly]="readOnly"></app-store-transactions>
                </div>
                @if (hasAProperty()) {
                <div class="tablediv">
                    <table class="proptable mat-elevation-z8">
                        <tbody>
                            @if (coffee.ICO?.origin || coffee.ICO?.exporter || coffee.ICO?.lot) {
                            <tr (click)="edit()">
                                <td class="labelname" i18n-matTooltip="beans$beans property ICO, tooltip@@ICO details" matTooltip="ICO Origin - Exporter - Lot"><span i18n="beans$$beans property ICO@@ICO">ICO</span></td>
                                <td>
                                    @if (coffee.ICO.origin) {
                                    <span data-test="spn_ico_origin">{{coffee.ICO.origin}}</span>
                                    }
                                    @if (!coffee.ICO.origin) {
                                    <span data-test="spn_ico_origin">X</span>
                                    }
                                    @if (coffee.ICO.exporter) {
                                    <span data-test="spn_ico_exporter"> - {{coffee.ICO.exporter}}</span>
                                    }
                                    @if (!coffee.ICO.exporter) {
                                    <span data-test="spn_ico_exporter"> - X</span>
                                    }
                                    @if (coffee.ICO.lot) {
                                    <span data-test="spn_ico_lot"> - {{coffee.ICO.lot}}</span>
                                    }
                                    @if (!coffee.ICO.lot) {
                                    <span data-test="spn_ico_lot"> - X</span>
                                    }
                                </td>
                            </tr>
                            }
                            @if (coffee.crop_date?.picked?.length) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="beans$$beans property crop date - picked@@crop_date_picked">Picked</span></td>
                                <td data-test="td_picked">{{utils.getMonthStr(coffee.crop_date.picked[1])}} {{coffee.crop_date.picked[0]}}</td>
                            </tr>
                            }
                            @if (coffee.crop_date?.landed?.length) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="beans$$beans property crop date - landed@@crop_date_landed">Landed</span></td>
                                <td data-test="td_landed">{{utils.getMonthStr(coffee.crop_date.landed[1])}} {{coffee.crop_date.landed[0]}}</td>
                            </tr>
                            }
                            @if (coffee.altitude?.min || coffee.altitude?.max) {
                            <tr (click)="edit()">
                                <td class="labelname" i18n-matTooltip="beans$altitude min/max explanation, tooltip@@altitude_minmax" matTooltip="Altitude min/max"><span i18n="beans$$beans property altitude@@altitude">Altitude</span></td>
                                <td>
                                    @if (coffee.altitude?.min) {
                                    <span data-test="td_altitude_min">{{coffee.altitude.min}}</span>
                                    }
                                    @if (coffee.altitude?.max) {
                                    <span data-test="td_altitude_max">@if (coffee.altitude?.min) {
                                        <span>/</span>
                                        } @else {&lt;}{{coffee.altitude.max}}
                                    </span>
                                    } @else {<span class="coffee-screen-plus">+</span>}
                                    <a class="littlespaceleft" href="https://en.wikipedia.org/wiki/Metres_above_sea_level" target="_blank" rel="noopener">MASL</a>
                                </td>
                            </tr>
                            }
                            @if (coffee.screen_size && (coffee.screen_size.min || coffee.screen_size.max)) {
                            <tr (click)="edit()">
                                <td class="labelname" i18n-matTooltip="coffee@@screen_size_minmax" matTooltip="Screen min/max"><span i18n="beans$$beans property screen size@@screen_size">Screen</span></td>
                                <td>
                                    @if (coffee.screen_size.min) {
                                    <span data-test="td_screen_min">{{coffee.screen_size.min}}</span>
                                    }
                                    @if (coffee.screen_size.max) {
                                    <span data-test="td_screen_max">@if (coffee.screen_size.min) {
                                        <span>/</span>
                                        } @else {&lt;}{{coffee.screen_size.max}}
                                    </span>
                                    } @else {<span class="coffee-screen-plus">+</span>}
                                    1/64"
                                </td>
                            </tr>
                            }
                            @if (coffee.score) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="beans$$beans property score@@score">Score</span></td>
                                <td data-test="td_score">{{coffee.score | number:'1.0-3'}}</td>
                            </tr>
                            }
                            @if (coffee.grade) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="beans$$beans property grade@@grade">Grade</span></td>
                                <td data-test="td_grade">{{coffee.grade}}</td>
                            </tr>
                            }
                            @if (coffee.species) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="beans$$beans property species@@Species">Species</span></td>
                                <td data-test="td_species">{{coffee.species}}</td>
                            </tr>
                            }
                            @if (coffee.varietals?.length > 0 && coffee.varietals[0] !== '') {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="beans$$beans property varietals@@Varietals">Varietals</span></td>
                                <td data-test="td_varietals">{{coffee.varietals.join(', ')}}</td>
                            </tr>
                            }
                            @if (coffee.cultivation) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="beans$$beans property cultivation@@cultivation">Cultivation</span></td>
                                <td data-test="td_cultivation">
                                    @if (getLabelAndCat(coffee.cultivation, true); as lc) {
                                    {{lc.label}} @if (lc.cat) {
                                    <span>({{lc.cat}})</span>
                                    }
                                    }
                                </td>
                            </tr>
                            }
                            @if (coffee.harvesting) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="beans$$beans property harvesting@@harvesting">Harvesting</span></td>
                                <td data-test="td_harvesting">{{coffee.harvesting}}</td>
                            </tr>
                            }
                            @if (coffee.processing) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="beans$$beans property processing@@Processing">Processing</span></td>
                                <td data-test="td_processing">
                                    @if (getLabelAndCat(coffee.processing, false); as lc) {
                                    {{lc.label}} @if (lc.cat) {
                                    <span>({{lc.cat.toLowerCase()}})</span>
                                    }
                                    }
                                </td>
                            </tr>
                            }
                            @if (coffee.fermentation) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="beans$$beans property fermentation@@fermentation">Fermentation</span></td>
                                <td data-test="td_fermentation">{{coffee.fermentation}}</td>
                            </tr>
                            }
                            @if (coffee.drying) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="beans$$beans property drying@@drying">Drying</span></td>
                                <td data-test="td_drying">{{coffee.drying}}</td>
                            </tr>
                            }
                            @if (coffee.decaffeination) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="beans$$beans property decaffeination@@decaffeination">Decaffeination</span></td>
                                <td data-test="td_decaffeination">
                                    @if (getLabelAndCat(coffee.decaffeination, true); as lc) {
                                    {{lc.label}} @if (lc.cat) {
                                    <span>({{lc.cat.toLowerCase()}})</span>
                                    }
                                    }
                                </td>
                            </tr>
                            }
                            @if (coffee.ageing) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="beans$$beans property ageing@@ageing">Ageing</span></td>
                                <td data-test="td_ageing">{{coffee.ageing}}</td>
                            </tr>
                            }
                            @if (coffee.packaging) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="beans$$beans property packaging@@packaging">Packaging</span></td>
                                <td data-test="td_packaging">{{coffee.packaging}}</td>
                            </tr>
                            }
                            @if (coffee.selection) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="beans$$beans property selection@@Selection">Selection</span></td>
                                <td data-test="td_selection">{{coffee.selection === 'European Preparation' ? tr.anslate('European Preparation') : (coffee.selection === 'American Preparation' ? tr.anslate('American Preparation') : coffee.selection)}}</td>
                            </tr>
                            }
                            @if (coffee.density) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="beans$$beans property density@@Density">Density</span></td>
                                <td data-test="td_density">{{coffee.density | number:'1.0-3'}}g/l</td>
                            </tr>
                            }
                            @if (coffee.moisture) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="beans$$property Moisture@@Moisture">Moisture</span></td>
                                <td data-test="td_moisture">{{coffee.moisture | number:'1.0-3'}}%</td>
                            </tr>
                            }
                            @if (coffee.water_activity) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="coffee$$beans property water activity@@water_activity">Water Activity</span></td>
                                <td data-test="td_water_activity">{{coffee.water_activity | number:'1.0-3'}}aW</td>
                            </tr>
                            }
                            @if (coffee.defects) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="coffee$$beans property defects@@defects">Defects</span></td>
                                <td data-test="td_defects">{{coffee.defects | number:'1.0-3'}} @if (coffee.defects_unit) {
                                    <span ng18n="coffee@defects per ...g" data-test="spn_defects_unit">per {{coffee.defects_unit}}g</span>
                                    }
                                </td>
                            </tr>
                            }
                            @if (coffee.flavors?.length > 0 && coffee.flavors[0] !== '') {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="coffee$$beans property flavors@@flavors">Flavors</span></td>
                                <td data-test="td_flavors">{{coffee.flavors.join(', ')}}</td>
                            </tr>
                            }
                            @if (coffee.crop_yield) {
                            <tr (click)="edit()">
                                <td class="labelname"><span i18n="coffee$$beans property crop yield@@crop_yield">Crop Yield</span></td>
                                <td data-test="td_crop_yield">{{getAmount(coffee.crop_yield)}}</td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                }
                <div style="min-width: 200px;">
                    @if (coffee.picture) {
                    <img [src]="coffee.picture" class="coffee-image" role="button" [tabindex]="0"
                        (click)="addImage()" (keydown.enter)="addImage(); $event.preventDefault()"
                        alt="probably a picture of the coffee">
                    }
                </div>
                <div style="width: 100%">
                    <app-ap-file [files]="coffee.files" [belongsTo]="coffee.internal_hr_id" belongsToType="COFFEE"
                        (filesChanged)="filesChanged($event)" [readOnly]="readOnly"></app-ap-file>
                </div>
                <mat-form-field class="notesdiv no-height-change" subscriptSizing="dynamic">
                    <mat-label i18n="generic$$arbitrary notes@@Notes">Notes</mat-label>
                    <textarea [disabled]="readOnly" matInput name="notes"
                        [(ngModel)]="notes" (blur)="saveNotes()" (ngModelChange)="notesChanged.next($event)"
                        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5">
                    </textarea>
                </mat-form-field>
                @if (pbShareLink) {
                <div class="share-pb">
                    <span i18n="generic$$Share beans info@@Share beans info" data-test="spn_share_beans">Share beans info</span>:
                    @if (!justCopied && !copyFailed) {
                    <button mat-icon-button class="copy-button-button primary-element" type="button" (click)="copyLink()" data-test="lnk_copy">
                        <mat-icon matTooltip="Copy to clipboard"
                            i18n-matTooltip="tooltip$$Copy to clipboard@@Copy to clipboard" class="copy-button-icon">content_copy</mat-icon>
                    </button>
                    }
                    @if (justCopied) {
                    <button mat-icon-button type="button" disabled class="copy-button-button">
                        <mat-icon class="overview-copied copy-button-icon" style="font-size: 18px;">done</mat-icon>
                    </button>
                    }
                    @if (copyFailed) {
                    <button mat-icon-button type="button" disabled class="copy-button-button">
                        <mat-icon class="overview-copyFailed copy-button-icon" style="font-size: 18px;">error_outline</mat-icon>
                    </button>
                    }
                    <a [routerLink]="['/add']" [queryParams]="{data: pbShareLink, returnUrl: '/coffees;id=' + coffee.hr_id}" style="margin-left: 10px;" data-test="lnk_pbsharelink"><mat-icon class="overview-close-icon" matTooltip="See what will be shared" i18n-matTooltip="tooltip$$See what will be shared@@See what will be shared">info</mat-icon></a>
                    <!-- <mat-icon class="small-close-icon">search_check</mat-icon><mat-icon class="small-close-icon">frame_inspect</mat-icon><mat-icon class="small-close-icon">conditions</mat-icon><mat-icon class="small-close-icon">data_info_alert</mat-icon> -->
                </div>
                }
            </div>
            }
        </ng-template>
        @if (!readOnly && (editMode < 0 || editMode !==index)) {
            <mat-action-row>
            @if (!dontShowCloneDelete) {
            <button [disabled]="editMode >= 0"
                (click)="clone()" mat-raised-button type="button" class="action-button clone-button"
                matTooltip="Create a copy" i18n-matTooltip="generic$$create a copy (clone), button@@clone">
                <mat-icon>content_copy</mat-icon>
            </button>
            @if (!checkingIfDeletable) {
            <button [disabled]="editMode >= 0"
                (click)="delete()" mat-raised-button type="button" class="edit-button">
                <mat-icon>delete</mat-icon>
            </button>
            } @else {
            <button disabled mat-raised-button class="action-button spinner-button">
                <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
            </button>
            }
            }
            <button [disabled]="editMode >= 0"
                (click)="edit()" mat-raised-button type="button" class="action-button">
                <mat-icon>edit</mat-icon>
            </button>
            </mat-action-row>
            }
            @if (!readOnly && editMode === index) {
            <mat-action-row [ngClass]="{'separate-buttons': (coffeecopy?.internal_hr_id || coffeecopy?._id)}">
                @if (coffeecopy?.internal_hr_id || coffeecopy?._id) {
                <button mat-raised-button type="button" class="action-button otherside-button" (click)="addImage()">
                    <mat-icon>{{coffeecopy?.picture ? 'insert_photo' : 'add_photo_alternate'}}</mat-icon>
                </button>
                }
                <div>
                    <button mat-button type="button" class="cancel-button" (click)="cancel()">
                        <mat-icon>close</mat-icon>
                    </button>
                    @if (!isSaving) {
                    <button [disabled]="isSaving" mat-raised-button type="submit" class="action-button" form="form" data-test="bttn_done">
                        <mat-icon>done</mat-icon>
                    </button>
                    } @else {
                    <button disabled mat-raised-button class="action-button spinner-button">
                        <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
                    </button>
                    }
                </div>
            </mat-action-row>
            }
    </mat-expansion-panel>
</span>
}