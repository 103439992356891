<ng-template #placeEntry let-obj="obj">
    @if (obj?.hr_id) {
    <div class="smallerfont">{{obj.hr_id}}</div>
    }
    @if (obj?.label) {
    <div>
        {{obj.label}}
        @if (obj.country) {
        ({{tr.anslate(obj.country)}})
        }
    </div>
    }
</ng-template>

@if (!contacts) {
<div class="wait-outer">
    <mat-spinner class="spinner"></mat-spinner>
</div>
}

@if (!readOnly) {
<div class="actionbar">
    <div class="actionbar-inner">
        @if (contacts) {
        <button [disabled]="editMode >= 0" data-test="bttn_addSupplier"
            (click)="add(LocTypes.SUPPLIER)" mat-raised-button type="button" class="action-button">
            <mat-icon>add</mat-icon> <span i18n="contacts$$supplier@@Supplier">Supplier</span>
        </button>
        <button [disabled]="editMode >= 0" data-test="bttn_addProducer"
            (click)="add(LocTypes.PRODUCER)" mat-raised-button type="button" class="action-button">
            <mat-icon>add</mat-icon> <span i18n="contacts$$producer@@producer">Producer</span>
        </button>
        <button [disabled]="editMode >= 0" data-test="bttn_addCustomer"
            (click)="add(LocTypes.CUSTOMER)" mat-raised-button type="button" class="action-button">
            <mat-icon>add</mat-icon> <span i18n="contacts$$customer@@Customer">Customer</span>
        </button>
        }
    </div>
</div>
}

@if (readOnly) {
<div class="readonly-spacer"></div>
}

@if (contacts) {
<div>
    @for (cType of getTypes(); track cType) {
    <div>
        @if (!readOnly || (contacts[cType] && contacts[cType].length > 0)) {
        <h2 data-test="hdr_contacts" class="main-heading">{{getTypeString(cType)}}</h2>
        @if (cType === LocTypes.SUPPLIER && supplierPartners?.length) {
        <app-supplierpartners
            [supPartners]="supplierPartners" [activeLabel]=scrollToSupPartner></app-supplierpartners>
        }
        <!-- no contacts of this type => show label and specific add button -->
        @if (contacts[cType] && contacts[cType].length === 0) {
        <div class="main-note">
            @if (cType === LocTypes.PRODUCER) {
            <button [disabled]="editMode >= 0" class="action-button"
                (click)="add(LocTypes.PRODUCER)" data-test="bttn_addFirstProducer" mat-raised-button type="button">
                <mat-icon>add</mat-icon> <span i18n="contacts$$producer@@producer">Producer</span>
            </button>
            } @else if (cType === LocTypes.SUPPLIER) {
            <button [disabled]="editMode >= 0" class="action-button"
                (click)="add(LocTypes.SUPPLIER)" data-test="bttn_addFirstSupplier" mat-raised-button type="button">
                <mat-icon>add</mat-icon> <span i18n="contacts$$supplier@@Supplier">Supplier</span>
            </button>
            } @else if (cType === LocTypes.CUSTOMER) {
            <button [disabled]="editMode >= 0" class="action-button"
                (click)="add(LocTypes.CUSTOMER)" data-test="bttn_addFirstCustomer" mat-raised-button type="button">
                <mat-icon>add</mat-icon> <span i18n="contacts$$customer@@Customer">Customer</span>
            </button>
            }
            <!-- <p i18n="generic$$no items found@@nothing to show">Currently, there is nothing to show.</p> -->
        </div>
        }
        <div>
            @for (contact of contacts[cType]; track contact?._id) {
            <mat-expansion-panel #expPanel
                class="contact-card" [ngClass]="{'wide-contact-card': editMode === cType*10000+$index}"
                hideToggle="true">
                <!-- [expanded]="isExpanded" (closed)="panel(false)" (opened)="panel(true)"> -->
                <mat-expansion-panel-header expandedHeight="auto" collapsedHeight="auto">
                    <mat-panel-title class="contact-paneltitle">
                        @if (contact._id) {
                        <span class="contact-avatar">
                            @if (contact?.logo) {
                            <img class="avatar-icon" src="{{contact.logo}}" (click)="changeAvatar(cType, $index); $event.stopPropagation()" (keydown.enter)="changeAvatar(cType, $index); $event.stopPropagation(); $event.preventDefault()" role="button" [tabindex]="0" onerror="this.src='assets/icons/main/contact.svg'; this.style.width='32px';this.style.height='32px'" alt="a small picture of the contact">
                            } @else {
                            <mat-icon class="avatar-icon-generic" [svgIcon]="'contact' + (isDarkmode ? '-dark' : '')" (click)="changeAvatar(cType, $index); $event.stopPropagation()" (keydown.enter)="changeAvatar(cType, $index); $event.stopPropagation(); $event.preventDefault()" role="button" [tabindex]="0"></mat-icon>
                            }
                        </span>
                        }
                        <span class="contact-title">
                            <div class="contact-title-label" data-test="dv_contact_title_label">
                                @if (editMode !== cType*10000+$index) {
                                <span #contactLabel>{{contact.label}}</span>
                                } @else {
                                <span #contactLabel>{{contactcopy.label}}</span>
                                }
                                @if (contact?.web) {
                                <a href="{{contact.web}}" class="contact-title-link"
                                    data-test="lnk_contactWeb" target="_blank" rel="noopener"
                                    (click)="$event.stopPropagation()" (keydown.enter)="$event.stopPropagation(); $event.preventDefault()" role="button" [tabindex]="0">
                                    <mat-icon class="small">launch</mat-icon>
                                </a>
                                }
                            </div>
                            <div class="mat-card-subtitle contact-subtitle">
                                @if (editMode !== cType*10000+$index && contact?.code) {
                                <span>{{contact.code}}</span>
                                }
                                @if (editMode === cType*10000+$index && contactcopy?.code) {
                                <span>{{contactcopy.code}}</span>
                                }
                                @if ((editMode !== cType*10000+$index && contact?.code || editMode === cType*10000+$index && contactcopy?.code) && (editMode === cType*10000+$index && contactcopy?.['floId'] || editMode !== cType*10000+$index && contact?.['floId'])) {
                                <span>, </span>
                                }
                                @if (editMode === cType*10000+$index && contactcopy?.['floId']) {
                                <span>FLO ID <a (click)="searchFloId(cType, $index, '', contactcopy?.['floId']); $event.stopPropagation()" (keydown.enter)="searchFloId(cType, $index, '', contactcopy?.['floId']); $event.stopPropagation(); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer">#{{contactcopy['floId']}}</a></span>
                                }
                                @if (editMode !== cType*10000+$index && contact?.['floId']) {
                                <span>FLO ID <a (click)="searchFloId(cType, $index, '', contact?.['floId']); $event.stopPropagation()" (keydown.enter)="searchFloId(cType, $index, '', contact?.['floId']); $event.stopPropagation(); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer">#{{contact['floId']}}</a></span>
                                }
                                @if (loadingFloId === cType*10000+$index) {
                                <mat-spinner diameter="14" class="littlespaceleft" style="display: inline;"></mat-spinner>
                                }
                            </div>
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    @if (!readOnly && editMode === cType*10000+$index) {
                    <div>
                        <form id="form" name="form" (ngSubmit)="mainForm.form.valid && (!editPlaceMode || (editPlaceMode && editingLocation?.label)) && save(cType, $index)" #mainForm="ngForm">
                            <mat-form-field class="form-group" subscriptSizing="dynamic">
                                <mat-label i18n="generic$$Name@@Name">Name</mat-label>
                                <input required matInput type="text" name="label" data-test="npt_name"
                                    placeholder="Name" i18n-placeholder="generic$$Name@@Name"
                                    [(ngModel)]="contactcopy.label">
                            </mat-form-field><br />
                            <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                <mat-label i18n="contacts$$some user defined code for the contact@@code">Code</mat-label>
                                <input matInput type="text" name="code" data-test="npt_code"
                                    placeholder="Code" i18n-placeholder="contacts$$some user defined code for the contact@@code"
                                    [(ngModel)]="contactcopy.code">
                            </mat-form-field>
                            @if (cType === LocTypes.SUPPLIER || cType === LocTypes.PRODUCER) {
                            <mat-form-field class="form-group halfsizeright-smaller" subscriptSizing="dynamic">
                                <mat-label>FLO ID</mat-label>
                                <input matInput type="text" name="floId" data-test="npt_floId"
                                    placeholder="FLO ID"
                                    [(ngModel)]="contactcopy['floId']">
                            </mat-form-field>
                            }
                            @if (loadingFloId !== cType*10000+$index && (contactcopy['floId'] || contactcopy.label?.length >= 3)) {
                            <mat-icon data-test="cn_searchFloId"
                                style="vertical-align: middle; margin-left: 5px;" class="primary-element cursor-pointer"
                                (click)="searchFloId(cType, $index, contactcopy.label, contactcopy['floId'])">search</mat-icon>
                            }
                            @if (loadingFloId === cType*10000+$index) {
                            <mat-spinner diameter="14" class="littlespaceleft" style="display: inline;"></mat-spinner>
                            }
                            @if (!getPlaces(cType)) {
                            <div class="spinner-with-comment">
                                <mat-spinner data-test="spnnr_loadPlaces" diameter="20"></mat-spinner> <span class="spinner-comment" i18n="contacts$$loading already defined locations@@loading fields">Loading places ...</span>
                            </div>
                            }
                            <div class="flex-wrap">
                                @if (getPlaces(cType) && !editPlaceMode) {
                                <mat-form-field data-test="slct_place" class="form-group left-large" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$location, placeholder@@place">Place</mat-label>
                                    <mat-select name="place" hideSingleSelectionIndicator="true"
                                        placeholder="Place" i18n-placeholder="contacts$$location, placeholder@@place"
                                        [(ngModel)]="contactcopy.location" [compareWith]="utils.compareObjectsFn"
                                        (selectionChange)="placeChanged(cType)">
                                        <mat-select-trigger class="twoLineOption ellipsed">
                                            @if (contactcopy.location?.toString() === '######') {
                                            <span>{{editingLocation.label}} @if (editingLocation.country) {
                                                <span>({{editingLocation.country}})</span>
                                                }</span>
                                            } @else {
                                            <ng-container *ngTemplateOutlet="placeEntry; context: {obj: contactcopy.location}"></ng-container>
                                            }
                                        </mat-select-trigger>
                                        <mat-option [value]="null"></mat-option>
                                        <mat-option value="######" class="highlight-add-option">
                                            @if (newLocation && editingLocation) {
                                            <span>{{editingLocation.label}} @if (editingLocation.country) {
                                                <span>({{tr.anslate(editingLocation.country)}})</span>
                                                }</span>
                                            } @else {
                                            <ng-container i18n="contacts$$add a new location, option@@add a new place">Add new place</ng-container>
                                            }
                                        </mat-option>
                                        @for (place of getPlaces(cType); track place?._id) {
                                        <mat-option [value]="place">
                                            <ng-container *ngTemplateOutlet="placeEntry; context: {obj: place}"></ng-container>
                                        </mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                                }
                                @if (!editPlaceMode && contactcopy.location) {
                                <button data-test="bttn_editPlace" mat-raised-button type="button"
                                    (click)="editPlace()" class="discardButton right-button" i18n="generic$$edit some details, button@@edit">Edit</button>
                                }
                            </div>
                            <!-- <br *ngIf="getPlaces(cType) && !editPlaceMode"/> -->
                            @if (editPlaceMode && editingLocation) {
                            <div>
                                <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                    <mat-label i18n="generic$$Name@@Name">Name</mat-label>
                                    <input required matInput type="text" name="placelabel" data-test="npt_placeName"
                                        placeholder="Name" i18n-placeholder="generic$$Name@@Name"
                                        [(ngModel)]="editingLocation.label">
                                </mat-form-field>
                                <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$street@@street">Street</mat-label>
                                    <input matInput type="text" name="street" data-test="npt_placeStreet"
                                        placeholder="Street" i18n-placeholder="contacts$$street@@street"
                                        [(ngModel)]="editingLocation.street">
                                </mat-form-field><br />
                                <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$address@@Address">Address</mat-label>
                                    <input matInput type="text" name="address" data-test="npt_placeAddress"
                                        placeholder="Address" i18n-placeholder="contacts$$address@@Address"
                                        [(ngModel)]="editingLocation.address">
                                </mat-form-field>
                                <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$Postal Code@@Postal Code">Postal Code</mat-label>
                                    <input matInput type="text" name="zip_code" data-test="npt_placePostalCode"
                                        placeholder="Postal Code" i18n-placeholder="contacts$$Postal Code@@Postal Code"
                                        [(ngModel)]="editingLocation.zip_code">
                                </mat-form-field><br />
                                <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$City@@city">City</mat-label>
                                    <input matInput type="text" name="city" data-test="npt_placeCity"
                                        placeholder="City" i18n-placeholder="contacts$$City@@city"
                                        [(ngModel)]="editingLocation.city">
                                </mat-form-field>
                                <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$region@@region">Region</mat-label>
                                    <input matInput type="text" name="region" data-test="npt_placeRegion"
                                        placeholder="Region" i18n-placeholder="contacts$$region@@region"
                                        [(ngModel)]="editingLocation.region">
                                </mat-form-field><br />
                                <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$subregion@@subregion">Subregion</mat-label>
                                    <input matInput type="text" name="subregion" data-test="npt_placeSubregion"
                                        placeholder="Subregion" i18n-placeholder="contacts$$subregion@@subregion"
                                        [(ngModel)]="editingLocation.subregion">
                                </mat-form-field>
                                <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$country@@Country">Country</mat-label>
                                    <input matInput type="text" name="country" id="country" data-test="npt_placeCountry"
                                        placeholder="Country" i18n-placeholder="contacts$$country@@Country"
                                        (input)="changeCountryFilter($event.target['value'])"
                                        (focus)="changeCountryFilter($event.target['value'])"
                                        [ngModel]="editingLocation.country"
                                        [matAutocomplete]="autoCompleteCountryPlace"
                                        (blur)="checkCountry($event.target['value'])">
                                    <mat-autocomplete #autoCompleteCountryPlace="matAutocomplete">
                                        @for (country of filteredCountries; track country) {
                                        <mat-option [value]="country">{{country}}</mat-option>
                                        }
                                    </mat-autocomplete>
                                </mat-form-field><br />
                                <!-- coordinates -->
                                <!-- <mat-form-field class="form-group">
                            <mat-label i18n="contacts$$street@@street">Street</mat-label>
                            <input matInput i18n-placeholder="contacts$$street@@street" type="text" placeholder="Street" name="street" [(ngModel)]="editingLocation.street">
                          </mat-form-field><br/> -->
                                @if (!floIdEdit) {
                                <button mat-raised-button type="button" class="discardButton" data-test="bttn_close"
                                    i18n="contacts$$go back to select-location mode, button@@close edit place mode"
                                    (click)="closePlace(cType)">Close</button>
                                }
                            </div>
                            }
                            @if (cType === LocTypes.PRODUCER) {
                            <div>
                                <mat-form-field data-test="slct_orgForm" class="form-group halfsizeleft" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$org form e.g. single / cooperation, placeholder@@organizational_form">Organizational form</mat-label>
                                    <mat-select name="organizational_form"
                                        panelWidth="null" hideSingleSelectionIndicator="true"
                                        placeholder="Organizational form" i18n-placeholder="contacts$$org form e.g. single / cooperation, placeholder@@organizational_form"
                                        [(ngModel)]="contactcopy['organizational_form']">
                                        @for (oform of orgforms; track oform?.label) {
                                        <mat-option [value]="oform.value">{{oform.label}}</mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                    <mat-label i18n="contacts$$farm owner name@@farmowner">Farm owner</mat-label>
                                    <input matInput type="text" name="farmowner" data-test="npt_farmOwner"
                                        placeholder="Farm owner" i18n-placeholder="contacts$$farm owner name@@farmowner"
                                        [(ngModel)]="contactcopy['farmowner']">
                                </mat-form-field>
                                <br />
                            </div>
                            }
                            <mat-form-field class="form-group wide-form-field" subscriptSizing="dynamic">
                                <mat-label i18n="contacts$$internet address@@Web">Web</mat-label>
                                <input matInput type="url" name="web" data-test="npt_web"
                                    placeholder="Web" i18n-placeholder="contacts$$internet address@@Web"
                                    [(ngModel)]="contactcopy.web"
                                    (blur)="checkLink(contactcopy)">
                            </mat-form-field><br />
                            <mat-form-field class="form-group halfsizeleft" subscriptSizing="dynamic">
                                <mat-label i18n="contacts$$Phone@@Phone">Phone</mat-label>
                                <input matInput type="tel" name="phone" data-test="npt_phone"
                                    placeholder="Phone" i18n-placeholder="contacts$$Phone@@Phone"
                                    [(ngModel)]="contactcopy.phone">
                            </mat-form-field>
                            <mat-form-field class="form-group halfsizeright" subscriptSizing="dynamic">
                                <mat-label i18n="users$$email address@@email">Email</mat-label>
                                <input matInput type="email" name="email" data-test="npt_email"
                                    placeholder="Email" i18n-placeholder="users$$email address@@email"
                                    [(ngModel)]="contactcopy.email">
                            </mat-form-field><br />
                            <mat-form-field class="form-group wide-form-field" subscriptSizing="dynamic">
                                <mat-label i18n="generic$$arbitrary notes@@Notes">Notes</mat-label>
                                <input type="text" name="notes" data-test="npt_notes" matInput
                                    placeholder="Notes" i18n-placeholder="generic$$arbitrary notes@@Notes"
                                    [(ngModel)]="contactcopy.notes">
                            </mat-form-field><br />
                            @if (contactcopy.image) {
                            <img data-test="img_contact" [src]="contactcopy.image" class="contact-image" (click)="addImage(cType, $index)" (keydown.enter)="addImage(cType, $index); $event.preventDefault()" role="button" [tabindex]="0" alt="" a picture of the contact>
                            }
                            <br />
                            <div class="inner-action-row" [ngClass]="{'separate-buttons': contactcopy._id}">
                                @if (contactcopy._id) {
                                <button data-test="bttn_image" mat-raised-button type="button"
                                    (click)="addImage(cType, $index)" class="action-button otherside-button">
                                    <mat-icon>{{contactcopy?.image ? 'insert_photo' : 'add_photo_alternate'}}</mat-icon>
                                </button>
                                }
                                <div style="margin-right: -5px;">
                                    <button (click)="cancel()" data-test="bttn_cancel" mat-button type="button" class="cancel-button">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    @if (!isSaving) {
                                    <button [disabled]="isSaving" data-test="bttn_done" mat-raised-button
                                        type="submit" class="action-button" form="form" data-test="bttn_done">
                                        <mat-icon>done</mat-icon>
                                    </button>
                                    } @else {
                                    <button disabled data-test="spnnr_whileSaving" mat-raised-button class="action-button spinner-button">
                                        <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
                                    </button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                    } @else {
                    <app-modification-date [element]="contact" [user]="currentUser"></app-modification-date>
                    @if (contact?.['organizational_form']) {
                    <div data-test="dv_organizational_form">{{tr.anslate(contact['organizational_form'])}}</div>
                    }
                    @if (contact?.['farmowner']) {
                    <div data-test="dv_farmowner">{{contact['farmowner']}}</div>
                    }
                    <div data-test="dv_whole_address">
                        @if (contact?.location?.label && contact.location.label !== contact.label) {
                        {{contact.location.label}}<br />
                        }
                        @if (contact?.location?.street) {
                        {{contact.location.street}}<br />
                        }
                        @if (contact?.location?.address) {
                        {{contact.location.address}}<br />
                        }
                        @if (contact?.location?.zip_code) {
                        {{contact.location.zip_code}}
                        }
                        @if (contact?.location?.city) {
                        {{contact.location.city}}
                        }
                    </div>
                    <div data-test="dv_region_and_country">
                        @if (contact?.location?.subregion) {
                        <span>{{contact.location.subregion}}</span>
                        }
                        @if (contact?.location?.region) {
                        <span>@if (contact?.location?.subregion) {
                            <span>, </span>
                            }{{contact.location.region}}</span>
                        }
                        @if (contact?.location?.country) {
                        <span>@if (contact?.location?.subregion || contact?.location?.region) {
                            <span>, </span>
                            }{{tr.anslate(contact.location.country)}}</span>
                        }
                    </div>
                    @if (contact?.email?.indexOf) {
                    <div data-test="dv_email">
                        @if (contact.email.indexOf('@') > 0) {
                        <a href="mailto:{{contact.email}}">{{contact.email}}</a>
                        } @else {
                        <span i18n="users$$email address@@email">Email</span>: {{contact.email}}
                        }
                    </div>
                    }
                    @if (formatPhone(contact?.phone); as fphone) {
                    <div><a [href]="'tel:' + fphone">{{contact.phone}}</a></div>
                    }
                    @if (contact?.notes) {
                    <div><i>{{contact.notes}}</i></div>
                    }
                    <div>
                        @if (contact?.image) {
                        <img [src]="contact.image" class="contact-image" (click)="addImage(cType, $index)" (keydown.enter)="addImage(cType, $index); $event.preventDefault()" role="button" [tabindex]="0" alt="a picture of the contact">
                        }
                    </div>
                    <app-ap-file
                        [files]="contact?.files"
                        [belongsTo]="contact?._id"
                        belongsToType="CONTACTS"
                        (filesChanged)="filesChanged(cType, $index, $event)"
                        [readOnly]="readOnly">
                    </app-ap-file>
                    }
                    @if (!readOnly && (0 > editMode || editMode !==cType*10000+$index)) {
                    <div class="contact-buttons">
                        @if (!checkingIfDeletable) {
                        <button [disabled]="editMode >= 0" data-test="bttn_delete"
                            (click)="delete(cType, $index)" mat-raised-button type="button" class="edit-button">
                            <mat-icon>delete</mat-icon>
                        </button>
                        }
                        @if (checkingIfDeletable) {
                        <button disabled mat-raised-button class="action-button spinner-button">
                            <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
                        </button>
                        }
                        <button [disabled]="editMode >= 0" data-test="bttn_edit"
                            (click)="edit(cType, $index)" mat-raised-button type="button" class="action-button">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                    }
                </ng-template>
            </mat-expansion-panel>
            }
        </div>
        }
    </div>
    }
    <div class="enlargepage"></div>
</div>
}