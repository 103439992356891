<ng-template #labelEntry let-obj="obj">
    @if (obj?.hr_id) {
    <div class="smallerfont">{{obj.hr_id}}</div>
    }
    @if (obj?.label) {
    <div>{{obj.label}}</div>
    }
</ng-template>


@if (!coffees) {
<div class="wait-outer">
    <mat-spinner class="spinner"></mat-spinner>
</div>
}

@if (!readOnly) {
<div class="actionbar">
    <div class="actionbar-inner">
        @if (coffees) {
        <button [disabled]="editNewCoffee >= 0 || showHidden === 'only'"
            (click)="add()" data-test="bttn_add" mat-raised-button
            type="button" class="add-button action-button">
            <mat-icon>add</mat-icon>
        </button>
        }
        @if (coffees) {
        <button [disabled]="editNewCoffee >= 0 || showHidden === 'only'"
            (click)="import()" data-test="bttn_import" mat-raised-button
            type="button" class="add-button action-button"
            matTooltip="Import Beans Data" i18n-matTooltip="external$$button and heading, import beans data from external web site@@Import Beans Data">
            <mat-icon>file_download</mat-icon>
        </button>
        }
    </div>
</div>
}

@if (readOnly) {
<div class="readonly-spacer"></div>
}

<div class="sortbar">
    <!-- filters -->
    <div class="sortbar-filter-div narrow-form-field">
        @if (stores?.length > 1 && maxObjectCount > 0) {
        <mat-form-field subscriptSizing="dynamic" style="margin-right: 10px;">
            <mat-label i18n="locationtypes$$LocationTypes@@Stores">Stores</mat-label>
            <mat-select [disabled]="editNewCoffee >= 0" multiple
                matInput name="showstockfrom" panelWidth="null"
                placeholder="Stores" i18n-placeholder="locationtypes$$LocationTypes@@Stores"
                [value]="showstockfrom === 'all' ? stores : showstockfrom"
                (selectionChange)="showstockfromChanged($event)"
                [compareWith]="utils.compareObjectsFn">
                <mat-select-trigger class="twoLineOption ellipsed">
                    @if (showstockfrom !== 'all' && showstockfrom?.length === 1) {
                    <ng-container *ngTemplateOutlet="labelEntry; context: {obj: showstockfrom[0]}"></ng-container>
                    } @else if (showstockfrom === 'all' || showstockfrom?.length === stores.length) {
                    <span i18n="generic$$indicating everything is selected@@all">all</span>
                    } @else if (showstockfrom?.length > 1 && stores.length > showstockfrom.length) {
                    {{showstockfrom.length}} <span i18n="locationtypes$$LocationTypes@@Stores">Stores</span>
                    }
                </mat-select-trigger>
                @for (store of stores; track store?._id) {
                <mat-option [value]="store"><ng-container *ngTemplateOutlet="labelEntry; context: {obj: store}"></ng-container></mat-option>
                }
            </mat-select>
        </mat-form-field>
        }
        @if (maxObjectCount > 0 || showOrganic) {
        <div class="sortbar-icon-div">
            <!-- organic icon -->
            <app-organicicon class="cursor-pointer"
                (click)="showOrganicChanged()"
                [isOrganic]="true"
                [inactive]="!showOrganic"
                [strikethrough]="showOrganic === 'off'"
                [matTooltip]="!showOrganic ? tr.anslate('Show only items with organic certification') : ''">
            </app-organicicon>
            @if (!helptipOrganicShown && showHelptipOrganic) {
            <div class="helptip helptip-organic" [ngClass]="{'showme': showHelptipOrganic}"><span i18n="generic$$tooltip, filter for organic certifications@@Show only items with organic certification">Show only items with organic certification</span>&nbsp;<mat-icon class="small-close-icon" (click)="removeHelptip('organic')">close</mat-icon>
            </div>
            }
        </div>
        }
        @if (maxObjectCount > 0 || showHidden !== 'false') {
        <div class="sortbar-icon-div">
            <!-- hide icon -->
            @if (showHidden !== 'false') {
            <mat-icon
                [ngClass]="{'tertiary-element': !hiddenButtonInactive}"
                class="cursor-pointer hide-icon"
                (click)="showHiddenChanged('false')">visibility_off
            </mat-icon>
            }
            @if (showHidden === 'false') {
            <mat-icon
                [ngClass]="{'primary-element': !hiddenButtonInactive}"
                class="cursor-pointer hide-icon"
                (click)="showHiddenChanged('only')"
                matTooltip="Click here to find all hidden entries"
                i18n-matTooltip="generic$$tip where hidden items can be found@@Click here to find all hidden entries">visibility
            </mat-icon>
            }
            @if (!helptipHiddenShown && showHelptipHidden) {
            <div class="helptip helptip-hide" [ngClass]="{'showme': showHelptipHidden}"><span i18n="generic$$tip where hidden items can be found@@Click here to find all hidden entries">Click here to find all hidden entries</span>&nbsp;<mat-icon class="small-close-icon" (click)="removeHelptip('hidden')">close</mat-icon>
            </div>
            }
        </div>
        }
        @if (maxObjectCount > 0) {
        <div class="filter-input-div" style="align-self: flex-end;">
            <mat-form-field subscriptSizing="dynamic">
                <mat-label i18n="generic$$filter to limit the display of a list, placeholder@@Filter">Filter</mat-label>
                <input [disabled]="editNewCoffee >= 0" matInput
                    name="filter" type="text" class="filter-input"
                    placeholder="Filter" i18n-placeholder="generic$$filter to limit the display of a list, placeholder@@Filter"
                    [ngModel]="filter"
                    (blur)="filterChanged.next($event.target['value'])"
                    (ngModelChange)='filterChanged.next($event)'>
                <!-- <mat-hint>origin: ... / flavor: ... / stock: ...</mat-hint> -->
            </mat-form-field>
            <a [href]="'https://' + (isDarkmode ? 'd' : '') + 'doc.artisan.plus/updates/filtering-beans/'" rel="noopener" style="padding-right: 0;margin: 0 0 0 5px;" target="_blank">
                <mat-icon class="helpicon">{{isDarkmode ? 'help' : 'help_outline'}}</mat-icon>
            </a>
        </div>
        }
    </div>

    <!-- sort -->
    @if (maxObjectCount > 1) {
    <div class="sortbar-inner">
        <mat-button-toggle-group [disabled]="editNewCoffee >= 0"
            [(ngModel)]="sortValue"
            [ngClass]="{'inverse': inverse}"
            class="sortButtonGroup" hideSingleSelectionIndicator="true">
            <mat-button-toggle (click)="sortOrderChanged()"
                class="sortButton" i18n="generic$$sort according to when the items have been modified@@Last modified"
                value="lastmodified">Last modified</mat-button-toggle>
            <mat-button-toggle (click)="sortOrderChanged()"
                class="sortButton" i18n="generic$$Stock@@Stock"
                value="stock">Stock</mat-button-toggle>
            <mat-button-toggle (click)="sortOrderChanged()"
                class="sortButton" i18n="generic$$sort order@@ID"
                value="hr_id">ID</mat-button-toggle>
            <mat-button-toggle (click)="sortOrderChanged()"
                class="sortButton" i18n="generic$$Name@@Name"
                value="label">Name</mat-button-toggle>
            <mat-button-toggle [disabled]="!properties" (click)="sortOrderChanged()"
                class="sortButton" i18n="beans$$origin@@Origin" value="origin">Origin</mat-button-toggle>
            <mat-button-toggle [disabled]="!properties" (click)="sortOrderChanged()"
                class="sortButton" i18n="beans$$beans property processing@@Processing" value="processing">Processing</mat-button-toggle>
            <!-- @if (sortValue === 'processing') {
            <div (click)="sortOrderChanged()" class="sort-indicator">
                @if (inverse) {
                <mat-icon>arrow_downward</mat-icon>
                } @else {
                <mat-icon>arrow_upward</mat-icon>
                }
            </div>
            } -->
        </mat-button-toggle-group>
    </div>
    } @else {
    <span></span>
    }
</div>

@if (maxObjectCount >= 5) {
<mat-paginator
    [disabled]="editNewCoffee >= 0 || isNew >= 0"
    (page)="pagingChanged($event)"
    [length]="filteredObjectCount" [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 50]" [pageIndex]="pageIndex"
    [selectConfig]="{ panelClass: 'paginator-overlay' }">
</mat-paginator>
}

@if (coffees?.length) {
<mat-accordion displayMode="default" multi="true">
    @for (coffee of coffees; track coffee?._id) {
    <app-coffee
        [coffee]="coffee"
        [index]="$index"
        [showHidden]="showHidden !== 'false'"
        [allCertifications]="allCertifications"
        [idToHighlight]="idToHighlight"
        [editMode]="editNewCoffee"
        [isNew]="isNew"
        [fields]="fields"
        (fieldAdded)="fieldAdded($event)"
        [stores]=stores
        [properties]="properties"
        [producers]="producers"
        (producerAdded)="producerAdded($event)"
        [suppliers]="suppliers"
        [allVarietals]="allVarietals"
        [allVarietalCategories]="allVarietalCategories"
        [readOnly]="readOnly"
        [showStockPlaceholder]="anyCoffeeHasStock"
        [showstockfrom]="showstockfrom">
    </app-coffee>
    }
</mat-accordion>
}

@if (maxObjectCount > 5 && filteredObjectCount > 5 && pageSize >= 10) {
<mat-paginator
    [disabled]="editNewCoffee >= 0 || isNew >= 0"
    (page)="pagingChanged($event)"
    [length]="filteredObjectCount" [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 50]" [pageIndex]="pageIndex"
    [selectConfig]="{ panelClass: 'paginator-overlay' }">
    style="margin-top: 4px;">
</mat-paginator>
}

@if (gettingForFilter) {
<div class="spinner-with-comment">
    <div>
        <mat-spinner diameter="20"></mat-spinner>
    </div>
    <span class="spinner-comment" i18n="blends$$add blend@@Loading beans">Loading beans ...</span>
</div>
}

@if (!readOnly && !gettingForFilter && maxObjectCount === 0 && coffees?.length === 0 && !idToHighlight) {
<div>
    @if (showHidden === 'false') {
    <p class="main-note" i18n="generic$$no items found, hint at how to add one@@nothing to show plus hint, add with button">Currently, there is nothing to show. Add using the button at the top!</p>
    } @else {
    <p class="main-note" i18n="generic$$no hidden items@@You have no hidden items.">You have no hidden items.</p>
    }
</div>
}
<!-- <div *ngIf="(idToHighlight && coffees && coffees.length === 0) || (coffees && coffees.length > 0 && filteredCoffees && filteredCoffees.length === 0)"> -->
@if (!gettingForFilter && coffees?.length === 0 && (maxObjectCount > 0 || idToHighlight)) {
<div>
    <p class="main-note" i18n="generic$$no matches found using the given filter@@no match for filter">No matches found</p>
</div>
}

<div class="enlargepage"></div>