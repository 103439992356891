<ng-template #coffeeEntry let-coffee="coffee">
    <div class="smallerfont">
        @if (coffee?.hr_id) {
        <span>{{coffee.hr_id}}</span>
        }
        <app-organicicon
            [isOrganic]="utils.isOrganicCoffee(coffee)"
            [size]="7"
            [leftMargin]="3">
        </app-organicicon>
        @if (coffee?.origin) {
        <span> {{tr.anslate(coffee.origin)}}</span>
        }
        @if (coffee?.yearLabel) {
        <span> {{coffee.yearLabel}}</span>
        }
    </div>
    @if (coffee?.label) {
    <div> {{coffee.label}}</div>
    }
</ng-template>

<ng-template #labelEntry let-obj="obj">
    @if (obj?.hr_id) {
    <div class="smallerfont">{{obj.hr_id}}</div>
    }
    @if (obj?.label) {
    <div>{{obj.label}}</div>
    }
</ng-template>

<ng-template #smallSpinner>
    <mat-spinner diameter="15" strokeWidth="1" class="small-spinner"></mat-spinner>
</ng-template>



@if (!data.transaction) {
<h1 mat-dialog-title i18n="transaction$$add correction header@@correction title">Correct your stock</h1>
} @else {
<h1 mat-dialog-title i18n="transaction$$edit correction header@@edit correction title">Edit Correction</h1>
}
<mat-dialog-content style="max-width: 400px;">
    <div><span i18n="transaction$$indicate some more appropriate action if _You bought beans_@@bought beans">You bought beans</span> => <a (click)="openDialog('purchase')" (keydown.enter)="openDialog('purchase'); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer" i18n="transaction$$add a purchase link@@add a purchase">add a Purchase</a></div>
    <div><span i18n="transaction$$indicate some more appropriate action if _You sold beans_@@sold beans">You sold beans</span> => <a (click)="openDialog('sale')" (keydown.enter)="openDialog('sale'); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer" i18n="transaction$$add a sale link@@add a sale">add a Sale</a></div>
    <div style="margin-bottom: 10px;"><span i18n="transaction$$indicate some more appropriate action if _You moved beans_@@moved beans">You moved beans</span> => <a (click)="openDialog('transfer')" (keydown.enter)="openDialog('transfer'); $event.preventDefault()" role="button" [tabindex]="0" class="cursor-pointer" i18n="transaction$$add a transfer link@@add a transfer">add a Transfer</a></div>

    <form class="narrow-form" id="form" name="form" (ngSubmit)="f.form.valid && add()" #f="ngForm">
        <mat-form-field>
            <mat-label i18n="transaction$$user should provide a reason when correcting the stock, placeholder@@other reason">Other reason</mat-label>
            <input matInput required [disabled]="waiting" name="reason"
                [ngClass]="{'required-but-empty': !reason}"
                placeholder="Other reason" i18n-placeholder="transaction$$user should provide a reason when correcting the stock, placeholder@@other reason"
                [(ngModel)]="reason">
        </mat-form-field>

        <div class="flex-center">
            <mat-form-field>
                <mat-label i18n="generic$$Beans@@Beans">Beans</mat-label>
                <mat-select required [disabled]="!coffees?.length || waiting"
                    name="coffee" panelClass="twoLineOption" panelWidth="null"
                    hideSingleSelectionIndicator="true"
                    placeholder="Beans" i18n-placeholder="generic$$Beans@@Beans"
                    [(ngModel)]="coffee"
                    (selectionChange)="updateAmount(true)"
                    [compareWith]="utils.compareObjectsFn">
                    <mat-select-trigger class="twoLineOption ellipsed">
                        <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: coffee}"></ng-container>
                    </mat-select-trigger>
                    @if (coffees?.length >= 5) {
                    <mat-option>
                        <ngx-mat-select-search ngModel (ngModelChange)="filteredCoffees = utils.filterObjects($event, coffees || [coffee])" name="coffeeSelect"
                            placeholderLabel="Filter ..." i18n-placeholderLabel="transaction$$Filter ...@@Filter ..."
                            noEntriesFoundLabel="">
                        </ngx-mat-select-search>
                    </mat-option>
                    }
                    @for (coff of (coffees?.length ? filteredCoffees : [coffee]); track coff?._id) {
                    <mat-option [value]="coff">
                        <ng-container *ngTemplateOutlet="coffeeEntry; context: {coffee: coff}"></ng-container>
                    </mat-option>
                    }
                </mat-select>
            </mat-form-field>
            @if (!coffees) {
            <ng-container *ngTemplateOutlet="smallSpinner"></ng-container>
            }
        </div>

        @if (coffees?.length === 0) {
        <div>
            <span i18n="transaction$$no beans to transfer / sell@@no beans">Please add beans first!</span>
        </div>
        } @else {
        <mat-form-field>
            <mat-label i18n="transaction$Target store placeholder@@Target store">Target store</mat-label>
            <mat-select required [disabled]="!stores?.length || waiting"
                name="store" panelClass="twoLineOption"
                panelWidth="null" hideSingleSelectionIndicator="true"
                placeholder="Target store"
                i18n-placeholder="transaction$Target store placeholder@@Target store"
                [(ngModel)]="store" [compareWith]="utils.compareObjectsFn"
                (selectionChange)="updateAmount(false)">
                @if (store) {
                <mat-select-trigger class="twoLineOption ellipsed">
                    <ng-container *ngTemplateOutlet="labelEntry; context: {obj: store}"></ng-container>
                </mat-select-trigger>
                }
                @for (store of stores; track store?._id) {
                <mat-option [value]="store">
                    <ng-container *ngTemplateOutlet="labelEntry; context: {obj: store}"></ng-container>
                </mat-option>
                }
            </mat-select>
        </mat-form-field><br />
        @if (coffee?.default_unit?.name) {
        <mat-form-field class="amount-input-ff1" subscriptSizing="dynamic">
            <mat-label i18n="transaction$$new, corrected amount in beans units, e.g. _5_ bags@@new amount">New amount</mat-label>
            <input matInput required [disabled]="!coffee || waiting"
                name="amount" type="text" class="amount-input"
                placeholder="New amount" i18n-placeholder="transaction$$new, corrected amount in beans units, e.g. _5_ bags@@new amount"
                (change)="checkChangesUnits(this, 'amount', amount, $event.target['value'], true, false)"
                [value]="amount | number:'1.0-3'">
            @if (amount === 1) {
            <span>{{tr.anslate(coffee.default_unit.name)}}</span>
            } @else {
            {{tr.anslate('plural_' + coffee.default_unit.name)}}
            }
        </mat-form-field>
        }
        <mat-form-field class="amount-input-ff2" subscriptSizing="dynamic">
            <mat-label i18n="transaction$$new, corrected amount in kg or lbs etc.@@new weight in">New weight in {{mainUnit}}</mat-label>
            <input matInput [disabled]="!coffee || waiting" name="unitamount" class="amount-input" type="text"
                (change)="checkChangesUnits(this, 'unitamount', unitamount, $event.target['value'], false, false)"
                [value]="unitamount * utils.getUnitFactor(mainUnit) | number:'1.0-3'">{{mainUnit}}
        </mat-form-field><br />
        @if (!this.data.transaction) {
        @if (showDeltaAmounts) {
        <div>
            @if (coffee?.default_unit?.name) {
            <mat-form-field class="amount-input-ff1">
                <mat-label i18n="transaction$$amount to be added / deducted in this correction in beans units, e.g. _5_ bags@@delta amount">Amount difference</mat-label>
                <input matInput required [disabled]="!coffee || waiting" name="damount" class="amount-input" type="text"
                    (change)="checkChangesUnits(this, 'damount', damount, $event.target['value'], true, true)"
                    [value]="damount | number:'1.0-3'">
                @if (damount === 1) {
                <span>{{tr.anslate(coffee.default_unit.name)}}</span>
                } @else {
                {{tr.anslate('plural_' + coffee.default_unit.name)}}
                }
            </mat-form-field>
            }
            <mat-form-field class="amount-input-ff2">
                <mat-label i18n="transaction$$amount to be added / deducted in this correction in kg or lbs etc.@@delta weight in">Weight difference in {{mainUnit}}</mat-label>
                <input matInput [disabled]="!coffee || waiting" class="amount-input"
                    name="dunitamount" type="text"
                    placeholder="Weight difference in {{mainUnit}}" i18n-placeholder="transaction$$amount to be added / deducted in this correction in kg or lbs etc.@@delta weight in"
                    (change)="checkChangesUnits(this, 'dunitamount', dunitamount, $event.target['value'], false, true)"
                    [value]="dunitamount * utils.getUnitFactor(mainUnit) | number:'1.0-3'">{{mainUnit}}
            </mat-form-field><br />
        </div>
        }
        @if (!showDeltaAmounts) {
        <div class="showDifferentLink">
            <a (click)="showDeltaAmounts = true" (keydown.enter)="showDeltaAmounts = true; $event.preventDefault()" role="button" [tabindex]="0" i18n="transaction$$while adding a correction, switch between specifying the absolute corrected weight or the relative change@@show delta amounts">Enter weight difference instead</a> ({{dunitamount > 0 ? '+' : ''}}{{dunitamount * utils.getUnitFactor(mainUnit) | number:'1.0-3'}} {{mainUnit}})
        </div>
        }
        }
        <mat-form-field>
            <mat-label i18n="transaction$placeholder, an arbitrary reference string@@reference">Reference</mat-label>
            <input matInput type="string" [disabled]="waiting" name="reference"
                placeholder="Reference" i18n-placeholder="transaction$placeholder, an arbitrary reference string@@reference"
                [(ngModel)]="reference">
        </mat-form-field><br />
        <app-ap-datetime [value]="date" [dateRequired]="true" [disabled]="waiting" (valueChange)="date=$event"></app-ap-datetime>
        }

    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button (click)="handleExit(undefined, undefined, true)" class="cancel-button" type="button">
        <mat-icon>close</mat-icon>
    </button>
    <button mat-raised-button [disabled]="waiting || !f.form.valid" form="form" ng-submit type="submit" class="action-button spinner-button">
        @if (showWaiting) {
        <mat-spinner class="button-spinner" diameter="20"></mat-spinner>
        } @else {
        <mat-icon>done</mat-icon>
        }
    </button>
</mat-dialog-actions>