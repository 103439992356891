<ng-template #labelEntry let-obj="obj">
    @if (obj?.hr_id) {
    <div class="smallerfont">{{obj.hr_id}}</div>
    }
    @if (obj?.label) {
    <div>{{obj.label}}</div>
    }
</ng-template>


@if (!blends) {
<div class="wait-outer">
    <mat-spinner data-test="spnnr_no_blends" class="spinner"></mat-spinner>
</div>
}

<div class="actionbar" #appBlends>
    @if (!readOnly) {
    <div class="actionbar-inner">
        @if (blends) {
        <button [disabled]="loadingCoffees || editNewBlend >= 0"
            (click)="add()" data-test="bttn_add" class="add-button action-button" mat-raised-button>
            <mat-icon>add</mat-icon>
        </button>
        }
    </div>
    }
    @if (loadingCoffees) {
    <div class="spinner-with-comment">
        <mat-spinner data-test="spnnr_loading" diameter="20"></mat-spinner>
        <span class="spinner-comment" i18n="blends$$add blend@@Loading beans">Loading beans ...</span>
    </div>
    }
</div>

@if (readOnly) {
<div class="readonly-spacer"></div>
}

@if (objectCount > 0) {
<div class="sortbar blends" style="align-items: flex-end;">
    <div class="sortbar-filter-div">
        @if (allStores?.length > 1) {
        <mat-form-field [ngClass]="{'blends-formfield-thin': showstockfrom?.length === 1}"
            data-test="slct_ssf" subscriptSizing="dynamic" style="margin-right: 20px">
            @if (showstockfrom?.length !== 1) {
            <mat-label i18n="generic$$storage location@@Store">Store</mat-label>
            }
            <mat-select [disabled]="loadingCoffees || editNewBlend >= 0" multiple
                matInput name="showstockfrom" panelWidth="null"
                [value]="showstockfrom === 'all' ? allStores : showstockfrom"
                [compareWith]="utils.compareObjectsFn"
                (selectionChange)="showstockfromChanged($event)">
                <mat-select-trigger class="ellipsed">
                    @if (showstockfrom !== 'all' && showstockfrom?.length === 1) {
                    <ng-container *ngTemplateOutlet="labelEntry; context: {obj: showstockfrom[0]}"></ng-container>
                    } @else if (showstockfrom === 'all' || showstockfrom?.length === allStores.length) {
                    <span i18n="generic$$indicating everything is selected@@all">all</span>
                    } @else if (showstockfrom?.length > 1 && allStores.length > showstockfrom.length) {
                    {{showstockfrom.length}} <span i18n="locationtypes$$LocationTypes@@Stores">Stores</span>
                    }
                </mat-select-trigger>
                @for (store of allStores; track store?.hr_id) {
                <mat-option [value]="store"><ng-container *ngTemplateOutlet="labelEntry; context: {obj: store}"></ng-container></mat-option>
                }
            </mat-select>
        </mat-form-field>
        }
        <mat-form-field data-test="slct_export" style="width: 100px; margin-right: 10px; margin-top: 0;" subscriptSizing="dynamic">
            <mat-select [disabled]="loadingCoffees || editNewBlend >= 0"
                placeholder="Export" matInput name="export" panelWidth="null"
                i18n-placeholder="generic$$button, export@@Export"
                [(value)]="exportFormat"
                (selectionChange)="export($event.value)">
                <!-- <mat-option></mat-option> -->
                <mat-option value="csv">CSV</mat-option>
                <mat-option value="clipboardCSV" i18n="generic$$Clipboard@@Clipboard">Clipboard</mat-option>
                <mat-option value="pdf">PDF</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    @if (objectCount > 1) {
    <div class="sortbar-inner">
        <mat-button-toggle-group [disabled]="editNewBlend >= 0"
            [(ngModel)]="sortValue"
            [ngClass]="{'inverse': inverse}"
            class="sortButtonGroup" hideSingleSelectionIndicator="true">
            <mat-button-toggle (click)="sortOrderChanged()" value="lastmodified" class="sortButton"
                i18n="generic$$sort according to when the items have been modified@@Last modified"
                data-test="bttn_lastModified">Last modified</mat-button-toggle>
            <mat-button-toggle (click)="sortOrderChanged()" value="stock" class="sortButton"
                i18n="generic$$Stock@@Stock"
                data-test="bttn_stock">Stock</mat-button-toggle>
            <mat-button-toggle (click)="sortOrderChanged()" value="hr_id" class="sortButton"
                i18n="generic$$sort order@@ID"
                data-test="bttn_id">ID</mat-button-toggle>
            <mat-button-toggle (click)="sortOrderChanged()" value="label" class="sortButton"
                i18n="generic$$Name@@Name"
                data-test="bttn_name">Name</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    }
</div>
}

@if (objectCount >= 5) {
<mat-paginator [disabled]="editNewBlend >= 0 || isNew >= 0"
    [length]="objectCount" [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 50]" [pageIndex]="pageIndex"
    (page)="pagingChanged($event)">
</mat-paginator>
}

@if (blends?.length) {
<mat-accordion displayMode="default" multi="true">
    @for (blend of blends; track blend?._id; let m = $index) {
    <app-blend
        [currentUser]="currentUser"
        [index]="m"
        [showstockfrom]="showstockfrom"
        [idToHighlight]="idToHighlight"
        [editMode]="editNewBlend"
        [isNew]="isNew"
        [readOnly]="readOnly"
        [blends]="cachedObjects"
        [blendsCount]="objectCount"
        [blend]="blend"
        [showStockPlaceholder]="anyBlendHasStock"
        (blendStockChanged)="blendStockChanged()">
    </app-blend>
    }
</mat-accordion>
}

@if (objectCount >= 5 && pageSize >= 10) {
<mat-paginator [length]="objectCount" [pageSize]="pageSize" [disabled]="editNewBlend >= 0 || isNew >= 0"
    [pageSizeOptions]="[5, 10, 25, 50]" [pageIndex]="pageIndex" (page)="pagingChanged($event)">
</mat-paginator>
}

@if (!readOnly && blends?.length === 0 && !loadingCoffees) {
<div>
    <p class="main-note" i18n="generic$$no items found, hint at how to add one@@nothing to show plus hint, add with button">Currently, there is nothing to show. Add using the button at the top!</p>
</div>
}

<div class="enlargepage"></div>